import { noop } from "lodash";
import Stores from "stores/Stores";
import { cloneEvent } from "./Utils";

export const  normalizeMouseOrTouchEvent = (e: TouchEvent & MouseEvent, stores: Stores): TouchEvent & MouseEvent => {
  const {userInfoStore}= stores;
  const {touchFingerOffsetX, touchFingerOffsetY} = userInfoStore.user || {};

  e = cloneEvent(e);
  if (e.touches) {
    const touches = (e.type === 'touchend' ? e.changedTouches : e.touches);

    if (touches.length > 1 || !e.pageX) {
      e.pageX = (touches[0].pageX + (touches[1]?.pageX || touches[0].pageX)) / 2;
      e.pageY = (touches[0].pageY + (touches[1]?.pageY || touches[0].pageY)) / 2;
    }
    
    if (!e.fingerOffsetX) {
      e.pageX += touchFingerOffsetX;
      e.pageY += touchFingerOffsetY;
    }

    e.fingerOffsetX = touchFingerOffsetX;
    e.fingerOffsetY = touchFingerOffsetY;
  }

  // would throw error anyway if not original instance  
  e.preventDefault = noop;

  return e;
}