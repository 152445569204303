import { DrawToolType } from "constants/DrawToolType";
import { NodeType } from 'constants/NodeType';
import { UnitType } from "constants/UnitType";
import { compact, groupBy, orderBy } from 'lodash';
import { action, computed, observable, reaction } from "mobx";
import DrawToolsOption from "models/DrawToolsOption";
import Line from "models/Line";
import Measurement from "models/Measurement";
import Point from "models/Point";
import Surface from "models/Surface";
import TreeNode from 'models/TreeNode';
import Wall from 'models/Wall';
import { askToSubtractSurfaceIfNeeded, drawToolsOptionsByType, getDefaultMeasurements } from "utils/DrawToolsUtils";
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { isClockwise } from 'utils/GeometryUtils';
import { getSafe, trySetTimeout } from 'utils/Utils';
import BaseStore from './BaseStore';

// for everything that doesn't need it's own store
export default class DrawToolsStore extends BaseStore {
  @observable _selectedTool: DrawToolType = DrawToolType.Select;
  @observable _lineBeingAdded: Line = null;
  @observable firstPt: Point = null;
  @observable isFullscreen = false;
  @observable shouldLocklineBeingAddedLength = false;
  @observable lastMousePt: Point = null;
  @observable nodeBeingAdded: TreeNode;
  @observable segmentsGroupBeingAdded: TreeNode;
  @observable surfaceNodeBeingAdded: TreeNode;

  selectedDrawToolRef = null;

  @observable shouldShowDrawingButtonOptions = false;
  @observable shouldShowDrawingButtonMeasurements = false;

  @observable selectedCountToolMeasurementId: string = '';

  @observable shapesToHighlightFromHoveredMeasurement = new Set();

  @computed get isTouchQuickDrawMode() {
    const { userInfoStore, treeNodesStore } = this.stores;
    return userInfoStore.user?.shouldUseTouchQuickDrawingMode;
  }

  // ensure to get the most up to date measurement, because sometimes a copy gets edited in a dialog
  @computed get selectedCountToolMeasurement(): Measurement {
    const { measurementsStore, treeNodesStore } = this.stores;
    const unitMeasurementsForSelectedNode = treeNodesStore.selectedTreeNode.measurements.filter(measurement => measurement?.unitType === UnitType.Unit);
    return (
      measurementsStore.getItem(this.selectedCountToolMeasurementId) ||
      (unitMeasurementsForSelectedNode.length === 1 ? unitMeasurementsForSelectedNode[0] : null)
    );
  }
  set selectedCountToolMeasurement(value: Measurement) {
    if (value?.id === this.selectedCountToolMeasurementId) {
      return;
    }

    this.selectedCountToolMeasurementId = value?.id || '';

    // reevaluate if we need to create a new group or not
    this.segmentsGroupBeingAdded = null;
  }

  buttonOptionsObserver = reaction(() => this.stores?.treeNodesStore?.selectedTreeNodeId, () => {
    if (this.shouldShowDrawingButtonOptions || this.shouldShowDrawingButtonMeasurements) {
      this.shouldShowDrawingButtonOptions = false;
      this.shouldShowDrawingButtonMeasurements = false;
    }
  });

  reset = (shouldResetDrawingTool = true, shouldResetCountTool = true) => {
    const { commonStore, snapStore, shapesStore, treeNodesStore } = this.stores;

    this.nodeBeingAdded = null;
    this.lineBeingAdded = null;
    this.lastMousePt = null;
    this.segmentsGroupBeingAdded = null;
    this.surfaceNodeBeingAdded = null;

    if (shouldResetCountTool) {
      this.selectedCountToolMeasurement = null;
    }

    if (shouldResetDrawingTool) {
      commonStore.isBigUpdateOngoing = false;
    }

    snapStore.cursorPosition = null;

    // hack to prevent mouse up from generating shape selection event
    shouldResetDrawingTool && setTimeout(() => this.selectedTool = DrawToolType.Select, 400);
  }

  @observable drawToolsOptions: Map<DrawToolType, DrawToolsOption[]> = new Map<DrawToolType, DrawToolsOption[]>(
    Object.values(DrawToolType)
      .map(toolType => [
        toolType,
        (drawToolsOptionsByType[toolType] || []).map(measurementType => (
          new DrawToolsOption(this.stores, measurementType)
        ))])
  );

  @computed get selectedDrawToolOptions(): DrawToolsOption[] {
    return this.drawToolsOptions.get(this.selectedTool);
  }

  isFirstDrawingBeingAdded = false;

  @computed get lineBeingAdded(): Line {
    return this._lineBeingAdded;
  }
  set lineBeingAdded(value: Line) {
    const { shapesStore } = this.stores;
    if (shapesStore.items.length === 1 && value) {
      this.isFirstDrawingBeingAdded = true;
    }

    if (this._lineBeingAdded && !value) {
      this.isFirstDrawingBeingAdded = false;
      this.firstPt = null;
    }

    this._lineBeingAdded = value;
  }


  @action
  finalizeUnclosedShape = (batchParam?: WriteBatch) => {
    const batch = batchParam || firestoreBatch(this.stores);
    const { treeNodesStore, shapesStore } = this.stores;
    // remove zero length wall
    const parentNode = treeNodesStore.getParentNode(this.nodeBeingAdded);

    if (parentNode) {
      parentNode.childrenIds.remove(this.nodeBeingAdded.id);
      treeNodesStore.deleteItems([this.nodeBeingAdded.id], true, batch);
      treeNodesStore.batchAddEditItem(parentNode, batch);

      treeNodesStore.toggleNodeMeasurements(
        getDefaultMeasurements(NodeType.General, DrawToolType.GeneralDraw, this.stores),
        true,
        parentNode,
        true,
        batch
      );
    }

    //todo should be automatic
    if (this.lineBeingAdded) {
      shapesStore.deleteItems([this.lineBeingAdded.id], false);
    }
    if (this.surfaceNodeBeingAdded) {
      shapesStore.deleteItems([getSafe(() => this.surfaceNodeBeingAdded.shape.id)], false);
    }

    if (!batch) {
      batch.commit();
    }

    // treeNodesStore.selectedTreeNode = this.segmentsGroupBeingAdded?.parent;// || treeNodesStore.rootNode;

    trySetTimeout(() => {
      treeNodesStore.treeComponentRef.scrollTo({ key: this.nodeBeingAdded?.id });
    }, 400);

    this.reset(false);
  }

  finalizeClosedShape = async (parentNode: TreeNode, batchParam: WriteBatch) => {
    const { shapesStore, treeNodesStore } = this.stores;

    const batch = batchParam || firestoreBatch(this.stores);

    shapesStore.batchAddEditItem(this.lineBeingAdded, batch);

    const surfaceShape = this.surfaceNodeBeingAdded.shape as Surface

    // Add surface
    // Would be better to move everything to another parent group
    shapesStore.batchAddEditItem(this.surfaceNodeBeingAdded.shape, batch);
    treeNodesStore.batchAddEditItem(this.surfaceNodeBeingAdded, batch);
    treeNodesStore.appendNode(
      this.surfaceNodeBeingAdded,
      parentNode,
      parentNode.children.findIndex(childNode => childNode == this.segmentsGroupBeingAdded),
      batch
    );

    // if wall was drawn in clockwise, change to counter clockwise to make sure dimension
    if (isClockwise(this.surfaceNodeBeingAdded.shape.points)) {
      this.segmentsGroupBeingAdded.children.forEach(wallNode => {
        const wallToChange = wallNode.shape as Wall;
        const tmpPoint = wallToChange.points[0];
        wallToChange.points[0] = wallToChange.points[1];
        wallToChange.points[1] = tmpPoint;

        shapesStore.batchAddEditItem(wallToChange, batch);
      });

      this.surfaceNodeBeingAdded.shape.points = this.surfaceNodeBeingAdded.shape.points.slice(0).reverse();
      shapesStore.batchAddEditItem(this.surfaceNodeBeingAdded.shape, batch);
    }

    treeNodesStore.toggleNodeMeasurements(
      getDefaultMeasurements(NodeType.General, DrawToolType.GeneralDraw, this.stores),
      true,
      parentNode,
      true,
      batch
    );

    if (!batchParam) {
      batch.commit();
    }

    treeNodesStore.selectedTreeNode = this.surfaceNodeBeingAdded.parent;

    this.reset();

    const { satelliteImageUrl, backgroundImage } = treeNodesStore.rootNode

    // recenter first drawn surface
    if (treeNodesStore.rootNode.surfaceShapes.length === 1) {
      if (backgroundImage || satelliteImageUrl) {
        shapesStore.zoomController?.zoomCompletelyOut?.()
      } else {
        shapesStore.zoomController?.zoomOutOnShapes?.(1.15);
      }  
    }
    
    await askToSubtractSurfaceIfNeeded([surfaceShape]);
  }


  @computed
  get selectedTool(): DrawToolType {
    const backgroundImage = this.stores.treeNodesStore.rootNode?.backgroundImage;
    if (
      !backgroundImage && (
        this._selectedTool === DrawToolType.BackgroundImageCrop ||
        this._selectedTool === DrawToolType.BackgroundImageSetScale
      )
    ) {
      return DrawToolType.Select;
    }

    return this._selectedTool;
  }

  set selectedTool(value: DrawToolType) {
    const { snapStore, commonStore, shapesStore } = this.stores;

    if (
      this._selectedTool !== DrawToolType.Select &&
      this._selectedTool !== value
    ) {
      this.finalizeUnclosedShape();
    }

    shapesStore.zoomController?.cancelAccelerationTracking?.();

    if ([DrawToolType.GeneralDraw, DrawToolType.PredefinedShapeRectangle].includes(value) && !commonStore.isBigUpdateOngoing) {
      commonStore._isBigUpdateOngoing = true;
    } else if (commonStore.isBigUpdateOngoing && this._selectedTool !== value) {
      commonStore._isBigUpdateOngoing = false;
    }

    this._selectedTool = value;

    //this.reset(false); // already called by selectedTreeNodeListener
  }

  @computed get duplicateLines(): Line[] {
    const { rootNode } = this.stores.treeNodesStore;
    const { shapes } = rootNode;

    const lines: Line[] = shapes.filter(shape => shape instanceof Line) as Line[];

    const groupedLines = groupBy(
      lines,
      (line: Line) => {
        const sortedPoints = orderBy(line.points, ['x', 'y']);
        return [sortedPoints[0].x, sortedPoints[0].y, sortedPoints[1].x, sortedPoints[1].y].join('_');
      }
    );

    return compact(
      Object.values(groupedLines)
        .map(lines => lines.length > 1 ? lines[1] : null)
    );
  }
}
