import { PanelIds } from "constants/PanelIds";
import { ReflexElementProps } from "react-reflex";
import Stores from 'stores/Stores';
import { getSafe } from "./Utils";

// gets called one panel at a time, so no way to ensure sizes are valid until all panels are done resizing
export const handlePanelSizeChange = (panelGroupId: PanelIds, panelSizes: Map<PanelIds, number>, stores: Stores) => (event) => {
  const { settingsStore } = stores;
  const { settings } = settingsStore;
  if (!settingsStore.settings) {
    return; // not available yet
  }

  const newFlex = getSafe(() => event.component.props.flex as number);
  panelSizes.set(panelGroupId, newFlex);

  settingsStore.addEditItemDebounced(settings, true, undefined, false);
}

export const getPanelProps = (panelId: PanelIds, panelSizes: Map<PanelIds, number>, stores: Stores): Partial<ReflexElementProps> => {
  const { drawToolsStore, settingsStore } = stores;
  let flex = panelSizes.get(panelId);

  let minSize = settingsStore.isTouchDevice ? 0 : 50;
  if (panelId === PanelIds.LeftBarMiddle) {
    if (drawToolsStore.shouldShowDrawingButtonOptions) {
      minSize = 200;
    }
    if (drawToolsStore.shouldShowDrawingButtonMeasurements) {
      minSize = 300;
    }
  }

  return {
    onStopResize: handlePanelSizeChange(panelId, panelSizes, stores),
    // dont initialize flex for the last elements, let it calculate so all elements add to flex: 1
    //flex: ['RightBar','RightBarBottom','LeftBarBottom','MainColumnBottom'].includes(panelId) ? undefined : flex,
    flex,
    minSize,
  };
}