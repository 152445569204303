import classnames from 'classnames';
import BackgroundImageComponent from 'components/drawing/BackgroundImageComponent/BackgroundImageComponent';
import ShapesComponent from 'components/drawing/ShapesComponent/ShapesComponent';
import Shape from 'models/Shape';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { isSafari } from 'utils/Utils';
import { getScaleToFit } from '../../../utils/ShapeUtil';
import ObserverComponent, { ObserverComponentProps } from '../ObserverComponent';

const inlineStyles = (
  // magic loader chain to load compiled sass
  require('!!raw-loader!extract-loader!css-loader!sass-loader!components/drawing/SurfaceComponent/SurfaceComponent.scss').default + ' ' +
  require('!!raw-loader!extract-loader!css-loader!sass-loader!components/drawing/WallComponent/WallComponent.scss').default + ' ' +
  require('!!raw-loader!extract-loader!css-loader!sass-loader!components/drawing/DimensionSurface/DimensionSurface.scss').default + ' ' +
  require('!!raw-loader!extract-loader!css-loader!sass-loader!components/drawing/DimensionLine/DimensionLine.scss').default
);

const montserratFontBase64 = require('!!raw-loader!./montserrat-font-base64.txt').default;

interface ShapePreviewProps {
  shapes: Shape[],
  treeNode: TreeNode,
  width?: number,
  height?: number,
  containerWidth?: number,
  containerHeight?: number,
  className?: string,
  shouldShowDimensions?: boolean,
  shouldInlineStyles?: boolean,
  shouldShowBackgroundImage?: boolean,
  withLoadingDelay?: boolean,
  onClick: () => void,
}

interface ShapePreviewState {
  shouldShow: boolean,
}

export default class ShapePreview extends ObserverComponent<ShapePreviewProps, ShapePreviewState> {
  svgRef;
  loadingTimeout;

  state = {
    shouldShow: false,
  }

  // real svg onLoad event has a bug in react https://github.com/facebook/react/issues/9607
  // so using ref instead
  onLoadSvg = (ref) => {
    this.svgRef = ref;

    // force refresh disappearing text on safari
    if (this.svgRef && isSafari() && !this.props.withLoadingDelay) {
      const filter = this.svgRef.querySelector('filter#textBackground');

      if (!filter) {
        return;
      }

      const filterParent = filter.parentNode;
      filter.remove();

      setTimeout(() => {
        try {
          if (this.svgRef) {
            filterParent.appendChild(filter);
          }
        } catch(e) {}
      }, 100)
    }
  }

  // shouldnt be needed because same component should be reused inside
  componentDidMount() {
    if (!this.props.withLoadingDelay) {
      this.setState({ shouldShow: true });
    }
    this.loadingTimeout = setTimeout(() => this.setState({ shouldShow: true }), 100 + Math.random() * 300);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (!this.props.withLoadingDelay) {
      return;
    }

    clearTimeout(this.loadingTimeout);
  }

  componentDidUpdate(prevProps: Readonly<ShapePreviewProps & ObserverComponentProps>, prevState: Readonly<ShapePreviewState>, snapshot?: any): void {
    const test = 0;
  }

  // this renders a lot because of problem with tree refresh
  _render() {
    const { userInfoStore, reportsStore } = this.context;
    const { width, height, containerWidth, containerHeight, className, shapes, shouldInlineStyles, onClick, shouldShowDimensions, treeNode, shouldShowBackgroundImage, withLoadingDelay } = this.props;
    const { shouldShow } = this.state;

    const { viewBoxRectWithoutDimensions, childDrawingNode } = treeNode;
    const { backgroundImage } = childDrawingNode || {};

    if ((withLoadingDelay && !shouldShow) || shapes.length == 0 && (!backgroundImage || !shouldShowBackgroundImage)) {
      return <div className={className} style={{ width, height }} />;
    }

    const scale = containerWidth && containerHeight && getScaleToFit(9999, viewBoxRectWithoutDimensions, containerWidth, containerHeight);

    const paddingFactor = shouldShowDimensions ? 30 : 2;
    const viewBox = scale && `${viewBoxRectWithoutDimensions.x - paddingFactor / scale}  ${viewBoxRectWithoutDimensions.y - paddingFactor / scale} ${(viewBoxRectWithoutDimensions.width + paddingFactor * 2 / scale) || 5} ${(viewBoxRectWithoutDimensions.height + paddingFactor * 2 / scale) || 5}`;

    const logoColor = reportsStore.report?.logoColors?.color1 || 'rgb(24,39,53)';
    const logoLighterColor = logoColor.replace(')', ',0.03)');

    const svgTag = (
      <svg
        ref={ref => this.onLoadSvg(ref)}
        className={classnames('ShapePreview', className)}
        width={width}
        height={height}
        onClick={onClick}
        viewBox={viewBox}
      >
        <defs>
          {/* duplicate */}
          <filter x="0" y="0" width="1" height="1" id="textBackground">
            <feFlood floodColor="rgba(255,255,255, 1.0)" />
            <feComposite in="SourceGraphic" />
          </filter>
          {/* duplicate and bad because chrome might use svg defs from a different component */}
          <g id="countPoint" strokeWidth="1">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
          </g>
        </defs>
        {shouldInlineStyles && (
          <style>
            {inlineStyles}

            {`
            .nodeIsSelected .SurfaceComponent,
            .nodeIsSelected .WallComponent  {
              color: ${logoColor};
            }
            .nodeIsSelected .SurfaceComponent .measurementPath {
              fill: ${logoColor};
            }

            @font-face {
              font-family: 'montserratregular';
              src: url(data:application/font-woff2;charset=utf-8;base64,${montserratFontBase64}) format('woff2');
              font-weight: normal;
              font-style: normal;

            }

            tspan.text {
              font-family: montserratregular !important;
              fill: rgba(55,55,55) !important;
            }
            `}
          </style>
        )}

        {backgroundImage && (
          <BackgroundImageComponent backgroundImage={backgroundImage} shouldShow={!!shouldShowBackgroundImage} isSvg />
        )}

        <ShapesComponent node={treeNode} shapes={shapes} readonly shouldShowDimensions={shouldShowDimensions} containerWidth={containerWidth} containerHeight={containerHeight} />
      </svg>
    );

    return svgTag;
  }
}