
import { DrawToolType } from 'constants/DrawToolType';
import BackgroundImageScale from 'models/BackgroundImageScale';
import * as React from 'react';
import Stores from 'stores/Stores';
import { drawToolIconsByType } from 'utils/DrawToolsUtils';
import i18n from 'utils/i18n';

const styles = require('./DrawingInstructions.module.scss');

const TitleBlock = ({ drawToolType, extraText }: { drawToolType: DrawToolType, extraText?: JSX.Element | string }) => (
  <div className={styles.title}>
    {drawToolIconsByType[drawToolType]}
    <div><span className={styles.toolName}>{i18n.t(drawToolType)}</span> {extraText ? ': ' : ''} {extraText}</div>
  </div>
);

export const getDrawingInstructions = (drawToolType: DrawToolType, stores: Stores) => {
  const { drawToolsStore, settingsStore, shapesStore, treeNodesStore } = stores;

  switch (drawToolType) {
    case DrawToolType.GeneralDraw:

      const isDrawing = !!drawToolsStore.lineBeingAdded;
      return (
        <>
          <TitleBlock drawToolType={drawToolType} extraText={!isDrawing && i18n.t('Click anywhere to create a new line.')} />
          <div>
            {isDrawing && (
              <>
                {
                  settingsStore.isImperial && settingsStore.isTouchDevice && !drawToolsStore.isTouchQuickDrawMode && (
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("Use the <b>hyphen (-)</b> to separate feet and inches.") }} />
                  )
                }

                {!settingsStore.isTouchDevice && (<div dangerouslySetInnerHTML={{ __html: i18n.t("Use <b>\"Tab\"</b> to modify the angle and <b>\"Enter\"</b> to start the next line.") }} />)}
                {!settingsStore.isTouchDevice && (<div dangerouslySetInnerHTML={{ __html: i18n.t("<b>\"Ctrl+Z\"</b> will remove the last point added.") }} />)}
                {settingsStore.isTouchDevice && (<div dangerouslySetInnerHTML={{ __html: i18n.t("Use <b>\"TWO FINGERS\"</b> to move the image.") }} />)}

                <div>
                  {i18n.t('When you are done, click on the drawing\'s starting point or end point.')}
                </div>
              </>
            )}
          </div>
        </>
      );
    case DrawToolType.BackgroundImageSetScale:
      return (
        <>
          <TitleBlock
            drawToolType={drawToolType}
            extraText={!shapesStore.backgroundImageScaleShape && (<>
              <div>{i18n.t('Click the extremity of any line on the image for which the length is known.')}</div>
              {!!treeNodesStore.rootNode?.backgroundImage?.DPI && (
                <div className={styles.manualScaleLink}>
                  <a
                    onClick={() => {
                      shapesStore.backgroundImageScaleShape = shapesStore.createItem(BackgroundImageScale);
                      shapesStore.shouldShowBackgroundImageScaleInput = true;
                      shapesStore.zoomController.zoomCompletelyOut();
                    }}
                  >
                    {i18n.t('Or click here if no length is known.')}
                  </a>
                </div>
              )}
              {settingsStore.isTouchDevice && (<div dangerouslySetInnerHTML={{ __html: i18n.t("Use <b>\"TWO FINGERS\"</b> to move the image.") }} />)}
            </>)}
          />
          <div>
            {shapesStore.backgroundImageScaleShape && !shapesStore.shouldShowBackgroundImageScaleInput && (
              <div>
                {i18n.t('Now, click the other extremity of the line you chose for scale calibration.')}
              </div>
            )}

            {shapesStore.backgroundImageScaleShape?.lengthPixels > 0 && shapesStore.shouldShowBackgroundImageScaleInput && (
              <div>
                {i18n.t('Provide the length of the line to finish calibration.')}
              </div>
            )}

            {shapesStore.backgroundImageScaleShape?.lengthPixels === 0 && shapesStore.shouldShowBackgroundImageScaleInput && (
              <div>
                {i18n.t('Choose scale from the list or restart calibration as needed.')}<br />
                {i18n.t('ALWAYS USE PLANS COMING DIRECTLY FROM AN ARCHITECT, NOT TEMPERED BY "PRINT TO PDF" OPTIONS.')}
              </div>
            )}
          </div>
        </>
      );

    case DrawToolType.BackgroundImageCrop:
      return (
        <>
          <TitleBlock
            drawToolType={drawToolType}
            extraText={!shapesStore.isCroppingBackgroundImageSecondPoint && i18n.t('Make sure you previously calibrated the scale before using this tool. To start cropping, click the top-left corner of the section of the image you want to keep.')}
          />
          <div>
            {shapesStore.isCroppingBackgroundImageSecondPoint && !shapesStore.isConfirmingBackgroundImageCrop && (
              <div>
                {i18n.t('Now, click bottom-right corner to continue cropping.')}
              </div>
            )}
            {shapesStore.isCroppingBackgroundImageSecondPoint && shapesStore.isConfirmingBackgroundImageCrop && (
              <div>
                {i18n.t('Proceed with crop if you are satisfied.')}
              </div>
            )}
          </div>
        </>
      );

    case DrawToolType.PredefinedShapeRectangle:
      return (
        <>
          <TitleBlock
            drawToolType={drawToolType}
            extraText={!drawToolsStore.segmentsGroupBeingAdded && i18n.t('To draw a rectangle, click the top-left corner of the rectangle to create.')}
          />
          <div>
            {drawToolsStore.segmentsGroupBeingAdded && (
              <div>
                {i18n.t('Move the mouse to resize the rectangle then click once you are satisfied.')}
              </div>
            )}
          </div>
        </>
      );

    case DrawToolType.GeneralCount:
      return (
        <>
          <TitleBlock
            drawToolType={drawToolType}
            extraText={!drawToolsStore.selectedCountToolMeasurement
              ? i18n.t('First, select the measurement to use for this count.')
              : i18n.t('Click anywhere to mark an item and add it to your count.')
            }
          />
        </>
      );

    default:
      return null;
  }
}