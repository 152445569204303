
import { Button, Tab, Tabs } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { PdfIcon, RulerIcon } from 'components/common/Icons';
import ObserverComponent from 'components/common/ObserverComponent';
import { ReportTabs } from 'constants/ReportTabs';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./ReportNav.module.scss');

export default class ReportNav extends ObserverComponent {
  _render() {
    const { reportsStore } = this.context;
    const { selectedTab, pdfUrl } = reportsStore;
    return (
      <div className={styles.root}>
        <Tabs
          className={styles.tabs}
          value={reportsStore.selectedTab}
          onChange={reportsStore.onChangeSelectedTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab icon={<RulerIcon />} label={<span className='hidden-vertical-phone'>{i18n.t('Setup')}</span>} />
          <Tab icon={<PdfIcon />} label={<span className='hidden-vertical-phone'>{i18n.t('Preview')}</span>} />
          {/*<Tab icon={<NotificationsIcon />} label={i18n.t('View notifications')} />*/}
        </Tabs>

        {selectedTab === ReportTabs.PDF && pdfUrl && (
          <span className='hidden-phone'>
            <Button className={styles.button} onClick={reportsStore.download} >
              <DownloadIcon />&nbsp;
              {i18n.t('Download')}
            </Button>
          </span>
        )}
      </div>
    )
  }
}