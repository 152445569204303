import ObserverComponent from 'components/common/ObserverComponent';
import { DRAWING_SCALE } from 'constants/Constants';
import { DrawToolType } from 'constants/DrawToolType';
import { NodeType } from "constants/NodeType";
import { isEmpty, last, throttle } from 'lodash';
import { action } from "mobx";
import Point from "models/Point";
import Surface from "models/Surface";
import TreeNode from "models/TreeNode";
import Wall from "models/Wall";
import * as React from "react";
import { cursorToLocalPoint } from "utils/Coords";
import { drawToolMouseMoveThrottledBase, getDefaultMeasurements, MOUSE_MOVE_THROTTLE_TIME, segmentsGroupNodeNameByType, segmentsNodeNameByType, surfaceNodeNameByType } from "utils/DrawToolsUtils";
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { FEET_PER_METER } from "utils/MeasurementConverter";
import { normalizeMouseOrTouchEvent } from 'utils/TouchUtil';
import { getSafe } from "utils/Utils";


export class AddDrawingTool extends ObserverComponent/* implements Add ? */ {
  svgTag: SVGSVGElement;

  componentDidMount() {
    const { settingsStore } = this.context;
    this.svgTag.addEventListener(settingsStore.isTouchDevice ? 'touchend' : 'click', this.mouseDown);
    // capture because need to happen slightly before zoom touch move to prevent zoom on object glitch
    window.addEventListener(settingsStore.isTouchDevice ? 'touchmove' : 'mousemove', this.mouseMove, {capture: true});
    window.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    const { settingsStore } = this.context;

    super.componentWillUnmount();
    this.svgTag.removeEventListener(settingsStore.isTouchDevice ? 'touchend' : 'click', this.mouseDown);
    window.removeEventListener(settingsStore.isTouchDevice ? 'touchmove' : 'mousemove', this.mouseMove, {capture: true});
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  createSegmentsGroup = (parentNode: TreeNode, batch: WriteBatch) => {
    const { treeNodesStore, drawToolsStore, shapesStore } = this.context;

    const { selectedTool } = drawToolsStore;

    const segmentsGroupName = i18n.tAll(segmentsGroupNodeNameByType[selectedTool]);

    // try to find either selected node or childNode with same groupname
    drawToolsStore.segmentsGroupBeingAdded = getSafe(() => [
      treeNodesStore.selectedTreeNode,
      ...treeNodesStore.selectedTreeNode.children
    ].find(node => node._name.en === segmentsGroupName._name.en)
    );

    if (!drawToolsStore.segmentsGroupBeingAdded) {
      drawToolsStore.segmentsGroupBeingAdded = new TreeNode(this.context);
      drawToolsStore.segmentsGroupBeingAdded._name = segmentsGroupName;
      drawToolsStore.segmentsGroupBeingAdded.isExpanded = false;
      treeNodesStore.batchAddEditItem(drawToolsStore.segmentsGroupBeingAdded, batch);
      treeNodesStore.appendNode(
        drawToolsStore.segmentsGroupBeingAdded,
        // TODO find closest node that's not a shape
        parentNode,
        undefined,
        batch,
      );

      if (!drawToolsStore.segmentsGroupBeingAdded.ancestors.some(a => a.isSelected)) {
        treeNodesStore.selectedTreeNode = drawToolsStore.segmentsGroupBeingAdded.parent || treeNodesStore.rootNode;
      }
    }

    // surface
    const newSurface = new Surface(this.context);
    shapesStore.batchAddEditItem(newSurface, batch);

    drawToolsStore.surfaceNodeBeingAdded = new TreeNode(this.context);
    drawToolsStore.surfaceNodeBeingAdded._name = i18n.tAll(surfaceNodeNameByType[selectedTool]);
    drawToolsStore.surfaceNodeBeingAdded.shape = newSurface;

    treeNodesStore.batchAddEditItem(drawToolsStore.surfaceNodeBeingAdded, batch);
    const surfaceMeasurements = getDefaultMeasurements(NodeType.Surface, selectedTool, this.context);
    treeNodesStore.toggleNodeMeasurements(
      surfaceMeasurements,
      true,
      drawToolsStore.surfaceNodeBeingAdded,
      true,
      batch
    );

    // Apply tool options
    /*const toolOptions = drawToolsStore.drawToolsOptions.get(selectedTool) as DrawToolsOption[];
    toolOptions.forEach(option => {
      if (surfaceMeasurements.map(measurement => measurement.id).includes(option.measurementType)) {
        const surfaceFormula = drawToolsStore.surfaceNodeBeingAdded.measurementValues.get(option.measurementType);
        surfaceFormula.formula = option.formula.formula;
      }
    });*/
  }

  @action.bound
  mouseDown(ev: MouseEvent & TouchEvent) {
    ev = normalizeMouseOrTouchEvent(ev, this.context);

    const { drawToolsStore, commonStore, treeNodesStore, shapesStore, snapStore, settingsStore } = this.context;
    if (
      ev.ctrlKey ||
      ev.button || // button is either 0 for left click or undefined for touch event
      shapesStore.isDragPanningOnDrawing ||
      getSafe(() => ['INPUT', 'BUTTON'].includes((ev.target as HTMLElement).tagName)) ||
      (ev.touches && !isEmpty(drawToolsStore.segmentsGroupBeingAdded?.children) && ev.pageX && !drawToolsStore.isTouchQuickDrawMode)
    ) {
      return;
    }

    const batch = firestoreBatch(this.context);

    drawToolsStore.shouldLocklineBeingAddedLength = false;

    commonStore.isBigUpdateOngoing = true;

    const { selectedTool } = drawToolsStore;
    const { rootNode, selectedTreeNode } = treeNodesStore;

    const mousePt = drawToolsStore.lastMousePt || cursorToLocalPoint(ev, this.svgTag);

    if (selectedTreeNode !== rootNode && !selectedTreeNode.hasChildren) {
      selectedTreeNode.isExpanded = false;
    }

    let parentNode = selectedTreeNode || rootNode;

    while (!parentNode.areChildrenAllowed) {
      parentNode = parentNode.parent;
    }

    if (!drawToolsStore.segmentsGroupBeingAdded) {
      this.createSegmentsGroup(parentNode, batch);
    }

    if (getSafe(() => drawToolsStore.lineBeingAdded.startPt.distancePixels(mousePt) < 10)) {
      drawToolsStore.finalizeUnclosedShape(batch);
      batch.commit();

      commonStore.setIsBigUpdateOngoing(true, true);
      return;
    }

    if (drawToolsStore.lineBeingAdded && drawToolsStore.firstPt.distancePixels(mousePt) < 10) {
      drawToolsStore.finalizeClosedShape(parentNode, batch);
      batch.commit();
      return;
    }

    commonStore.quickResetFlagAfterInactivity.cancel();
    commonStore.resetFlagAfterInactivity.cancel();
    if (!commonStore._isBigUpdateOngoing) {
      commonStore._isBigUpdateOngoing = true;
    }

    // Prepare new wall
    const newWall = shapesStore.createItem(Wall);
    newWall.startPt = mousePt.clone();
    newWall.startPt.isSnappable = true;

    newWall.endPt = mousePt.clone();

    // Don't save to db yet, because we don't know the endPt
    shapesStore.batchAddEditItem(newWall, batch);

    // attach to a tree node
    const lineNode = treeNodesStore.createItem(TreeNode);

    lineNode._name = i18n.tAll(segmentsNodeNameByType[selectedTool], { number: drawToolsStore.segmentsGroupBeingAdded.children.length + 1 });
    lineNode.shape = newWall;
    newWall.treeNode = lineNode;

    const lineMeasurements = getDefaultMeasurements(NodeType.Line, selectedTool, this.context);
    treeNodesStore.toggleNodeMeasurements(
      lineMeasurements,
      true,
      lineNode,
      true,
      batch
    );

    // Apply tool options
    /*const toolOptions = drawToolsStore.drawToolsOptions.get(selectedTool) as DrawToolsOption[];
    toolOptions.forEach(option => {
      const lineFormula = lineNode.measurementValues.get(option.measurementType);
      lineFormula.formula = option.formula.formula;
    });*/

    if (drawToolsStore.lineBeingAdded) {
      // save previous wall
      drawToolsStore.lineBeingAdded.endPt.isSnappable = true;
      shapesStore.batchAddEditItem(drawToolsStore.lineBeingAdded, batch);
    } else {
      drawToolsStore.firstPt = newWall.startPt;
    }

    treeNodesStore.batchAddEditItem(lineNode, batch);
    treeNodesStore.appendNode(
      lineNode,
      drawToolsStore.segmentsGroupBeingAdded,
      undefined,
      batch,
    );

    const surface = drawToolsStore.surfaceNodeBeingAdded.shape;
    surface.addPoint(mousePt.clone());
    shapesStore.batchAddEditItem(surface, batch);

    drawToolsStore.nodeBeingAdded = lineNode;
    drawToolsStore.lineBeingAdded = newWall;

    snapStore._snapPointsMap.clear();

    // ideally wait till we choose a different tool
    batch.commit();



    //shapesStore.zoomController.adjustSvgSize();
  }

  // problème l'événement doit être sur le document, pas seulement sur svg :(
  // a moins que événement pour autoscroll soit dans un autre fichier
  @action.bound
  mouseMove(ev: MouseEvent) {
    // prevent native scroll (?)
    ev.preventDefault();

    this.mouseMoveThrottled(ev);
  }

  @action
  mouseMoveThrottled = throttle((ev: MouseEvent, skipLocalCoordsConversion: boolean = false) => {
    const { snapStore, drawToolsStore, commonStore, settingsStore, shapesStore, undoStore } = this.context;
    let cursorPt = snapStore.cursorPosition;
    const { lastKnownCursorPos } = shapesStore.zoomController

    if (
      undoStore.isBusy || 
      // should find a better way because 1 finger will touch before the second one
      (ev?.touches && ev.touches?.length !== 1)
    ) {
      return;
    }

    if (!ev) {
      ev = { pageX: lastKnownCursorPos.x, pageY: lastKnownCursorPos.y }
    }

    ev = normalizeMouseOrTouchEvent(ev, this.context);

    // auto scroll
    drawToolMouseMoveThrottledBase(ev, this.svgTag, this.context);

    if (getSafe(() => ['INPUT', 'BUTTON'].includes((ev.target as HTMLElement).tagName))) {
      return;
    }

    if (!skipLocalCoordsConversion) {
      cursorPt = cursorToLocalPoint(ev, this.svgTag);
      snapStore.cursorPosition = cursorPt;
    }

    const { snapPoint, snapLineHorizontal, snapLineVertical } = snapStore;
    let newEndPt = cursorPt;

    if (snapLineHorizontal) {
      newEndPt = new Point(
        newEndPt.x,
        snapLineHorizontal.points[1].y,
      );
    }

    if (snapLineVertical) {
      newEndPt = new Point(
        snapLineVertical.points[1].x,
        newEndPt.y,
      );
    }

    // distance snap, maybe move outside
    // ignore snap settings for this one (?)
    if (drawToolsStore.lineBeingAdded /*&& userInfoStore.user?.isDrawingSnapPointEnabled */) {
      commonStore.isBigUpdateOngoing = true;

      const prevPt = drawToolsStore.lineBeingAdded.startPt;
      const isImperial = settingsStore.isImperial;
      const scale = DRAWING_SCALE;
      const distance = newEndPt.distance(prevPt, scale);

      const distanceRealWorld = isImperial
        ? distance * FEET_PER_METER
        : distance

      const distanceFractionalPart = distanceRealWorld - Math.floor(distanceRealWorld);

      const snapDistanceStep = isImperial
        ? 12 // snap to inch
        : 1 / 0.01 // 1 cm

      const snappedDistanceFractionalPart = Math.round(distanceFractionalPart * snapDistanceStep) / snapDistanceStep;
      const snappedDistanceRealWorld = Math.floor(distanceRealWorld) + snappedDistanceFractionalPart;

      let snappedDistance = isImperial
        ? snappedDistanceRealWorld / FEET_PER_METER * scale
        : snappedDistanceRealWorld * scale;

      if (drawToolsStore.shouldLocklineBeingAddedLength) {
        // lock distance
        snappedDistance = drawToolsStore.lineBeingAdded.length * scale;
      }

      const angle = Math.atan2(newEndPt.y - prevPt.y, newEndPt.x - prevPt.x);

      newEndPt = new Point(
        prevPt.x + Math.cos(angle) * snappedDistance,
        prevPt.y + Math.sin(angle) * snappedDistance,
      );
    }

    if (snapPoint && !drawToolsStore.shouldLocklineBeingAddedLength) {
      newEndPt = snapPoint.clone();
    }

    newEndPt.isSnappable = false; // avoid snapping on itself

    drawToolsStore.lastMousePt = newEndPt;

    if (drawToolsStore.lineBeingAdded) {
      drawToolsStore.lineBeingAdded.endPt = newEndPt;
    }
  }, MOUSE_MOVE_THROTTLE_TIME)

  onKeyDown = async (e: KeyboardEvent) => {
    const { drawToolsStore, undoStore } = this.context;

    if (e.key.toLowerCase() === 'z' && (e.metaKey || e.ctrlKey)) {
      if (undoStore.canUndo) {
        await undoStore.undo();
        this.afterUndoOrRedo();
      }
    } else if (e.key.toLowerCase() === 'y' && (e.metaKey || e.ctrlKey)) {
      if (undoStore.canRedo) {
        await undoStore.redo();
        this.afterUndoOrRedo();
      }
    } else if (e.key === 'Escape') {
      drawToolsStore.finalizeUnclosedShape();
      drawToolsStore.selectedTool = DrawToolType.Select;
    }
  }

  afterUndoOrRedo = () => {
    const { treeNodesStore, drawToolsStore, snapStore, undoStore } = this.context;
    const { lineBeingAdded } = drawToolsStore;

    if (lineBeingAdded && drawToolsStore.nodeBeingAdded && drawToolsStore.segmentsGroupBeingAdded) {
      const newLastPoint = last(drawToolsStore.surfaceNodeBeingAdded.shape?.points || []);
      if (newLastPoint) {
        newLastPoint.isSnappable = false;
        
        drawToolsStore.nodeBeingAdded = last(drawToolsStore.segmentsGroupBeingAdded.children);
        drawToolsStore.lineBeingAdded = getSafe(() => drawToolsStore.nodeBeingAdded.shape as Wall);
        
        this.mouseMoveThrottled(null, true);
      } else {
        drawToolsStore.reset();
      }

      drawToolsStore.lastMousePt = null;
    } else {
      // try to go back to editing the shape we are undoing
    }

    snapStore._snapPointsMap.clear();
  }

  public _render() {
    return (
      <g id="AddDrawingTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement) || this.svgTag} />
    );
  }
}
