
import { Button, Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { Unit } from 'constants/Unit';
import Dialog from 'models/Dialog';
import * as React from 'react';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { formatCurrency, formatDecimal, formatPercentage } from 'utils/NumberFormatter';
import { showUpgradeSubscriptionDialog } from 'utils/SubscriptionUtil';
import { formatUnit } from 'utils/UnitFormatter';
import { round } from 'utils/Utils';
import FeesCalculationEditDialog from '../FeesCalculationEditDialog/FeesCalculationEditDialog';
import FooterEditableFee from '../FooterEditableFee/FooterEditableFee';
import ObserverComponent from '../ObserverComponent';
import TaxEditDialog from '../TaxEditDialog/TaxEditDialog';

const styles = require('./FooterComponent.module.scss');

export default class FooterComponent extends ObserverComponent {
  showTaxesDialog = () => {
    const { settingsStore, dialogsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <TaxEditDialog open={open} dialogId={newDialog.id} settings={settingsStore.settings} />
    )
    dialogsStore.showDialog(newDialog);
  }

  toggleIncludeTaxInTotal = () => {
    const { userInfoStore } = this.context;
    const { user } = userInfoStore;
    if (!user) {
      return;
    }

    user.shouldIncludeTaxInFooterTotal = !user.shouldIncludeTaxInFooterTotal;
    const batch = firestoreBatch(this.context);
    batch.isUndoable = false;
    userInfoStore.addEditItem(user, true, ['shouldIncludeTaxInFooterTotal'], batch);
    batch.commit();
  }

  showTaxesAndFeesOptions = () => {
    const { settingsStore, dialogsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <FeesCalculationEditDialog open={open} dialogId={newDialog.id} settings={settingsStore.settings} />
    )
    dialogsStore.showDialog(newDialog);
  }

  _render() {
    const { treeNodesStore, settingsStore, totalsStore, userInfoStore, subscriptionsStore } = this.context;
    const { rootNode, selectedTreeNode } = treeNodesStore;
    const { settings } = settingsStore;
    const { user } = userInfoStore;
    if (!rootNode || !settings || !selectedTreeNode || !user) {
      return null;
    }
    const { subtotalBeforeFees, subtotalHours, subtotalLabour, subtotalMaterial } = rootNode;
    const { subtotalBeforeFees: selectedNodeSubtotalBeforeFees, subtotalHours: selectedNodeSubtotalHours, subtotalLabour: selectedNodeSubtotalLabour, subtotalMaterial: selectedNodeSubtotalMaterial } = selectedTreeNode;
    const { taxesTotal, totalBeforeTaxes, allIncludedTotal } = totalsStore;
    const { fees, taxesPercentage } = settings;
    const { shouldIncludeTaxInFooterTotal, shouldShowFooterTimeTotals } = user;

    return (
      <div className={styles.root + ' hidden-horizontal-phone'}>
        <div className={styles.main}>
          <Tooltip title={<div>
            <div>{i18n.t('Project subtotal (before fees)')}</div>
            <div>
              <span>{i18n.t('Material')}:</span>&nbsp;<span>{formatCurrency(subtotalMaterial)}</span>
              <br />
              <span>{i18n.t('Labour & misc.')}:</span>&nbsp;<span>{formatCurrency(subtotalLabour)}</span>
            </div>
          </div>}
          >
            <div className={styles.subTotal + ' ' + styles.footerButton}>
              <div className="hidden-phone">{i18n.t('Project subtotal (before fees)')}</div>
              <div className="visible-phone">{i18n.t('Subtotal (before fees)')}</div>

              <div>{formatCurrency(subtotalBeforeFees)}</div>
              {shouldShowFooterTimeTotals && <div>({formatDecimal(round(subtotalHours, 1))} {formatUnit(Unit.Hour)})</div>}
            </div>
          </Tooltip>

          <Tooltip disableHoverListener leaveDelay={9999999} title={
            <div>
              <div>{i18n.t('Costs breakdown for {{selectedNodeName}}' + (selectedTreeNode.hasNonDrawingRootChildren ? ' and subgroups' : ''), { selectedNodeName: selectedTreeNode?.name || '' })}</div>
              <div><div>{i18n.t('Material')}:</div><div>{formatCurrency(selectedNodeSubtotalMaterial)}</div></div>
              <div><div>{i18n.t('Labour & misc.')}:</div><div>{formatCurrency(selectedNodeSubtotalLabour)}</div></div>
            </div>
          }
          >
            <div className={styles.subTotal + ' ' + styles.footerButton + ' hidden-phone'}>
              <div>{i18n.t('Selected group subtotal')}</div>
              <div>{formatCurrency(selectedNodeSubtotalBeforeFees)}</div>
              {shouldShowFooterTimeTotals && <div>({formatDecimal(round(selectedNodeSubtotalHours, 1))} {formatUnit(Unit.Hour)})</div>}
            </div>
          </Tooltip>

          {fees.map((fee, index) => (
            <FooterEditableFee
              parentClassName="hidden-phone"
              className={styles.footerButton}
              fee={fee}
              key={index}
            />
          ))}

          <div className={styles.taxes + ' ' + styles.footerButton + ' hidden-phone'} onClick={this.showTaxesDialog}>
            <div>{i18n.t('Taxes')} ({formatPercentage(taxesPercentage, true)})</div>
            <div>{formatCurrency(taxesTotal)}</div>
          </div>

          <div className={styles.grandTotal + ' ' + styles.footerButton} onClick={this.toggleIncludeTaxInTotal}>
            {/*<Tooltip title={i18n.t('Fees & taxes calculation options')}>
            <IconButton className={styles.settingsButton} onClick={this.showTaxesAndFeesOptions}>
              <SettingsIcon  />
            </IconButton>
        </Tooltip>*/}
            <div className="hidden-phone">{i18n.t('Project total')} ({shouldIncludeTaxInFooterTotal ? i18n.t('after tx.') : i18n.t('before tx.')})</div>
            <div className="visible-phone">{i18n.t('Total')} ({shouldIncludeTaxInFooterTotal ? i18n.t('after fees and tx.') : i18n.t('after fees, before tx.')})</div>
            {/* needs to be in report */}
            <div>
              {shouldIncludeTaxInFooterTotal ? (<>
                {formatCurrency(allIncludedTotal)}
              </>) : (<>
                {formatCurrency(totalBeforeTaxes)}
              </>)}
            </div>
          </div>
        </div>

        {subscriptionsStore.isTrial && (
          <div className={styles.upgradeButton + ' visible-phone'}>
            <Button onClick={showUpgradeSubscriptionDialog(this.context)} variant="contained" color="primary">
              <StarIcon />&nbsp; {i18n.t('Upgrade')}
            </Button>
          </div>
        )}
      </div>
    )
  }
}