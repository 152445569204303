
import ObserverComponent from 'components/common/ObserverComponent';
import { BuiltinMeasurementSubcategories } from 'constants/BuiltinCategories';
import { UnitType } from 'constants/UnitType';
import { first } from 'lodash';
import { observer } from 'mobx-react';
import Measurement from 'models/Measurement';
import * as React from 'react';
import { STRING_SEPARATOR, groupItemsByCategSubcateg, mergeCategsAndSubcategsGroups } from 'utils/CategoryUtil';
import { showCreateNewMeasurementDialog } from 'utils/MeasurementUtil';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import ComboBox from '../ComboBox/ComboBox';

const styles = require('./MeasurementsCombobox.module.scss');

interface MeasurementsComboboxProps {
  onChange?: (measurement: Measurement, action: 'select-option' | 'deselect-option') => void,
  selectedMeasurement?: Measurement,
  disabled?: boolean,
  className?: string,
  unitTypes?: UnitType[],
}

export default class MeasurementsCombobox extends ObserverComponent<MeasurementsComboboxProps> {
  static defaultProps = {
    unitTypes: [UnitType.Unit]
  }

  commitChange = (measurement: Measurement) => {
  }

  addEditMeasurement = (measurementName?: string) => {
    const { measurementsStore, drawToolsStore } = this.context;
    const { unitTypes } = this.props;

    if (measurementName) {
      const newMeasurement = measurementsStore.createItem(Measurement);
      newMeasurement.name = measurementName;
      newMeasurement.unitType = first(unitTypes);

      measurementsStore.addEditItem(newMeasurement);
      drawToolsStore.selectedCountToolMeasurement = newMeasurement;
    } else {
      const newMeasurement = measurementsStore.createItem(Measurement);
      newMeasurement.unitType = UnitType.Unit;
      showCreateNewMeasurementDialog(this.context, newMeasurement);
      drawToolsStore.selectedCountToolMeasurement = newMeasurement;
    }
  }

  onChange = ({ value }, { action, option, removedValue }) => {
    const { measurementsStore } = this.context;
    // option.value is for isMulti mode
    const measurement = measurementsStore.getItem(option?.value || removedValue?.value || value);

    if (action === 'remove-value') {
      // why do they differentiate between the 2 ?
      action = 'deselect-option';
    }
    this.props.onChange(measurement, action);
  }

  onCreateMeasurement = (measurementName?: string) => this.addEditMeasurement(measurementName);

  _render() {
    const { measurementsStore, categoriesStore, settingsStore } = this.context;
    const { disabled, className, unitTypes, selectedMeasurement } = this.props;

    const activeInputMeasurements = measurementsStore.activeMeasurements.filter(measurement => (
      unitTypes.includes(measurement.unitType) &&
      measurement.subsubcategory?.id === BuiltinMeasurementSubcategories.InputMeasurements
    ));
    const activeInputMeasurementsByCategSubcateg = mergeCategsAndSubcategsGroups(groupItemsByCategSubcateg(activeInputMeasurements));

    const inactiveInputMeasurements = [
      ...measurementsStore.inactiveMeasurements.filter(measurement => (
        unitTypes.includes(measurement.unitType) &&
        measurement.subsubcategory?.id === BuiltinMeasurementSubcategories.InputMeasurements
      ))
    ];

    const inactiveInputMeasurementsByCategSubcateg = mergeCategsAndSubcategsGroups(groupItemsByCategSubcateg(inactiveInputMeasurements));

    const outputMeasurements = [
      ...measurementsStore.items.filter(measurement => (
        unitTypes.includes(measurement.unitType) &&
        measurement.subsubcategory?.id === BuiltinMeasurementSubcategories.OutputMeasurements
      )),
    ];

    const outputMeasurementsByCategSubcateg = mergeCategsAndSubcategsGroups(groupItemsByCategSubcateg(outputMeasurements));

    const suggestions = [
      ...Object.keys(activeInputMeasurementsByCategSubcateg).map(categSubcategKey => ({
        label: categSubcategKey.split(STRING_SEPARATOR).map(categoryId => categoriesStore.getItem(categoryId)?.name || '').join(' > '),
        options: activeInputMeasurementsByCategSubcateg[categSubcategKey].map(measurement => ({
          index: measurement.index,
          value: measurement.id,
          label: measurement.name,
          isEmpty: false,
        }))
      })),
      ...Object.keys(inactiveInputMeasurementsByCategSubcateg).map(categSubcategKey => ({
        label: categSubcategKey.split(STRING_SEPARATOR).map(categoryId => categoriesStore.getItem(categoryId)?.name || '').join(' > '),
        options: inactiveInputMeasurementsByCategSubcateg[categSubcategKey].map(measurement => ({
          index: measurement.index,
          value: measurement.id,
          label: measurement.name,
          isEmpty: true,
        }))
      })),
      ...Object.keys(outputMeasurementsByCategSubcateg).map(categSubcategKey => ({
        label: categSubcategKey.split(STRING_SEPARATOR).map(categoryId => categoriesStore.getItem(categoryId)?.name || '').join(' > '),
        options: outputMeasurementsByCategSubcateg[categSubcategKey].map(measurement => ({
          index: measurement.index,
          value: measurement.id,
          label: measurement.name,
          isEmpty: true,
        }))
      })),
    ];

    return (
      <div className={styles.root}>
        <ComboBox
          shouldFocusOnMount={!selectedMeasurement}
          placeholder={i18n.t('Choose measurement to count')}
          disabled={disabled}
          className={styles.root + ' ' + (className || '')}
          classes={{ input: styles.input }}
          label={i18n.t('Measurement')}
          addNewLabel={i18n.t('Add new measurement')}
          value={selectedMeasurement?.id}
          onChange={this.onChange}
          onCreateOption={this.onCreateMeasurement}
          formatCreateLabel={inputValue => i18n.t('"{{- measurement}}" (Create measurement)', { measurement: inputValue })
          }
          suggestions={suggestions}
          SuggestionIcon={observer(({ modelId }: { modelId: ModelId }) => <AvatarImageWithFallback model={measurementsStore.getItem(modelId)} />)}
          isVirtualized={false /* todo! */}
          menuIsOpen
          menuStyles={{ marginLeft: settingsStore.isVerticalOrientation ? undefined : '114px' }}
        />
      </div>
    );
  }
}