import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import Panel from 'components/common/Panel/Panel';
import RightBar from 'components/common/RightBar/RightBar';
import SelectedMeasurements from 'components/common/SelectedMeasurements/SelectedMeasurements';
import SelectedMeasurementsHeader from 'components/common/SelectedMeasurementsHeader/SelectedMeasurementsHeader';
import Tasks from 'components/common/Tasks/Tasks';
import TasksHeader from 'components/common/Tasks/TasksHeader';
import TreeNodePropertiesPanel from 'components/common/TreeNodePropertiesPanel/TreeNodePropertiesPanel';
import WorksheetsTree from 'components/common/WorksheetsTree/WorksheetsTree';
import WorksheetsTreeHeader from 'components/common/WorksheetsTree/WorksheetsTreeHeader';
import { PanelIds } from 'constants/PanelIds';
import * as React from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { getPanelProps } from 'utils/PanelUtil';
import './ProjectView.scss';

const styles = require('./ProjectView.module.scss');

class ProjectView extends ObserverComponent<{}, {}> {
  leftBarContainer;

  public _render() {
    const { settingsStore, treeNodesStore } = this.context;

    if (!settingsStore.settings) {
      // good usecase to render spinner or suspense
      return <div className={styles.progress}><CircularProgress /></div>;
    }

    const { selectedTreeNode } = treeNodesStore;

    const { panelSizes } = settingsStore.settings;

    const middleBarPanelProps = getPanelProps(PanelIds.LeftBarMiddle, panelSizes, this.context);

    return (
      <ReflexContainer
        className={classnames(styles.root)}
        orientation="vertical"
      >
        <ReflexElement {...getPanelProps(PanelIds.LeftBar, panelSizes, this.context)}>
          <ReflexContainer orientation="horizontal" ref={ref => this.leftBarContainer = ref}>
            <ReflexElement {...getPanelProps(PanelIds.LeftBarTop, panelSizes, this.context)}>
              <Panel
                header={<WorksheetsTreeHeader />}
                content={<WorksheetsTree />}
              />
            </ReflexElement>

            <ReflexSplitter propagate />

            {/* hack to ensure proper resizing when we need to have enough space to show ok cancel buttons */}
            <ReflexElement {...middleBarPanelProps} style={{ minHeight: middleBarPanelProps.minSize > 50 ? middleBarPanelProps.minSize : undefined }}>
              <TreeNodePropertiesPanel />
            </ReflexElement>

            <ReflexSplitter propagate />

            <ReflexElement {...getPanelProps(PanelIds.LeftBarBottom, panelSizes, this.context)}>
              <Panel
                header={<SelectedMeasurementsHeader />}
                content={
                  <SelectedMeasurements shouldAllowCheckboxes />
                }
              />
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>

        <ReflexSplitter propagate />

        <ReflexElement {...getPanelProps(PanelIds.MainColumn, panelSizes, this.context)}>
          <Panel
            hidden={!selectedTreeNode}
            className={styles.hideable}
            header={<TasksHeader />}
            content={<Tasks />}
          />
        </ReflexElement>

        <ReflexSplitter propagate />

        <ReflexElement {...getPanelProps(PanelIds.RightBar, panelSizes, this.context)}>
          <RightBar />
        </ReflexElement>
      </ReflexContainer>
    );
  }
}

export default ProjectView;