//TODO ensure user cannot load otger users projects


import { CollectionReference as Firebase10CollectionReference, collection as firebaseCollection, onSnapshot, query, where } from '@firebase/firestore';
import Globals from 'Globals';
import { BuiltinCategories } from 'constants/BuiltinCategories';
import { DbLocationType } from 'constants/DbLocationType';
import { compact, debounce, defer, first, isEmpty, isUndefined, last, get as lget, noop, omit, pick, uniq } from 'lodash';
import { action, computed, observable, observe, transaction } from "mobx";
import { computedFn } from 'mobx-utils';
import ModelBase from 'models/ModelBase';
import moment from 'moment';
import DbItemFactory from 'utils/DbItemFactory';
import { modelToPlainObject } from 'utils/DeserializeUtils';
import 'utils/FirebaseInitializedApp';
import { CollectionReference, DocumentSnapshot, FieldPath, UserInfo, WriteBatch, auth, firestore } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { assignToExistingObject } from 'utils/StoreUtil';
import { areModelsDifferent, memoizeDebounce, modelSortFunction, sleep } from 'utils/Utils';
import i18n from 'utils/i18n';
import BaseStore from './BaseStore';
import Stores from './Stores';

export default class FirebaseStore<T extends ModelBase> extends BaseStore {
  storeKey: string = ''; // example  'categories'

  _cachedMasterData = {};
  get cachedMasterData() {
    return this.storeBaseInstance?.cachedMasterData || this._cachedMasterData;
  }
  set cachedMasterData(value) {
    if (!this.storeBaseInstance) {
      this._cachedMasterData = value;
    }
  }

  dbLocationsTypes: Set<DbLocationType> = new Set();

  // cant write back to that collection, but can write to higher collection
  @computed get readonlyDbLocationsTypes(): Set<DbLocationType> {
    const retval = new Set([DbLocationType.Master]);
    if (this.hasExternalUserDbLocation) {
      // external user to avoid overwriting personal by company
      retval.add(DbLocationType.ExternalUser);
      // target company (User or Account)
      retval.add(DbLocationType.User);
    }

    return retval;
  };

  // can't change at all except for keeping an unchanged copy in project collection
  @computed get nonOverridableDbLocationsTypes(): Set<DbLocationType> {
    const retval = new Set<DbLocationType>([
      DbLocationType.Master
    ]);

    if (this.hasExternalUserDbLocation) {
      retval.add(DbLocationType.User); // target company
    }

    return retval;
  };

  isProjectDbOptional = false;
  hasLocalization = true;
  hasCategories = true;

  shouldWaitOnAllCollectionsLoaded = true;

  @computed get sortedDbLocationsTypes() {
    let possibleLocationTypes = Object.values(DbLocationType)
      .filter(dbLocationType => this.dbLocationsTypes.has(dbLocationType));

    return possibleLocationTypes;
  }

  // allows to make less changes in existing code, but could get rid of
  @computed get hasMasterDbLocation() {
    return this.dbLocationsTypes.has(DbLocationType.Master);
  }
  @computed get hasMasterProjectDbLocation() {
    return this.dbLocationsTypes.has(DbLocationType.MasterProject);
  }
  @computed get hasUserDbLocation() {
    return this.dbLocationsTypes.has(DbLocationType.User);
  }
  @computed get hasExternalUserDbLocation() {
    return this.dbLocationsTypes.has(DbLocationType.ExternalUser);
  }
  @computed get hasProjectDbLocation() {
    return this.dbLocationsTypes.has(DbLocationType.Project);
  }
  @computed get hasCustomDbLocation() {
    return this.dbLocationsTypes.has(DbLocationType.Custom);
  }

  // to allow access to user collection item, but doesn't work because gets overwritten by project level items
  shouldKeepUserItems = false;


  // FOR DEBUGGING ONLY, not intended to be true ever in prod. Also needs to disable cache to use
  shouldKeepMasterItems = false;

  // this one can be toggled on/off by user
  @observable shouldSearchUserItems = false;

  shouldSoftDelete = false;

  shouldHideUnnamedItems = false;

  shouldTrackCreationDate = false;
  shouldTrackModifDate = false;
  shouldTrackOwner = false;

  customDbLocation = '';

  // only set from searchable firebasestore not from every store like flags above
  protected hasMasterCache = false;

  sortField: string;
  sortDirection: ('asc' | 'desc') = 'asc';


  // DB filter
  // because we don't store fulll items in db, can't filter by a field that has a default value because it will be empty in DB
  advancedQueryFunction = (collection: Firebase10CollectionReference) => query(collection);

  // frontend filter (ONLY 1 field supported right now)
  queryFilters = new Map<string | FieldPath, any>();

  asyncRequestsCount = 0;
  startLoadTime = 0;

  dbLocationsOnLastReset = [];

  @observable shouldShowHiddenItems = false;
  @observable shouldMarkMasterItems = false;
  @observable shouldMarkReadonlyItems = false;

  unsubscribeAuthState = noop;
  unsubscribeSnapshots = [];
  @observable receivedSnapshotsCascadeOrders = new Set();

  protected pendingGetItems = new Map<ModelId, ((value?: ModelBase | PromiseLike<ModelBase>) => void)>();

  @observable _isReady: boolean = false;

  @computed get isReady(): boolean {
    return this.storeBaseInstance
      ? this.storeBaseInstance._isReady
      : this._isReady;
  };

  set isReady(value: boolean) {
    this._isReady = value;
  }

  @observable isLoading: boolean = false;

  canLoadItems = true;

  db = firestore();

  // storeBaseInstance can also have a storeBaseInstance
  @observable storeBaseInstance = null;

  constructor(stores: Stores, storeBaseInstance?: FirebaseStore<T>) {
    super(stores);

    if (storeBaseInstance) {
      this.storeBaseInstance = storeBaseInstance;

      this.canLoadItems = false;
    } else {
      defer(() => this.attemptLoadItems());
    }
  }

  @observable protected _firebaseUser: UserInfo;

  @computed get firebaseUser(): UserInfo {
    return this._firebaseUser;
  }

  set firebaseUser(value: UserInfo) {
    if (value) {
      value.getIdTokenResult().then(result => {
        const oldValue = this._firebaseUser;
        value.parentAccountEmail = result?.claims?.parentAccountEmail;

        this._firebaseUser = value;

        if (oldValue?.email !== value?.email) {
          this.attemptLoadItems();
        }
      });
    } else {
      this._firebaseUser = value;
    }
  }

  @computed get selectedProjectId(): ModelId {
    const { commonStore } = this.stores;
    return commonStore.selectedProjectId;
  }

  @computed get dbLocations(): string[] {
    // ensure correct loading order
    // might be better to load everything, the instanciate in a specific order
    if (
      this.hasLocalization && !i18n.language ||
      // can't wait for categories store, because it is instable state (ready when no project selected)
      // when project gets selected it will be in non-ready state at a timing impossible to predict
      //this.hasCategories && !this.stores?.categoriesStore?.isReady ||
      !this.userEmail ||
      this.hasProjectDbLocation && !this.isProjectDbOptional && !this.selectedProjectId
    ) {
      return [];
    }

    return compact([
      this.hasMasterDbLocation && `users/master/${this.storeKey}`,
      this.hasMasterProjectDbLocation && this.selectedProjectId && `users/master/projects/${this.selectedProjectId}/${this.storeKey}`,
      this.hasCustomDbLocation && this.customDbLocation,
      this.hasExternalUserDbLocation && `users/${this.nonImpersonatedEmail}/${this.storeKey}`,
      this.hasUserDbLocation && `users/${this.userEmail}/${this.storeKey}`,
      this.hasProjectDbLocation && this.selectedProjectId && `users/${this.userEmail}/projects/${this.selectedProjectId}/${this.storeKey}`,
    ]);
  }

  @computed get dbLocation(): string {
    return last(this.dbLocations);
  }

  @computed get collections(): CollectionReference[] {
    if (this.storeBaseInstance) {
      return this.storeBaseInstance.collections;
    }

    const db = firestore();

    return this.dbLocations.map(
      location => db.collection(location)
    );
  }

  // need to do on backend too
  @computed get nonMasterCollections() {
    return this.collections.filter(collection => !collection.path.startsWith('users/master') || this.userEmail === 'master');
  }

  @computed get masterCollection() {
    return this.collections.find(collection => collection.path.startsWith(`users/master/${this.storeKey}`));
  }

  @computed get masterProjectCollection() {
    return this.collections.find(collection => collection.path.startsWith(`users/master/projects/`));
  }

  @computed get projectCollection() {
    return (this.hasProjectDbLocation && this.selectedProjectId)
      ? last(this.collections)
      : null;
  }

  @computed get projectCollectionIndex() {
    return this.collections.findIndex(collection => collection === this.projectCollection);
  }

  getCollectionType = computedFn((collectionToFind: CollectionReference) => (
    this.sortedDbLocationsTypes[this.collections.findIndex(collection => collectionToFind === collection)]
  ))

  getCollectionByType = computedFn((dbLocationType: DbLocationType) => (
    this.collections[this.getCollectionIndexByType(dbLocationType)]
  ))

  getCollectionIndexByType = computedFn((dbLocationType: DbLocationType) => (
    this.sortedDbLocationsTypes.findIndex(type => type === dbLocationType)
  ))

  @computed get userCollection() {
    return this.hasUserDbLocation
      ? (this.hasProjectDbLocation && this.selectedProjectId ? this.collections[this.collections.length - 2] : last(this.collections))
      : null;
  }

  @computed get userCollectionIndex() {
    return this.collections.indexOf(this.userCollection);
  }

  // same as ?user= except we bring some data from the non impersonated user
  // instead ?user= that shows the target account as is.
  @computed get accountParam() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('account');
  }

  // the email used for DB
  @computed get userEmail(): string {
    // bad fix for stores that don't have time to load login info (ex: creating tasks list)
    if (this.stores !== Globals.defaultStores) {
      return Globals.defaultStores.userInfoStore.userEmail;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userParam = urlParams.get('user');

    // !!!! NEEDS TO BE DISABLED BY FIREBASE RULES for regular users !!!

    // currently used for external users only
    // have to rename ?user= 
    if (this.accountParam) {
      return this.accountParam;
    }

    if (userParam) {
      return userParam;
    }

    return this.firebaseUser?.parentAccountEmail || this.firebaseUser?.email || '';
  }

  // email used to login, not necessarily to determine the DB location
  @computed get nonImpersonatedEmail(): string {
    return this.firebaseUser?.email || '';
  }

  // only use to make some projects public to all users of organization but not external users
  @computed get organization(): string {
    return '@' + this.nonImpersonatedEmail.split('@')[1];
  }

  get collection(): CollectionReference {
    // highest priority
    return last(this.collections);
  }

  // needed because Walls have circular references and we don't save walls
  // to DB all the time as we are drawing them.
  // T2 because for example: creating a Wall (T2) from a Shape store
  public createItem<T2 extends T>(type: (new (stores: Stores) => T2), id?: ModelId): T2 { //might be a more elegant way?
    const item = new type(this.stores);
    if (id) {
      item.id = id;
    }
    return item;
  }

  // overrode when needed
  public addCachedMasterItems() { }


  public addCachedUserItems() {
    const { dbCacheStore } = this.stores;

    this.dbLocations.forEach((dbLocation, cascadeOrder) => {
      const data = {};
      Array.from(dbCacheStore.cache.keys()).forEach(cachedPath => {
        if (new RegExp(dbLocation + '/[^/]+$').exec(cachedPath)) {
          data[last(cachedPath.split('/'))] = dbCacheStore.cache.get(cachedPath);
        }
      });

      if (!isEmpty(data)) {
        this.applyCachedData(data, cascadeOrder);
      }
    });

  }

  reset() {
    if (this.isReady) {
      console.log('clearing ', this.storeKey, this.stores.name);
    }

    Array.from(this.itemsMap.values()).forEach(item => item.dispose?.());

    this.itemsMap.clear();
    this.isReady = false;

    this.unsubscribeSnapshots.forEach(unsubscribe => unsubscribe());
    this.unsubscribeSnapshots = [];
    this.receivedSnapshotsCascadeOrders = new Set();

    this.dbLocationsOnLastReset = this.dbLocations.slice(0);

    this.stores.commonStore.isBigUpdateOngoing = true;
  }

  //@action
  /*async */attemptLoadItems(shouldForce = false) {
    if (!this.firebaseUser) {
      this.unsubscribeAuthState();
      this.unsubscribeAuthState = auth().onAuthStateChanged(user => {
        this.onLoginOrLogout(user)
      });
      return;
    }

    if (
      !shouldForce && (
        this.isLoading ||
        !this.canLoadItems
      )
    ) {
      return;
    }

    if (// don't reload if nothing has changed since last load, hopefully correct condition
      (this.dbLocationsOnLastReset.join('') === this.dbLocations.join('')) && !isEmpty(this.dbLocationsOnLastReset)) {
      return;
    }

    this.reset();

    if (isEmpty(this.dbLocations)) {
      return;
    }

    this.startLoadTime = Date.now();
    console.log('start load', this.storeKey, this.stores.name, this.storeBaseInstance);

    this.isLoading = true;
    // console.log('firestore load start', this);

    this.addCachedMasterItems();

    // no gain to be made for now because we wait until store is done loading online data before letting user interact with app
    //this.addCachedUserItems();

    // load in reverse order so more important data will arrive first

    this.collections.slice(0).reverse().reduce(async (previousTask, collection, reverseIndex) => {
      await previousTask;

      const index = this.collections.length - reverseIndex - 1;

      if (this.hasMasterDbLocation && index === 0 && this.hasMasterCache) {
        // skipped cached master collection
        return;
      }

      // console.log('firestore load start collection', index, this);
      //console.log(collection.path);
      const firebase10Collection = firebaseCollection(firestore(), collection.path);
      let collectionQuery = query(firebase10Collection);

      //if (this.sortField) {
      //  query = query.orderBy(this.sortField, this.sortDirection);
      //}

      // won't work correctly when overriding master items, but should eventually find a better way
      // to apply to all stores
      //if (!this.hasMasterDbLocation && !this.hasMasterProjectDbLocation) {
      //  query = query.where('isDeleted', '==', false);
      //}

      if (this.queryFilters.size) {
        [...this.queryFilters.keys()].forEach(field => {
          collectionQuery = query(firebase10Collection, where(field, '==', this.queryFilters.get(field)));
        });
      } else {
        collectionQuery = this.advancedQueryFunction(firebase10Collection);
      }

      this.unsubscribeSnapshots.push(
        onSnapshot(collectionQuery,
          // { includeQueryMetadataChanges: true, includeDocumentMetadataChanges: true },
          snapshot => transaction(() => {
            // console.log('network complete', index, this, collection.path);
            snapshot.docChanges().forEach(change => {
              // ignore while offline, to avoid thinking a store is ready when it's not
              // and then trying to add default objects like rootNode and default report and settings
              if (!window.navigator.onLine) {
                debugger;
                return;
              }

              const id = change.doc.id;
              const itemData = change.doc.data();
              itemData.id = id;

              if (!itemData.type) {
                // change.doc.ref.delete(); // cleanup
              }

              this.onItemChangeFromDb(
                id,
                itemData as T,
                change.type,
                index
              );
            })

            this.receivedSnapshotsCascadeOrders.add(index);

            if (
              !this.shouldWaitOnAllCollectionsLoaded && index == this.collections.length - 1 ||
              this.shouldWaitOnAllCollectionsLoaded && this.receivedSnapshotsCascadeOrders.size === this.collections.length - (this.hasMasterCache ? 1 : 0)
            ) {
              this.onUpdateCompleted();
            }
          }),
          error => {
            console.error(
              'ERROR',
              error,
              collection.path,
              this.stores?.userInfoStore?.firebaseUser?.email,
              this.stores?.userInfoStore?.firebaseUser?.uid,
              this.stores?.userInfoStore?.firebaseUser?.auth?._currentUser?.accessToken?.substring(0, 10));
          }
        )
      );

      if (!this.shouldWaitOnAllCollectionsLoaded) {
        await sleep(200)
      }

      return '';
    }, Promise.resolve(''));
  }

  @action
  protected onLoginOrLogout(user: UserInfo) {
    this.isLoading = false;
    this.firebaseUser = user;
  }

  @action
  protected onUpdateCompleted() {
    if (!this.isReady) {
      this.onLoadCompleted();
    }
  }

  @action applyCachedData(data: any = {}, cascadeOrder = 0) {
    if (!isEmpty(data) && cascadeOrder === 0 && this.hasMasterDbLocation) {
      this.hasMasterCache = true;
    }

    // add object to store but don't index yet (indexing is slow)
    Object.keys(data).forEach(key => {
      const itemData = data[key];

      if (
        this.queryFilters.size === 1 &&
        itemData[[...this.queryFilters.keys()][0]] !== [...this.queryFilters.values()][0]
      ) {
        return;
      }

      this.onItemChangeFromDb(
        key,
        itemData as T,
        'added',
        cascadeOrder
      );
    });
    // console.log('cached data adding items done');
  }


  @action
  // make sure to call this as last step (super) when overriding in subclass
  public onLoadCompleted() {
    this.isLoading = false;
    this.isReady = true;

    if (this.stores.commonStore.isBigUpdateOngoing) {
      this.stores.commonStore.isBigUpdateOngoing = false;
    }
    console.log('end load ' + this.stores.name + ' ' + Math.round((Date.now() - this.startLoadTime) / 1000) + 's.', this.storeKey);
  }

  @action reorderItem(itemToReorder: T, itemPrecedingTarget: T, items = this.items, shouldSaveToDb = true, batchParam: WriteBatch = undefined) {
    // not the most efficient algorithm...
    itemToReorder.index = itemPrecedingTarget
      // we want to go just after the preceding item, but before the next one
      // the 0.00001 needs to be smaller than temporarily assigned in CategoriesCombobox
      ? itemPrecedingTarget.index + 0.00001
      // there might already be an item with index 0 and
      // we want to be before that item
      : -0.00001;

    items
      .sort(modelSortFunction)
      // set the index to integers
      .forEach((item, arrayIndex) => {
        item.index = arrayIndex;
      });

    if (shouldSaveToDb) {
      //disable while testing
      this.addEditItems(items, true, ['index', 'categoryId'], batchParam);
    }
  }

  updateItemsMap(item: T, itemId: ModelId, changeType: string, cascadeOrder: number) {
    const existingItem = this.itemsMap.get(itemId);
    const existingCascadeOrder = existingItem ? existingItem.cascadeOrder : -1;
    if (existingCascadeOrder > cascadeOrder) {
      return;
    }

    switch (changeType) {
      case 'added':
        if (!item) {
          debugger;
        }
        this.addEditItem(item, false);
        break;
      case 'modified':
        if (this.items.includes(item)) {
          this.addEditItem(item, false);
        }
        break;
      case 'removed':
        // this should only be called when a real db update happens (not by the current user)
        // try to get the item at other cascadeOrders if they exist.
        // ex. if we delete measurement at project level directly from db, but a project/master measurement exists;
        // we delete directly from itemsMap before refresh
        // we don't call this.deleteItem that will set isDeleted to true, and make it impossible to refresh
        // -- Problem when deleting drafts with lots of items, should be able to bypass this
        if (this.itemsMap.has(itemId)) {
          this.itemsMap.delete(itemId);
        }

        if (this.hasMasterDbLocation) {
          this.getItemAsync(itemId, true, true, cascadeOrder - 1);
        }

        break;
    }
  }

  ensureType(itemData) {
    if (itemData && !itemData.type) {
      // dirty fallback for type (shouldn't normally happen)
      itemData.type = this.storeKey.replace(/s$/, '');
      itemData.type = itemData.type.charAt(0).toUpperCase() + itemData.type.slice(1);
    }

    return itemData;
  }

  onItemChangeFromDb(itemId: ModelId, itemData: T, changeType: string, cascadeOrder: number) {
    itemData = this.ensureType(itemData);

    if (!this.collections[cascadeOrder]) {
      //console.log('onItemChange called without active collections');
      //debugger;
      return;
    }

    // skip receving master collection when logged in as master and use cached data to make sure to see
    // master project the same as users view it with cache
    if (this.userEmail === 'master' && !isEmpty(this.cachedMasterData) && cascadeOrder === this.userCollectionIndex) {
      return;
    }

    const serializedData = JSON.stringify(changeType == 'removed' ? null : itemData);

    if (!this.hasMasterCache || cascadeOrder) { // don't cache already cached masterdata
      this.stores.dbCacheStore.cache.set(
        this.collections[cascadeOrder].path + '/' + itemId,
        JSON.parse(serializedData)
      );
    }

    // partly sync and partly async
    // sync creates the item, async adds the references to avoid circular blocking
    itemData.cascadeOrder = cascadeOrder;

    // need to use itemsMap to avoid overwriting item that has isDeleted true at higher cascade order
    let existingItem = this.itemsMap.get(itemData.id);

    let newItem;
    let newItemFromReturnValue;

    const shouldUpdateUserCollectionMap = this.shouldKeepUserItems && this.userCollection && this.collections[cascadeOrder] === this.userCollection;

    // force separate object instances in useritems map vs regular map
    if (this.shouldKeepUserItems && cascadeOrder === this.projectCollectionIndex && this.userCollectionItemsMap.get(itemData.id) === this.itemsMap.get(itemData.id)) {
      existingItem = null;
    }

    // lots of ifs, but trying to avoid deserializing when possible, because expensive
    if (
      !existingItem ||
      existingItem.cascadeOrder <= itemData.cascadeOrder ||
      shouldUpdateUserCollectionMap ||
      this.shouldKeepMasterItems
    ) {
      // This line creates an item that might never be used, so we need to be careful
      // if anything were to reference that newItem before we confirm adding it to the store

      // when callback is called sync, the most up to date value will be inside the callback, not the one returned
      newItemFromReturnValue = DbItemFactory.create(itemData, this.stores, (err, result: T) => {

        if (!result) {
          debugger;
        }

        result.stores = this.stores;
        const item = existingItem || result as T;

        if (result.id !== itemId) {
          result.id = itemId;
        }

        if (this.shouldKeepMasterItems && cascadeOrder == 0) {
          this.masterCollectionItemsMap.set(result.id, result);
        }

        if (shouldUpdateUserCollectionMap) {
          if (this.userCollectionItemsMap.has(itemId) && changeType === 'removed') {
            this.userCollectionItemsMap.delete(itemId);
          } else {
            this.userCollectionItemsMap.set(result.id, result);
          }
          // for search index to update only
          this.updateItemsMap(result, result.id, changeType, cascadeOrder);
        }

        item.cascadeOrders.add(cascadeOrder);

        // special case when forcing to create a brand new item to be separate form user collection
        const userCollectionItem = this.userCollectionItemsMap.get(item.id);
        if (userCollectionItem) {
          userCollectionItem.cascadeOrders.forEach(itemCascadeOrder => {
            item.cascadeOrders.add(itemCascadeOrder);
          })
        }

        if (item?.cascadeOrder <= result?.cascadeOrder) {
          result.cascadeOrders = item.cascadeOrders;
          result.cascadeOrders.add(result.cascadeOrder);
          //result.serializedData = serializedData; // not used yet
          assignToExistingObject(item, result, this.shouldTrackCreationDate);
          this.updateItemsMap(item, item.id, changeType, cascadeOrder);
        } else {
          // works opposite of other properties, readonly & owner in lower cascade have precedence
          existingItem._isReadonly = !!itemData._isReadonly;
          existingItem.owner = itemData.owner || '';

          if (changeType == 'removed') {
            item.cascadeOrders.delete(result.cascadeOrder);
          } else {
            item.cascadeOrders.add(cascadeOrder);
          }
        }

        const pendingGetResolve = this.pendingGetItems.get(item.id);
        if (pendingGetResolve) {
          pendingGetResolve();
        }

        item.afterDeserialize();

        newItem = item;
      }) as T;
    } else if (existingItem) {
      // works opposite of other properties, readonly & owner in lower cascade have precedence
      existingItem._isReadonly = !!itemData._isReadonly;
      existingItem.owner = itemData.owner || '';

      existingItem.cascadeOrders.add(cascadeOrder);
    }

    newItem = newItem || newItemFromReturnValue;

    if (newItem && !existingItem) {
      this.updateItemsMap(newItem, itemId, changeType, cascadeOrder);
    }
  }

  @observable public _itemsMap: Map<ModelId, T> = new Map<ModelId, T>();

  @computed get itemsMap(): Map<ModelId, T> {
    return this.storeBaseInstance?.itemsMap || this._itemsMap;
  }
  set itemsMap(value) {
    if (!this.storeBaseInstance) {
      this.itemsMap = value;
    }
  }

  @observable public userCollectionItemsMap: Map<ModelId, T> = new Map<ModelId, T>();

  @observable public masterCollectionItemsMap: Map<ModelId, T> = new Map<ModelId, T>();

  @computed get items(): T[] {
    return this.itemsAndHiddenItems.filter(item => this.shouldShowHiddenItems || !item.isHidden);
  }

  @computed get itemsAndHiddenItems(): T[] {
    // doing sort locally, but could have option to do it in the db, but this
    // requires to create indexes if we combine it with other filtering (like deleted field)
    // WARNING: when moving to db, some sort still needs to happen locally (e.g. on updatedMiliseconds) 
    return this.sortField
      ? this.getItemsImpl().sort((a, b) => this.sortDirection == 'asc' ? lget(a, this.sortField) - lget(b, this.sortField) : lget(b, this.sortField) - lget(a, this.sortField))
      : this.getItemsImpl();
  }

  @computed get allItems(): T[] {
    return Array.from(this.itemsMap.values());
  }

  @computed get deletedItems(): T[] {
    return this.allItems.filter(i => i.isDeleted);
  }

  @computed get itemsIds(): ModelId[] {
    return this.items.map(item => item.id);
  }

  @observable selectedItems: Set<T> = new Set<T>();

  @computed get selectedItemsArray(): T[] {
    return Array.from(this.selectedItems.values());
  }

  @computed get selectedItemsDifferentFromUserCollection(): T[] {
    return this.shouldKeepUserItems
      ? (this.selectedItemsArray.filter(item => (
        areModelsDifferent(this.userCollectionItemsMap.get(item.id), item)
      )))
      : [];
  }

  getItemsBySubtype = computedFn((subtype: string) => (
    this.items.filter(
      item => (
        item?.id === BuiltinCategories.General || // very much hack, applies only to categories store
        !item?.subtype ||
        item?.subtype === subtype
      )) || []
  ))

  getItemsByName = computedFn((name: string) => (
    this.itemsAndHiddenItems.filter(item => item.name === name)
  ))

  getItemByName = computedFn((name: string) => (
    first(this.getItemsByName(name))
  ))

  // to allow overriding in child class
  getItemsImpl(): T[] {
    return Array.from(this.itemsMap.values())
      .filter(item => item && !item.isDeleted);
  }

  getItem(key: ModelId, ignoreReadyFlag = false) {
    if (!this.isReady && !ignoreReadyFlag) {
      // needs more testing before activating
      //return null;
    }
    const retval = this.itemsMap.get(key);
    return !retval?.isDeleted && retval;
  }

  // tries to retrieve the item from db if not loaded
  async getItemAsync(key: ModelId, allowWait = true, allowDbGet = false, maxCascadeOrder: number = this.collections.length - 1): Promise<T> {
    if (this.getItem(key)) {
      return this.getItem(key);
    }

    if ((!this.isReady || !allowDbGet) && allowWait) {
      return new Promise((resolve, reject) => {
        this.pendingGetItems.set(key, () => resolve(this.getItem(key)));
      });
    }

    const start = new Date().getTime();

    // UNUSED
    let doc: DocumentSnapshot;
    for (let i = maxCascadeOrder; i >= 0; i--) {
      if (!key || !this.collections[i]) {
        //debugger;
        return null;
      }
      const docRef = this.collections[i].doc(key);

      let cachedData = (i === 0 && this.hasMasterCache)
        ? this.cachedMasterData[docRef.id]
        : this.stores.dbCacheStore.cache.get(docRef.path);

      if (cachedData) {
        console.log('getitemasync from cache', cachedData, docRef.path, i);
        // this should only happen when deleting item
        // this isn't normal inside getter
        this.onItemChangeFromDb(key, cachedData, 'added', i);
      } else {
        // not cached
        this.asyncRequestsCount++;
        this.resetAsyncRequestsCount();

        // protects against infinite loops that send db requests
        // but then reseting to master won't work, once expired
        if (this.asyncRequestsCount > 1000) {
          console.error('Number of async requests exceeded, app is in unknown state');
          return;
        }

        console.log('getitemasync before SHOULD BE AVOIDED', this.storeKey, key);

        doc = await docRef.get();
        const itemData = doc.data() as T;
        if (itemData) {
          console.log('getitemasync after data', itemData);
          // this should only happen when deleting item
          // this isn't normal inside getter
          this.onItemChangeFromDb(key, itemData, 'added', i);
        } else {
          console.log('getitemasync no data', this.storeKey, key);
        }
      }
    }

    return this.getItem(key);
  }

  resetAsyncRequestsCount = debounce(() => {
    this.asyncRequestsCount = 0;
  }, 10000);


  @action batchAddEditItem(item: T, batchParam: WriteBatch) {
    this.addEditItem(item, true, null, batchParam);
  }

  @action batchAddEditItems(items: T[], batchParam: WriteBatch) {
    this.addEditItems(items, true, null, batchParam);
  }

  @action addEditItem(item: T, shouldSaveToDb = true, fieldsToUpdate: (keyof T)[] = null, batchParam: WriteBatch = null) {
    this.addEditItems([item], shouldSaveToDb, fieldsToUpdate, batchParam);
  }

  // debounced, doesn't allow to pass a batch inside, because commit should happen only once it has been debounced.
  addEditItemDebouncedInternalFunction = (item: T, shouldSaveToDb = true, fieldsToUpdate: (keyof T)[] = null, isUndoable = true) => {
    const batch = firestoreBatch(this.stores);
    batch.isUndoable = isUndoable;
    this.addEditItems([item], shouldSaveToDb, fieldsToUpdate, batch);
    return batch.commit();
  };

  // ensure debouncing is per item+project to avoid skipping a previous item change
  // should fix typing to show that it expects same parameters as function inside
  addEditItemDebounced = memoizeDebounce(
    this.addEditItemDebouncedInternalFunction,
    1000,
    { resolver: item => item.id + ' ' + this.stores?.commonStore?.selectedProjectId }
  );

  get hasPendingDebouncedCalls() {
    // very dependent on lodash internal data
    return [this.addEditItemDebounced, this.addEditItemLongDebounced].some(memoizedDebouncedFn => (
      Object.values(memoizedDebouncedFn.cache.__data__.string.__data__).some(debounced => {
        return debounced?.pending?.();
      })
    ));
  }

  flushDebouncedCalls = () => {
    try {
      [this.addEditItemDebounced, this.addEditItemLongDebounced].forEach(memoizedDebouncedFn => {
        // very dependent on lodash internal data
        Object.values(memoizedDebouncedFn.cache.__data__.string.__data__).forEach(debounced => {
          debounced.flush();
        });

        memoizedDebouncedFn.cache.clear();
      });
    } catch (e) {
      console.error('error flushing debounced', e);
    }
  }

  addEditItemLongDebounced = memoizeDebounce(
    this.addEditItemDebouncedInternalFunction,
    2500,
    { resolver: (item, shouldSaveToDb, fieldsToSave) => item.id + ' ' + fieldsToSave.join(' ') + ' ' + this.stores?.commonStore?.selectedProjectId }
  );

  @action addEditItems(items: T[], shouldSaveToDb = true, fieldsToUpdate: (keyof T)[] = null, batchParam: WriteBatch = null) {
    this.addEditItemsImpl(items, shouldSaveToDb, fieldsToUpdate, batchParam);
  }

  // to allow override
  addEditItemsImpl(items: T[], shouldSaveToDb = true, fieldsToUpdate: (keyof T)[] = null, batchParam: WriteBatch = null) {
    if (isEmpty(items)) {
      return;
    }

    items.forEach(item => {
      if (!item) {
        // semi normal if deleting item
        debugger;
        return;
      }

      // needed when copying from one project to another
      item.stores = this.stores;

      let existingItem;
      if (
        !this.shouldKeepUserItems ||
        // only assign to existing object if project collection object is separate from user collection object
        this.userCollectionItemsMap.get(item.id) !== this.itemsMap.get(item.id)
      ) {
        existingItem = this.getItem(item.id);
        assignToExistingObject(existingItem, item, this.shouldTrackCreationDate);
      }

      this.itemsMap.set(item.id, (existingItem || item) as T);
    });

    if (!shouldSaveToDb) {
      return;
    }

    this.saveToDb(compact(items), this.nonMasterCollections, fieldsToUpdate, batchParam);
  }

  compressSubItemsIfNeeded(plainItem: any) {
    // do nothing if not overrided
  }

  // always make sure a project has a copy of every item it needs,
  // so it won't be modified from inside another project
  @action
  ensureSavedInProjectCollection(items: T[], batchParam: WriteBatch = null) {
    if (!this.isReady || !this.projectCollection) {
      return;
    }

    const batch = batchParam || firestoreBatch(this.stores);

    const projectCollectionIndex = this.collections.indexOf(this.projectCollection);
    const itemsToSave = items.filter(item => !item.cascadeOrders.has(projectCollectionIndex));

    // could add something to prevent saving to user collection too
    this.addEditItemsImpl(itemsToSave, true, null, batchParam);

    if (!batchParam) {
      batch.commit();
    }
  }

  @action
  ensureSavedInUserCollection(items: T[], batchParam: WriteBatch = null) {
    if (!this.isReady || !this.projectCollection) {
      return;
    }

    const batch = batchParam || firestoreBatch(this.stores);

    const itemsToSave = items.filter(item => !item.cascadeOrders.has(this.userCollectionIndex));

    // could add something to prevent saving to user collection too
    this.addEditItemsImpl(itemsToSave, true, null, batchParam);

    if (!batchParam) {
      batch.commit();
    }
  }

  saveToDb(items: T[], collections = this.nonMasterCollections, fieldsToUpdate = [], batchParam: WriteBatch = null) {
    const batch = batchParam || firestoreBatch(this.stores);

    // should await
    if (this.hasPendingDebouncedCalls) {
      this.flushDebouncedCalls();
    }

    items.forEach(item => {
      // force saving whole object if not present yet in every collection
      if (item.cascadeOrders.size < this.collections.length || item.isDeleted) {
        fieldsToUpdate = [];
      }

      if (
        this.shouldTrackOwner &&
        isEmpty(fieldsToUpdate) &&
        (!this.hasMasterDbLocation || !item.cascadeOrders.has(0)) &&
        !item.owner &&
        !item.isReadonly
      ) {
        item.owner = this.nonImpersonatedEmail;
      }

      let plainItem = modelToPlainObject(item);

      if (item.isReadonly && item.serializedData?.includes?.('isReadonly:true')) {
        // revert item to original when readonly
        //  plainItem = JSON.parse(item.serializedData);
      }

      // dont save default values to DB (big change from before,
      // NEVER CHANGE default values of any existing object !!!!
      // in the future think of way to save default values by project version
      const defaultItem = modelToPlainObject(DbItemFactory.create({ type: plainItem.type }, this.stores) as ModelBase);
      Object.keys(plainItem)
        // anything that affects prices should be baked in the project even if it's the default value
        .filter(key => !['type', 'fees'].includes(key))
        .forEach(key => {
          if (
            JSON.stringify(plainItem[key]) === JSON.stringify(defaultItem[key]) ||
            JSON.stringify(plainItem[key]) === JSON.stringify({ en: [] }) ||
            JSON.stringify(plainItem[key]) === JSON.stringify({ undefined: "" })
          ) {
            if (!isEmpty(fieldsToUpdate)) {
              plainItem[key] = firestore.FieldValue.delete();
            } else {
              delete plainItem[key];
            }
          }
        });

      this.compressSubItemsIfNeeded(plainItem);

      // WARNING: Don't check for plainItem property values starting here, because default values will be replaced
      // by firebase special field delete function

      if (item.isDeleted && !this.shouldSoftDelete && !item.minUserVersion && !item.maxUserVersion) {
        // remove all fields except for isDeleted
        plainItem = pick(plainItem, ['id', 'type', 'isDeleted']);
      }

      // can remove this in a few weeks when everyone is on latest version
      // make new items appear deleted for old users
      if (item._isDeleted && item.minUserVersion) {
        plainItem.isDeleted = true;
      }

      // impersonating = stealth mode
      if (
        this.nonImpersonatedEmail !== 'louisp.tremblay@gmail.com' ||
        this.nonImpersonatedEmail == this.userEmail
      ) {
        plainItem.updatedMiliseconds = moment().valueOf(); // firebase.FieldValue.serverTimestamp()
      }

      plainItem = omit(plainItem, compact([
        // flags that are set locally only
        'cascadeOrder',
        'cascadeOrders',
        'serializedData',
        !this.shouldTrackCreationDate && 'createdMiliseconds',
        !this.shouldTrackModifDate && 'updatedMiliseconds',
        !this.shouldTrackOwner && 'owner',
      ]));

      // actual save to db
      collections
        .filter(collection => !this.readonlyDbLocationsTypes.has(this.getCollectionType(collection)))
        .map(collection => {
          const doc = collection.doc(item.id);

          // comment the first condition to not really delete from db (safer, but harder to debug)
          // we can't delete from db when using master -> user -> project cascade, because we can't delete master
          if (
            item.isDeleted &&
            !this.shouldSoftDelete && (
              !item.cascadeOrders.has(0) ||
              (!this.hasMasterDbLocation && !this.hasMasterProjectDbLocation)
            )) {
            batch.delete(doc);
            this.stores.dbCacheStore.cache.delete(doc.path);
          } else if (item.isDeleted && this.shouldSoftDelete) {
            batch.set(doc, plainItem);
          } else if (!isEmpty(fieldsToUpdate)) {
            plainItem = pick(plainItem, uniq(['updatedMiliseconds', 'createdMiliseconds', ...fieldsToUpdate]));
            batch.set(doc, plainItem, { merge: true });
          } else if (plainItem.type) {
            batch.set(doc, plainItem);
          } else {
            debugger;
            console.error('Trying to set a doc without full data')
          }

          if (item.isReadonly) {
            // if item is already saved to project collection the with same data, 
            // it won't trigger the db change event, have to manually trigger
            // this.onItemChangeFromDb(item.id, plainItem, 'added', this.collections.findIndex(collectionFromList => collectionFromList === collection));
          }

          if (item.metaCached) {
            const metaDoc = collection.parent.collection(this.storeKey + '_meta').doc(item.id);
            const plainMeta = modelToPlainObject(item.metaCached);
            // could implement fieldsToUpdate logic here
            batch.set(metaDoc, plainMeta);
          }
        });
    })


    if (!batchParam) {
      batch.commit();
    }
  }

  @action
  deleteItem(id: ModelId, shouldSaveToDb: boolean = true, batchParam: WriteBatch = null) {
    const batch = batchParam || firestoreBatch(this.stores);
    this.deleteItems([id], shouldSaveToDb, batch);

    if (!batchParam) {
      batch.commit();
    }
  }

  @action
  deleteItems(ids: ModelId[], shouldSaveToDb: boolean = true, batchParam: WriteBatch = null, topMostCascadeOrderOnly = false) {
    const batch = batchParam || firestoreBatch(this.stores);

    const items = compact(ids.map(id => this.getItem(id)));

    const deletionTime = (new Date()).getTime();
    items.forEach(item => {
      item.isDeleted = true;
      item.deletedMiliseconds = deletionTime;
      // these flags override isDeleted if set
      item.maxUserVersion = 0;
      item.minUserVersion = 0;
    });

    // do separately with ids, because we might not have the item still available
    /*ids.forEach(id => {
      this.itemsMap.delete(id);
    });*/

    if (shouldSaveToDb) {
      this.saveToDb(
        items,
        topMostCascadeOrderOnly ? [this.collection] : this.nonMasterCollections,
        undefined,
        batch
      );
    }

    if (!batchParam) {
      batch.commit();
    }
  }

  @action resuscitateMasterItems = async () => {
    return this.emptyTrash(null, false, true);
  }

  @action emptyTrash = async (batchParam?: WriteBatch, topMostCascadeOrderOnly = false, resetMasterItems = false) => {
    const { userInfoStore } = this.stores;
    const batch = batchParam || firestoreBatch(this.stores);

    const trashItems = this.allItems.filter(
      item => (
        (!item.maxUserVersion || userInfoStore.user.versionOnCreation <= item.maxUserVersion) &&
        (!item.minUserVersion || userInfoStore.user.versionOnCreation >= item.minUserVersion) &&
        (item.isDeleted || item.deletedMiliseconds) &&
        (!topMostCascadeOrderOnly || item.cascadeOrder === this.collections.length - 1) &&
        // dont really delete master items, we need to keep the local copy with deleted flag for item to stay deleted
        (resetMasterItems || !this.hasMasterDbLocation || !item.cascadeOrders.has(0))
      ));

    (topMostCascadeOrderOnly
      ? [this.collection]
      : this.nonMasterCollections
    ).forEach(collection => {
      trashItems.forEach(item => {
        // delete from map here so that we don't try to load item on another collection
        // (getitemasync after delete)
        this.itemsMap.delete(item.id);
        batch.delete(collection.doc(item.id));
      });
    });

    if (!batchParam) {
      await batch.commit();
    }
  }

  // this only works if store is loaded
  @action dropTable = (batchParam: WriteBatch) => {
    const batch = batchParam || firestoreBatch(this.stores);
    // deletes from highest cascade order only
    this.allItems
      .filter(item => item.cascadeOrder === this.collections.length - 1)
      .forEach(item => {
        this.itemsMap.delete(item.id);
        batch.delete(this.collection.doc(item.id));
      })

    if (!batchParam) {
      batch.commit();
    }
  }

  // without saving, meaning, for providing items that we reset, we don't save them back to the
  // project which is needed most time to make sure the project has a copy of all items
  // but it can work for Tasks Lists that we deleted by mistake
  // ex: globals.defaultStores.tasksListsStore.resetToMasterItemsWithoutSaving(globals.defaultStores.tasksListsStore.allItems)
  @action resetToMasterItemsWithoutSaving = async (items = this.itemsAndHiddenItems) => {
    let batch = firestoreBatch(this.stores);

    const itemsWithMasterData = items
      .filter(i => i.cascadeOrder !== 0 && i.cascadeOrders.has(0));

    this.nonMasterCollections.forEach(collection => {
      itemsWithMasterData.forEach(item => {
        batch.delete(collection.doc(item.id));
      });
    });

    await batch.commit();
  }

  // only works if master cached data!
  // to rescuscitate deleted items, use: (this.allItems) as parameter
  @action resetToMasterItems = async (items = this.itemsAndHiddenItems, collections = this.nonMasterCollections) => {
    const itemsWithMasterData = items
      .filter(existingItem => this.cachedMasterData[existingItem.id])
      .map(existingItem => {
        const newItem = DbItemFactory.create(this.cachedMasterData[existingItem.id], this.stores) as T;
        newItem.cascadeOrder = existingItem.cascadeOrder;
        newItem.cascadeOrders = new Set(existingItem.cascadeOrders);
        return newItem;
      });

    this.saveToDb(itemsWithMasterData, collections);
  }

  async resetToUserItems(items = this.itemsAndHiddenItems) {
    const userCollectionItemsToRestore = await Promise.all(items
      .map(async item => {
        const userItemCopy = this.userCollectionItemsMap.get(item.id).clone();
        const projectItem = this.getItem(userItemCopy.id);

        if (userItemCopy.meta && !userItemCopy.metaCached) {
          await userItemCopy.waitOnMetaReady();
        }

        // make sure not to affect cascadeOrders
        userItemCopy.cascadeOrder = projectItem.cascadeOrder;
        userItemCopy.cascadeOrders = new Set(projectItem.cascadeOrders);

        return userItemCopy;
      }));

    this.addEditItems(userCollectionItemsToRestore);
  }

  // all backend, but promise don't work if empty
  @action DEBUGDeleteAll = async (collectionIndex = undefined) => {
    return Promise.all(
      this.collections.map((collection, index) => {
        if (!isUndefined(collectionIndex) && collectionIndex !== index) {
          return Promise.resolve();
        }

        const batchSize = 256;
        const query = collection.orderBy('__name__').limit(batchSize);

        return new Promise((resolve, reject) => {
          this.deleteQueryBatch(query, resolve, reject);
        });
      })
    );
  }

  i18nObserver = observe(i18n, (change) => {
    if (this.isReady || change.name !== '_language') {
      return;
    }

    this.attemptLoadItems();
  });

  categoriesStoreObserver = this.stores.categoriesStore && observe(this.stores.categoriesStore, (change) => {
    if (!this.hasCategories || change.name !== 'isReady') {
      return;
    }

    this.attemptLoadItems();
  });

  // what happens if commonstore isnt defined yet, this won't ever get created
  selectedProjectObserver = this.stores.commonStore && observe(this.stores.commonStore, (change) => {
    if (!this.hasProjectDbLocation || change.name !== '_selectedProjectId') {
      return;
    }
    // always reload if project changes
    this.isLoading = false;

    if (
      !this.storeBaseInstance &&
      !this.stores.commonStore.selectedProjectId &&
      !this.isProjectDbOptional
    ) {
      this.reset();
    }

    this.attemptLoadItems();
  });
}
