import { Currency } from "constants/Currency";
import { DbLocationType } from "constants/DbLocationType";
import { PanelIds } from "constants/PanelIds";
import { UnitSystem } from "constants/UnitSystem";
import { first, isArray, isEmpty, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import Fee from 'models/Fee';
import Settings from "models/Settings";
import FirebaseStore from 'stores/FirebaseStore';
import { modelToPlainObject } from 'utils/DeserializeUtils';
import { WriteBatch, firestore } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from "utils/i18n";
const cssBreakpoints = require('../Constants.scss');

export default class SettingsStore extends FirebaseStore<Settings> {
  storeKey = 'settings';

  dbLocationsTypes = new Set([
    DbLocationType.User,
    //DbLocationType.MasterProject, // cant get it to work because the cascade priority should be different than taskslists
    DbLocationType.Project
  ]);

  // optional only when no project is loaded
  isProjectDbOptional = true;

  hasLocalization = false;
  hasCategories = false;

  shouldKeepUserItems = true;

  @computed get supportedLanguages(): LanguageKey[] {
    return ['en', 'fr', 'es'];
  }

  @computed get settings(): Settings {
    return first(this.items) || new Settings(this.stores);
  }

  // not per project, but doesnt really work, because gets overwritten right away
  @computed get userSettings(): Settings {
    return first(Array.from(this.userCollectionItemsMap.values())) || this.settings;
  }

  @computed get isImperial(): boolean {
    return this.settings.unitSystem === UnitSystem.Imperial;
  }

  @computed get language(): LanguageKey {
    return this.settings.language;
  }

  @computed get countryCode(): CountryCode {
    return this.settings.countryCode;
  }

  @computed get currency(): Currency {
    return this.settings.currency;
  }

  // e.g.: 
  //globals.defaultStores.settingsStore.DEBUG_applySettingsToAllProjects('shouldHideEvalumoReportFooter', true)
  DEBUG_applySettingsToAllProjects = async (key: keyof Settings, value: any) => {
    const { projectsStore, settingsStore, userInfoStore } = this.stores;

    if (!key || !value) {
      return;
    }

    const db = firestore();
    const batch = firestoreBatch(this.stores);

    const projectIds = projectsStore.items.map(i => i.id);

    await projectIds.reduce(async (previousTask, projectId) => {
      const projectsSettingsDocs = await (await db.collection(`/users/${userInfoStore.userEmail}/projects/${projectId}/settings`).get()).docs.map(doc => doc.id);

      projectsSettingsDocs.forEach(settingsId => {
        batch.set(db.doc(`/users/${userInfoStore.userEmail}/projects/${projectId}/settings/${settingsId}`), { [key]: value }, { merge: true });
      })

      return '';
    }, Promise.resolve(''));

    debugger;
    batch.commit();
  }

  // settings from browser
  @observable windowWidth = this.usableWidth;
  @observable windowHeight = this.usableHeight;

  @observable isTouchDevice = false;
  @computed get isVerticalOrientation() {
    return this.windowHeight > this.windowWidth;
  }

  @computed get isVerticalPhone() {
    return this.isVerticalOrientation && window.innerWidth < parseInt(cssBreakpoints.verticalPhoneMaxWidth)
  }

  addEditItemsImpl(items: Settings[], shouldSaveToDb = true, fieldsToUpdate = null, batchParam: WriteBatch = null) {
    const retval = super.addEditItemsImpl(items, shouldSaveToDb, fieldsToUpdate, batchParam);

    if (this.language !== i18n.language) {
      i18n.language = this.language;
    }

    if (this.countryCode !== i18n.countryCode) {
      i18n.countryCode = this.countryCode;
    }

    if (this.currency !== i18n.currency) {
      i18n.currency = this.currency;
    }

    return retval;
  }

  // todo make more generic and move to firebasestore
  /* override */ compressSubItemsIfNeeded(plainItem: any) {
    const defaultItem = modelToPlainObject(new Fee(this.stores));
    [
      ...(isArray(plainItem.fees) ? plainItem.fees : []),
      ...(isArray(plainItem.taxes) ? plainItem.taxes : [])
    ].forEach(fee => {
      Object.keys(fee).forEach(key => {
        if (key === 'percentage') {
          return;
        }
        if (JSON.stringify(fee[key]) === JSON.stringify(defaultItem[key])) {
          delete fee[key];
        }
      })
    });
  }

  public onLoadCompleted() {
    // double check that really root node isnt in db, can't afford to be a connection error
    // or we will overwrite whole project
    if (isEmpty(this.items)) {
      this.userCollection.doc('DEFAULT').get({ source: 'server' })
        .then(snapshot => {
          if (!snapshot.exists) {
            this.onLoadCompletedAndVerified();
          } else {
            debugger; // bad!
            window.location.reload();
          }
        }).catch(error => {
          if (navigator.onLine) {
            debugger;
            this.stores.commonStore.error = error.message;
            throw (error);
          }
        })
    } else {
      this.onLoadCompletedAndVerified();
    }
  }

  applyPhoneLayoutIfNeeded(settings: Settings): Settings {
    // also if on vertical phone, start with left row taking most of the space
    if (this.isVerticalPhone) {
      settings.panelSizes.set(PanelIds.LeftBar, 0.8);
      settings.panelSizes.set(PanelIds.MainColumn, 0.1);
      settings.panelSizes.set(PanelIds.RightBar, 0.1);
    }

    return settings;
  }

  public onLoadCompletedAndVerified() {
    super.onLoadCompleted();

    const batch = firestoreBatch(this.stores);
    batch.isUndoable = false;

    this.applyPhoneLayoutIfNeeded(this.settings);

    if (isEmpty(this.items)) {
      // create the project settings node if it doesn't exist
      const settings = new Settings(this.stores);
      this.applyPhoneLayoutIfNeeded(settings);
      settings.id = 'DEFAULT';

      this.addEditItem(settings, true, undefined, batch);

      // bad hardcoded cascade order! fix soon!
    } else if (this.settings.cascadeOrder === 0 /* user */ && this.stores.commonStore.selectedProjectId) {
      // ensure project level settings
      this.addEditItem(this.settings, true, undefined, batch);
    }

    // sometimes panel widths dont add up to 1 because of react reflex bugs
    ['panelSizes', 'tasksListPanelSizes', 'drawingPanelSizes'].forEach(panelSizesProperty => {
      [PanelIds.RightBar, PanelIds.LeftBarBottom].forEach(panelIdToCheck => {
        const interdependentPanels = [
          [PanelIds.LeftBar, PanelIds.MainColumn, PanelIds.RightBar],
          [PanelIds.LeftBarTop, PanelIds.LeftBarMiddle, PanelIds.LeftBarBottom],
        ];

        const otherPanelsToCheck = interdependentPanels
          .find(group => group.includes(panelIdToCheck))
          .filter(panelId => panelId !== panelIdToCheck);
        const otherPanelsTotalFlex = sumBy(otherPanelsToCheck, panelId => this.settings[panelSizesProperty].get(panelId));

        if (1 - otherPanelsTotalFlex - this.settings[panelSizesProperty].get(panelIdToCheck) > 0.1) {
          debugger;
          this.settings[panelSizesProperty].set(panelIdToCheck, 1 - otherPanelsTotalFlex);
        }
      });
    });

    batch.commit();

    i18n.language = this.language;
  }

  @computed get shouldUseLegacyFeesCalculation() {
    const { projectsStore, tasksStore } = this.stores;

    const { selectedProject } = projectsStore;
    const { settings } = this;

    return (
      selectedProject?.version <= 3 &&
      !settings.areSomeFeesFilteredByCategories &&
      !settings.areSomeFeesFilteredBySubtypes &&
      !tasksStore.items.some(task => task.providingItem?.isExemptFromAllFees)
    );
  }

  constructor(stores, storeBaseInstance) {
    super(stores, storeBaseInstance);
    // singleton for the lifetime of app, shouldn't have to remove listener
    window.addEventListener('resize', this.onResize);
    window.addEventListener('pointerdown', this.onPointerDown);
  }

  get usableWidth() {
    return Math.min(window.innerWidth, window.screen.availWidth);
  };

  get usableHeight() {
    return Math.min(window.innerHeight, window.screen.availHeight);
  };

  onResize = () => {
    this.windowWidth = this.usableWidth;
    this.windowHeight = this.usableHeight;
  }

  // not infaillible for people using laptops with touch screen
  onPointerDown = (e:PointerEvent) => {
    if (e.pointerType == "mouse" && this.isTouchDevice) {
      this.isTouchDevice = false;
    } else if (e.pointerType != "mouse" && !this.isTouchDevice) {
      this.isTouchDevice = true;
    }
  }
}
