import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { compact, isEmpty } from "lodash";
import Dialog from "models/Dialog";
import ModelBase from "models/ModelBase";
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import FirebaseStore from "stores/FirebaseStore";
import TreeNodesStore from 'stores/TreeNodesStore';
import firestoreBatch from "./FirestoreBatchUtil";
import i18n from "./i18n";
const colors = require('../Colors.scss');


export function deleteItem<T extends ModelBase>(item: T, store: FirebaseStore<T>) {
  store.deleteItem(item.id);
}

export function deleteItems<T extends ModelBase>(items: T[], store: FirebaseStore<T>) {
  store.deleteItems(items.map(item => item.id));
  store.selectedItems.clear();
}

export function askToDeleteItem<T extends ModelBase>(item: T, store: FirebaseStore<T>, onConfirmDelete = () => deleteItem(item, store)) {
  const { dialogsStore } = store.stores;

  const newDialog = new Dialog(store.stores);
  newDialog.dialogComponent = ({ open }) => (
    <ConfirmDialog
      open={open}
      dialogId={newDialog.id}
      onConfirm={() => {
        onConfirmDelete();
        dialogsStore.hideDialog(newDialog.id);
      }}
      title={i18n.t('Do you really want to delete this item?', { itemType: i18n.t(item.type).toLowerCase() })}
      content={item.name}
      actionButtonColor={colors.red}
      yesLabel={i18n.t('Delete')}
    />
  );
  dialogsStore.showDialog(newDialog);
};

export function askToDeleteItems<T extends ModelBase>(
  items: T[],
  store: FirebaseStore<T>,
  onConfirmDelete = () => deleteItems(items, store)
) {
  const { dialogsStore } = store.stores;

  const newDialog = new Dialog(store.stores);
  newDialog.dialogComponent = ({ open }) => (
    <ConfirmDialog
      open={open}
      dialogId={newDialog.id}
      onConfirm={() => {
        onConfirmDelete();
        dialogsStore.hideDialog(newDialog.id);
      }}
      title={i18n.t('Do you really want to delete these items?')}
      actionButtonColor={colors.red}
      yesLabel={i18n.t('Delete')}
    />
  );
  dialogsStore.showDialog(newDialog);
};

export function archiveItems<T extends ModelBase>(items: T[], store: FirebaseStore<T>) {
  items.forEach(item => item.isHidden = true);
  store.addEditItems(items, true, ['isHidden']);
  store.selectedItems.clear();
}

export function unarchiveItems<T extends ModelBase>(items: T[], store: FirebaseStore<T>) {
  items.forEach(item => item.isHidden = false);
  store.addEditItems(items, true, ['isHidden']);
  store.selectedItems.clear();
}

export function askToDeleteDrawing(drawingRootNode: TreeNode, treeNodesStore: TreeNodesStore) {
  const { stores } = treeNodesStore;
  const { dialogsStore, snapStore, drawToolsStore } = stores;
  const newDialog = new Dialog(stores);
  newDialog.dialogComponent = ({ open }) => (
    <ConfirmDialog
      open={open}
      dialogId={newDialog.id}
      onConfirm={() => {
        const batch = firestoreBatch(stores);
        drawingRootNode.satelliteImageUrl = '';
        drawingRootNode.satelliteImageAddress = '';
        drawingRootNode.backgroundImage = null;

        const nodesWithoutMeasurements = drawingRootNode.descendants.filter(d => isEmpty(compact(d.measurementValuesArray)));

        // duplicate with DeleteUtils
        if (isEmpty(nodesWithoutMeasurements)) {
          drawingRootNode.ownShapes = [];
          treeNodesStore.addEditItem(drawingRootNode, true, ['shapeId', 'ownShapesIds'], batch);
          treeNodesStore.deleteDescendants(drawingRootNode, true, batch);
        } else {
          nodesWithoutMeasurements.forEach(node => treeNodesStore.deleteNodeAndDescendants(node, true, batch));

          drawingRootNode.descendants
            .filter(descendant => !isEmpty(descendant.ownShapes))
            .forEach(descendant => {
              descendant.ownShapes = [];
              treeNodesStore.addEditItem(descendant, true, ['shapeId', 'ownShapesIds'], batch);
            })
        }

        batch.commit();
        drawToolsStore.reset();
        snapStore._snapPointsMap.clear();
      }}
      title={i18n.t('Do you really want to delete this {{- itemType}}?', { itemType: i18n.t('Reference Drawing') })}
      actionButtonColor={colors.red}
      yesLabel={i18n.t('Delete')}
    />
  );
  dialogsStore.showDialog(newDialog);
}
