import { Button, LinearProgress, Paper } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import * as classnames from 'classnames';
import ColorButton from 'components/common/ColorButton/ColorButton';
import { PdfIcon } from 'components/common/Icons';
import ObserverComponent from 'components/common/ObserverComponent';
import Panel from 'components/common/Panel/Panel';
import ReportClientInfo from 'components/common/ReportClientInfo/ReportClientInfo';
import ReportContentListOptions from 'components/common/ReportContentListOptions/ReportContentListOptions';
import ReportDate from 'components/common/ReportDate/ReportDate';
import ReportEditableSection from 'components/common/ReportEditableSection/ReportEditableSection';
import ReportFeesOptions from 'components/common/ReportFeesOptions/ReportFeesOptions';
import ReportHeader from 'components/common/ReportHeader/ReportHeader';
import ReportNumber from 'components/common/ReportNumber/ReportNumber';
import ReportProjectDescription from 'components/common/ReportProjectDescription/ReportProjectDescription';
import ReportTasksFilterComponent from 'components/common/ReportTasksFilterComponent/ReportTasksFilterComponent';
import ReportTaxesOptions from 'components/common/ReportTaxesOptions/ReportTaxesOptions';
import ReportTypeComponent from 'components/common/ReportTypeComponent/ReportTypeComponent';
import RichText2 from 'components/common/RichText2/RichText2';
import SettingsEditDialog from 'components/common/SettingsEditDialog/SettingsEditDialog';
import { Typography } from 'components/common/Typography/Typography';
import ReportContentList from 'components/reports/ReportContentList/ReportContentList';
import ReportNotes from 'components/reports/ReportNotes/ReportNotes';
import ReportPhotos from 'components/reports/ReportPhotos/ReportPhotos';
import ReportPreparedBy from 'components/reports/ReportPreparedBy/ReportPreparedBy';
import ReportThankYou from 'components/reports/ReportThankYou/ReportThankYou';
import ReportTotal from 'components/reports/ReportTotal/ReportTotal';
import { ReportSubtypes, ReportTasksVisibility, ReportTotalsVisibility } from 'constants/ReportOptionsConstants';
import { isEmpty } from 'lodash';
import Dialog from 'models/Dialog';
import UserColors from 'models/UserColors';
import * as React from 'react';
import { match } from 'react-router';
import sanitizeHtml from 'sanitize-html';
import { handleCheckboxChangeAndSave } from 'utils/FormUtils';
import i18n from 'utils/i18n';
import { isFilterAffectingTotal } from 'utils/ReportUtil';
/* tslint:enable */
import IntegerField from 'components/common/IntegerField/IntegerField';
import ReportNav from 'components/reports/ReportNav/ReportNav';
import { ReportTabs } from 'constants/ReportTabs';
import { InputNumberFormatInteger } from 'utils/NumberFormatter';
import { getSafe } from 'utils/Utils';
import './ReportView.scss';

/* tslint:disable */
const styles = require('./ReportView.module.scss');

interface IReportViewProps {
  match: match<{ [projectId: string]: string }>
}

class ReportView extends ObserverComponent<IReportViewProps> {
  page: HTMLElement;
  reportScrollContainer;

  editUserInfo = () => {
    const { dialogsStore, settingsStore, userInfoStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <SettingsEditDialog open={open} dialogId={newDialog.id} settings={settingsStore.settings} />
    )
    dialogsStore.showDialog(newDialog);
  }


  public _render() {
    const { projectsStore, reportsStore, treeNodesStore, userInfoStore, settingsStore } = this.context;

    const { selectedProject } = projectsStore;
    const { rootNode } = treeNodesStore;
    const { reportGenerationProgress, selectedTab, pdfUrl } = reportsStore;

    if (!selectedProject || !rootNode) {
      return null;
    }

    const report = reportsStore.selectedItem;

    if (!report) {
      return null;
    }

    const { shouldShowIntro, shouldShowTerms, shouldShowSignature, tasksFilter, shouldShowCustomHtmlInReportFooter, customHtmlInReportFooterMarginTop } = report;
    const { canManuallyChangeLogoColor, reportFontSizeScale, shouldBreakBeforeTerms, shouldNeverInlineNotesInReport } = userInfoStore.user || {};

    const nodesWithNotes = treeNodesStore.allNodes.filter(node => node.hasDescription || sanitizeHtml(node.description2, { allowedTags: [] }));
    const shouldShowNotes = report.shouldShowNotes && !isEmpty(nodesWithNotes);

    const nodesWithPhotos = treeNodesStore.allNodes.filter(node => (
      node.imageUrl &&
      // skip images inserted automatically after dragging tasks list
      !node.imageUrl.includes('%2Fmaster%2F') &&
      !node.imageUrl.includes('.svg') &&
      !node.shouldSkipImageInReport
    ));
    const shouldShowPhotos = report.shouldShowPhotos && !isEmpty(nodesWithPhotos);

    const logoColor = report.logoColors.color1;
    const logoColorPale = report.logoColors.colorPale;
    const logoColorText = report.logoColors.colorText;

    const hasNodesExcludedFromReports = rootNode.descendants.find(descendant => descendant.shouldExcludeFromReports);

    const { customHtmlInReportFooter } = userInfoStore.user || {}

    return (
      <div id="ReportView" className={styles.root}>

        <style dangerouslySetInnerHTML={{
          __html: `
                :root {
                  --logo-color: ${logoColor};
                  --logo-color-pale: ${logoColorPale};
                  --logo-color-text: ${logoColorText};
                  --report-font-size-scale: ${reportFontSizeScale || 1}; 
                }
              `}} />

        <Panel
          className={styles.reportPanel}
          header={<div className={styles.navContainer + ' hidden-phone'}><ReportNav /></div>}
          content={
            <div className={styles.report + ' ' + styles.scrollContainer}>
              <div className={styles.tabContent} id="report-scroll-container">
                <div className={classnames(
                  styles.reportPreparation, {
                  [styles.hiddenOutside]: selectedTab !== ReportTabs.DESIGN, // ??
                }
                )} >

                  {/* MOVE TO COMPONENT: Complete page that gets converted to PDF but is not visible before that */}
                  {(selectedTab === ReportTabs.PDF || reportsStore.isGeneratingExcel) && (
                    <Paper elevation={3} className={classnames(
                      'hiddenOutside',
                      'printablePage',
                      styles.page,
                      styles.printablePage,
                      styles.hiddenOutside,
                    )} >
                      <div
                        ref={page => reportsStore.printablePageRef = page}
                        className={styles.pageContent}
                      >
                        { /* <PageTemplate /> (added during print) */}

                        {/* TODO REMOVE DUPLCATION */}

                        <div className={styles.firstPageInfo}>
                          <div className={styles.columnOne}>
                            <div className={styles.reportType}>
                              <ReportTypeComponent report={report} />
                              {isFilterAffectingTotal(tasksFilter) && <ReportTasksFilterComponent report={report} />}
                            </div>
                            <ReportProjectDescription report={report} className={styles.reportProperties} />
                            <ReportClientInfo report={report} className={styles.reportProperties} />
                          </div>

                          <div className={styles.separator} />

                          <div className={styles.columnTwo}>
                            <ReportDate report={report} className={styles.reportProperties} />
                            {report.subtype === ReportSubtypes.Invoice && <ReportDate dateField='dueDate' report={report} className={styles.reportProperties} />}
                            <ReportNumber report={report} className={styles.reportProperties} />
                          </div>

                          {report.subtype === ReportSubtypes.Receipt && <div className={styles.paid}>{i18n.t('Paid')}</div>}
                        </div>

                        {shouldShowIntro && <RichText2 model={report} className={styles.introText} property="introText" />}

                        <ReportContentList report={report} />

                        <ReportPreparedBy report={report} />

                        {report.totalsVisibility !== ReportTotalsVisibility.None && <ReportTotal shouldPageBleed={!shouldShowNotes} />}

                        {shouldShowNotes && (shouldNeverInlineNotesInReport || !report.isGroupedByTreeNodeFirst || report.tasksVisibility === ReportTasksVisibility.Hidden) && <ReportNotes nodesWithNotes={nodesWithNotes} />}
                        {shouldShowPhotos && <ReportPhotos nodesWithPhotos={nodesWithPhotos} />}

                        {/*<ReportTotalInWords /> */}

                        <div className="keep-together" style={{ marginTop: '35px' }}>
                          {shouldBreakBeforeTerms && <div className="force-page-break" />}
                          {shouldShowTerms && <RichText2 model={report} property="generalTerms2" />}
                          {shouldShowSignature && <RichText2 model={report} property="signatureText" />}
                          <ReportThankYou />
                          {shouldShowCustomHtmlInReportFooter && customHtmlInReportFooter && (
                            <div className="FooterCustomHtml" style={{ marginTop: customHtmlInReportFooterMarginTop }} dangerouslySetInnerHTML={{ __html: userInfoStore.user?.customHtmlInReportFooter }}></div>
                          )}
                        </div>
                      </div>
                    </Paper>
                  )}

                  {/* end printable ---------- start editable */}

                  <ReportEditableSection
                    title={i18n.t('Header')}
                    isFirstSection
                    className={classnames(
                      styles.page,
                      styles.firstPage,
                      styles.header,
                      styles.isEditable,
                    )}
                    children={<>
                      <ReportHeader isEditable />
                      <div className={styles.firstPageInfo}>
                        <div className={styles.columnOne}>
                          <div className={styles.reportType}>
                            <ReportTypeComponent isEditable report={report} />
                          </div>
                          <ReportProjectDescription report={report} isEditable />
                          <ReportClientInfo report={report} isEditable />
                        </div>

                        <div className={styles.separator} />

                        <div className={styles.columnTwo}>
                          <ReportDate report={report} isEditable className={styles.reportProperties} />
                          {report.subtype === ReportSubtypes.Invoice && <ReportDate dateField='dueDate' report={report} isEditable className={styles.reportProperties} />}
                          <ReportNumber report={report} isEditable className={styles.reportProperties} />

                          <div style={{ flex: 1 }} />

                          {canManuallyChangeLogoColor && (
                            <ColorButton
                              className={styles.colorButton}
                              color={report.logoColors.color1}
                              onChange={color => {
                                const { rgb } = color;
                                const rbgString = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
                                report.logoColors = new UserColors(rbgString);
                                reportsStore.addEditItemDebounced(report, true, ['_logoColors']);
                              }}
                            />
                          )}

                        </div>

                        {report.subtype === ReportSubtypes.Receipt && <div className={styles.paid}>{i18n.t('Paid')}</div>}
                      </div>

                      <ReportPreparedBy report={report} isEditable className={styles.preparedByEditable} />
                    </>}
                  />

                  <ReportEditableSection
                    onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowIntro', reportsStore)}
                    isToggled={shouldShowIntro}
                    title={i18n.t('Introduction')}
                    className={styles.page}
                    children={<RichText2 model={report} property="introText" store={reportsStore} isEditable scrollingContainer="#report-scroll-container" />}
                  />

                  <ReportEditableSection
                    title={i18n.t('Content')}
                    className={classnames(styles.page, styles.reportContent)}
                    children={
                      <div>
                        <ReportContentListOptions report={report} />

                        {hasNodesExcludedFromReports && (
                          <div className={styles.hasNodesExcludedFromReports}><WarningIcon /> {i18n.t('Reminder: Some groups are currently excluded from this report.')}</div>
                        )}

                        <div className={styles.mobileScroller}>
                          <ReportContentList isSampleList report={report} />
                        </div>

                        {/* DEBUG */}
                        {/*<ReportTotal shouldPageBleed={!shouldShowNotes} />*/}
                      </div>
                    }
                  />

                  <ReportEditableSection
                    title={i18n.t('Fees')}
                    className={styles.page}
                    children={<ReportFeesOptions />}
                  />

                  <ReportEditableSection
                    title={i18n.t('Taxes')}
                    className={styles.page}
                    children={<ReportTaxesOptions />}
                  />


                  {!isEmpty(nodesWithNotes) && (
                    <ReportEditableSection
                      onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowNotes', reportsStore)}
                      isToggled={shouldShowNotes}
                      title={i18n.t('Notes')}
                      className={styles.page}
                    >
                      {(report.isGroupedByTreeNodeFirst && !shouldNeverInlineNotesInReport && report.tasksVisibility !== ReportTasksVisibility.Hidden)
                        ? (
                          <div>{i18n.t('Notes are now shown beside tasks in the main content when possible (when report is grouped by Location first).')}</div>
                        ) : (
                          <ReportNotes nodesWithNotes={nodesWithNotes} />
                        )}
                    </ReportEditableSection>
                  )}

                  {!isEmpty(nodesWithPhotos) && (
                    <ReportEditableSection
                      onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowPhotos', reportsStore)}
                      isToggled={shouldShowPhotos}
                      title={i18n.t('Photos')}
                      className={styles.page}
                    >
                      <ReportPhotos nodesWithPhotos={nodesWithPhotos} />
                    </ReportEditableSection>
                  )}

                  <ReportEditableSection
                    onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowTerms', reportsStore)}
                    isToggled={shouldShowTerms}
                    title={i18n.t('Terms & conditions')}
                    className={styles.page}
                    children={<RichText2 model={report} property="generalTerms2" store={reportsStore} isEditable scrollingContainer="#report-scroll-container" />}
                  />

                  <ReportEditableSection
                    onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowSignature', reportsStore)}
                    isToggled={shouldShowSignature}
                    title={i18n.t('Signature')}
                    className={styles.page}
                    children={<RichText2 model={report} canAddSignature property="signatureText" store={reportsStore} isEditable scrollingContainer="#report-scroll-container" />}
                  />

                  {customHtmlInReportFooter && (
                    <ReportEditableSection
                      onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowCustomHtmlInReportFooter', reportsStore)}
                      isToggled={shouldShowCustomHtmlInReportFooter}
                      title={i18n.t('Custom content')}
                      className={styles.page}
                      children={
                        <div>
                          <div>
                            <IntegerField
                              label={i18n.t('Spacing with previous section')}
                              className={styles.customHtmlMarginTopField}
                              value={customHtmlInReportFooterMarginTop}
                              onChange={event => {
                                try {
                                  report.customHtmlInReportFooterMarginTop = parseInt(event.target.value);
                                  reportsStore.addEditItemDebounced(report, true, ['customHtmlInReportFooterMarginTop']);
                                } catch (e) {
                                  report.customHtmlInReportFooterMarginTop = 0;
                                }
                              }}
                              InputProps={{ inputComponent: InputNumberFormatInteger }}
                              //disabled={hasVariables}
                              margin="none"
                            />
                          </div>
                          <div className="FooterCustomHtml" style={{ marginTop: customHtmlInReportFooterMarginTop }} dangerouslySetInnerHTML={{ __html: customHtmlInReportFooter }}></div>
                        </div>
                      }
                    />
                  )}

                  <div className={styles.bottomButton} >
                    <Button color="primary" onClick={() => reportsStore.onChangeSelectedTab(null, ReportTabs.PDF)} variant="contained">
                      <PdfIcon />
                      {i18n.t('View PDF')}
                    </Button>
                  </div>

                </div>

                <div className={styles.preview} hidden={selectedTab !== ReportTabs.PDF}>

                  {pdfUrl
                    ? (
                      settingsStore.isTouchDevice
                        ? (
                          <iframe
                            onLoad={(e) => {
                              try {
                                const { projectsStore, reportsStore } = this.context;

                                const projectName = getSafe(() => projectsStore.selectedProject.name) || '';
                                const filename = `${projectName} - ${i18n.t(reportsStore.report.subtype)}.pdf`;

                                e.target.contentWindow.PDFViewerApplication.setTitleUsingUrl(filename);
                              } catch (e) {
                                console.error(e);
                              }
                            }}
                            id="mobile-pdf-viewer-iframe"
                            // the underscore is to prevent app service worker from loading
                            src={window.location.origin + '/_mobile-pdf-viewer/pdfjs/web/viewer.html?file=' + pdfUrl + (settingsStore.isVerticalOrientation ? '#pagemode=none' : '')}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ) : (
                          <object
                            data={pdfUrl + '#toolbar=1&view=FitH&pagemode=thumbs'}
                            className={styles.pdf}
                          />
                        )
                    ) : (
                      <div className={styles.progressContainer}>
                        <Typography variant="subtitle2">{i18n.t('Preparing document')}</Typography>
                        <LinearProgress className={styles.progressBar} value={reportGenerationProgress} variant="determinate" />
                      </div>
                    )}
                </div>
              </div>
            </div>
          } />
      </div>
    );
  }
}

export default ReportView;