import * as classnames from 'classnames';
import ObserverComponent, { ObserverComponentProps } from 'components/common/ObserverComponent';
import DimensionLine from 'components/drawing/DimensionLine/DimensionLine';
import { computed } from 'mobx';
import Point from 'models/Point';
import TreeNode from 'models/TreeNode';
import Wall from 'models/Wall';
import * as React from 'react';

// don't use css modules here because SVG component is hard to rasterize with modules
require('./WallComponent.scss');

const DOUBLE_CLICK_THRESHOLD = 200;

interface IWallComponentProps {
  wall: Wall;
  readonly?: boolean,
  shouldShowDimensions?: boolean,
  forceObserve?: boolean,
  containerWidth?: number,
  containerHeight?: number,
};

export default class WallComponent extends ObserverComponent<IWallComponentProps> {
  componentDidMount(): void {
    const test1 = 0;
  }
  componentDidUpdate(prevProps: Readonly<IWallComponentProps & ObserverComponentProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const test = 0;
  }

  private queuedOnClicks: number/*NodeJS.Timeout*/[] = [];

  // The wall outline points themselves are calculated in Wall model
  // How to make it look more fancy is done in this Component
  private getOutlinePath(outlinePoints: Point[]): string {
    if (outlinePoints.length !== 4) {
      return '';
    }

    // only draw line on the outside of the wall, not the join
    return (
      'M' + outlinePoints[0] +
      'L' + outlinePoints[1] +
      'M' + outlinePoints[2] +
      'L' + outlinePoints[3]
    );
  }

  @computed get node(): TreeNode {
    const { treeNodesStore } = this.context;
    const { wall } = this.props;
    return wall.treeNode;
  }

  onClickDelayed = (event: React.MouseEvent<SVGPathElement>) => {
    event.stopPropagation();
    event.preventDefault();
    event.persist();
    this.queuedOnClicks.push(setTimeout(this.onClick, DOUBLE_CLICK_THRESHOLD, event));
  }

  // duplicate
  onClick = (event: React.MouseEvent<SVGPathElement>) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;

    this.queuedOnClicks.forEach(timer => (
      clearTimeout(timer)
    ));
    this.queuedOnClicks = [];

    drawToolsStore.dispatchEvent(new CustomEvent('click', { detail: { shape: this.props.wall, originalEvent: event } }));
  }

  // duplicate
  onDoubleClick = (event: React.MouseEvent<SVGPathElement>) => {
    const { drawToolsStore, treeNodesStore } = this.context;
    this.queuedOnClicks.forEach(timer => (
      clearTimeout(timer)
    ));
    this.queuedOnClicks = [];

    // check for listener with drawToolsStore.addEventListener...
    drawToolsStore.dispatchEvent(new CustomEvent('doubleclick', { detail: { shape: this.props.wall, originalEvent: event } }));
  }

  // renders a lot!!!!!!! careful when adding new code in render, test in a huge drawing
  public _render(): JSX.Element {
    const { drawToolsStore, settingsStore, routerStore } = this.context;
    const { isReportPage } = routerStore;
    const { wall, readonly, shouldShowDimensions, containerWidth, containerHeight, forceObserve } = this.props;

    let { isEditMode } = wall;

    const isLineBeingAdded = drawToolsStore.lineBeingAdded === wall;
    isEditMode = isEditMode && (!settingsStore.isTouchDevice || !drawToolsStore.isTouchQuickDrawMode)

    const legendColor = wall.legendColor;

    return (
      <g
        // not unique id
        id={wall.id}
        className={classnames(
          'WallComponent', {
          isShapeHidden: !(readonly || wall.isVisible),
          isHighlightedFromMeasurement: !readonly && drawToolsStore.shapesToHighlightFromHoveredMeasurement.has(wall),
        })}
        style={(!isReportPage && legendColor) ? { color: legendColor } : {}}
      >
        <g>
          <g>
            {shouldShowDimensions && (<path
              key="outline"
              onClick={readonly ? undefined : this.onClickDelayed}
              onDoubleClick={readonly ? undefined : this.onDoubleClick}
              className="outline"
              d={this.getOutlinePath(wall.outlinePoints)}
            />
            )}
            <path
              key="fill"
              onClick={readonly ? undefined : this.onClickDelayed}
              onDoubleClick={readonly ? undefined : this.onDoubleClick}
              className="fill"
              d={wall.fillPath}
            />
          </g>

          {wall.offsetPaths.map((path, index) => (
            <path
              key={`offset${index}`}
              className={'offsetPath' + ' ' + `offsetPath${index}`}
              onClick={readonly ? undefined : this.onClickDelayed}
              onDoubleClick={readonly ? undefined : this.onDoubleClick}
              d={path}
            />
          ))}

          {shouldShowDimensions && (isLineBeingAdded || !readonly || this.node.shapes.length < 50) && (
            <DimensionLine
              node={this.node}
              // hack: needs to listen to imperial, should be changed to not use the static getderived...
              isImperial={settingsStore.isImperial}
              language={settingsStore.language}
              //--
              isEditMode={isEditMode}
              lineToMeasure={wall}
              isLineBeingAdded={isLineBeingAdded}
              // ??
              forceVisible={!this.node || isLineBeingAdded}
              forceObserve={isEditMode}
              readonly={readonly}
              containerWidth={containerWidth} containerHeight={containerHeight}
            />
          )}
        </g>
      </g>
    );
  }
}
