import { Currency } from "constants/Currency";
import { PanelIds } from "constants/PanelIds";
import { RightBarTabs } from 'constants/RightBarTabs';
import { RoundingMethod } from "constants/RoundingMethod";
import { TreeNodePropertiesTabs } from 'constants/TreeNodePropertiesTabs';
import { UnitSystem } from "constants/UnitSystem";
import { isEmpty, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import { list, map, object, primitive, serializable } from "serializr";
import { getDefaultFees, getDefaultTaxes } from "utils/TaxesAndFeesUtil";
import Fee from "./Fee";
import ModelBase from "./ModelBase";
import Tax from "./Tax";

export enum SettingsType {
  Project, // applies to current project
  User, // applies to all projects
};

// Wont be able to use @localized decorator here, because we use this object
// to initialize i18n.. might have to recheck the SettingsStore hasLocalization

export default class Settings extends ModelBase {
  type = 'Settings';
  @serializable settingsType: SettingsType = SettingsType.Project;

  // default to French until English works better
  @serializable @observable language: LanguageKey = 'fr'; //detectBrowserLanguage().substr(0, 2);
  @serializable @observable unitSystem = UnitSystem.Imperial;
  @serializable @observable currency = Currency.CanadianDollar; // this will create problem with us dollar and US locale
  @serializable @observable countryCode: CountryCode = 'CA';

  // not observable to prevent glitch when saving then moving toolbar and gets reset after read from DB
  // also allows many users to move without moving other user
  @serializable(map(primitive())) panelSizes: Map<PanelIds, number> =
    new Map<PanelIds, number>([[PanelIds.LeftBar, 0.2],[PanelIds.MainColumn, 0.6], [PanelIds.RightBar, 0.2]]);
  @serializable(map(primitive())) tasksListPanelSizes: Map<PanelIds, number> =
    new Map<PanelIds, number>([[PanelIds.LeftBar, 0.2],[PanelIds.MainColumn, 0.6], [PanelIds.RightBar, 0.2]]);
  @serializable(map(primitive())) drawingPanelSizes: Map<PanelIds, number> =
    new Map<PanelIds, number>([[PanelIds.LeftBar, 0.2],[PanelIds.MainColumn, 0.8]]);

  @serializable(list(object(Fee))) @observable fees: Fee[] = getDefaultFees(this.stores);
  @serializable(list(object(Tax))) @observable taxes: Tax[] = getDefaultTaxes(this.stores);

  @serializable @observable areFeesRecursivelyCalculated = true;
  @serializable @observable areFeesIncludedInItemPrice = true;

  @observable shouldShowMeasurementsAdvancedMode = false;

  // to delete
  @serializable @observable canExportExcelPriceChanges = false;

  // force true for every body
  /*@serializable*/ @observable shouldAllowPausePriceUpdate = true;
  // should also allow pause price update if allowing clear price update
  @serializable @observable shouldAllowClearPriceUpdate = false;

  @serializable @observable shouldHideEvalumoReportFooter = false;

  // set to true soon
  @serializable @observable shouldShowReportHeaderMeasurements = false;

  /*@serializable */@observable selectedRightBarTab = RightBarTabs.LISTS;
  @serializable @observable selectedTreeNodePropertiesTab = TreeNodePropertiesTabs.DRAWING;

  @serializable @observable shouldRoundLabour = true;
  @serializable @observable shouldAllowWasteForLabour = false;

  @serializable @observable defaultRoundingMethod = RoundingMethod.RoundByProject;

  @computed get taxesPercentage(): number {
    return sumBy(this.taxes, tax => tax.percentage)
  }

  @computed get areSomeFeesFilteredByCategories() {
    return this.fees.some(fee => !isEmpty(fee.providingItemsCategoryIds) || !isEmpty(fee.excludedProvidingItemsCategoryIds));
  }

  @computed get areSomeFeesFilteredBySubtypes() {
    return this.fees.some(fee => !isEmpty(fee.providingItemsSubtypes));
  }
}