
import { Button, DialogActions, DialogContent, DialogTitle, Fade } from '@material-ui/core';
import { IDialogProps, IEditComponentProps } from 'constants/CommonProps';
import { isEmpty } from 'lodash';
import { computed } from 'mobx';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import FirebaseStore from 'stores/FirebaseStore';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import ObserverComponent from '../ObserverComponent';
;

const styles = require('./EditDialogComponent.module.scss');

function Transition(props) {
  // grow is nicer but introduces sizing bugs
  return <Fade {...props} timeout={{ enter: 500, exit: 200 }} />;
}

export interface IEditDialogComponentProps extends IDialogProps {
  // provide either models or modelCopies
  models?: ModelBase[],
  modelCopies?: ModelBase[],
  stores?: FirebaseStore<ModelBase>[],
  FormComponent: React.ComponentType<IEditComponentProps & any>,
  FormComponentProps: { [key: string]: any },
  onClose?: (shouldSave: boolean, modelCopies: ModelBase[]) => void,
  extraButtons?: JSX.Element | JSX.Element[],
  title?: string,
  saveButtonLabel?: string,
  fieldsToUpdate?: string[],
  shouldHideCancel?: boolean,
  className?: string,
  canModelsBeEmpty?: boolean
  shouldDisableUndo?: boolean,
}

interface IEditDialogComponentState {
  modelCopies: ModelBase[],
  isClosing: boolean,
}

export default class EditDialogComponent extends ObserverComponent<IEditDialogComponentProps, IEditDialogComponentState> {
  static defaultProps = {
    extraButtons: null,
    FormComponentProps: {},
    modelCopies: [],
    fieldsToUpdate: null,
    className: ''
  };

  state = {
    modelCopies: [],
    isClosing: false,
  }

  formComponentRef;

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { state, props } = this;
    const { isClosing } = this.state;

    let modelCopies = props.modelCopies;

    if (isEmpty(state.modelCopies) && isEmpty(props.modelCopies) && !isEmpty(props.models)) {
      modelCopies = props.models.map(model => !!model && model.clone())
    }

    // seem to trigger infinite loops often
    if (modelCopies.some((modelCopy, index) => modelCopy !== state.modelCopies[index])) {
      this.setState({ modelCopies });
    }

    if (!props.canModelsBeEmpty && isEmpty(props.modelCopies) && isEmpty(props.models) && !isClosing) {
      this.handleClose(false);
    }
  }

  handleClose = (shouldSave: boolean) => () => {
    const { dialogsStore } = this.context;
    const { stores, dialogId, onClose, fieldsToUpdate, shouldDisableUndo } = this.props;
    const { modelCopies } = this.state;

    this.formComponentRef?.beforeClose?.(shouldSave);

    if (onClose) {
      onClose(shouldSave, modelCopies);
    }

    if (shouldSave && modelCopies && !isEmpty(stores)) {
      const batch = firestoreBatch(this.context);
      if (shouldDisableUndo) {
        batch.isUndoable = false;
      }

      stores.forEach((store, index) => {
        if (!modelCopies[index]) {
          return;
        }
        store.addEditItem(modelCopies[index], true, fieldsToUpdate, batch);
      });
      batch.commit();
    }

    this.setState({ modelCopies: [], isClosing: true });
    dialogsStore.hideDialog(dialogId);
  }

  @computed get formComponent() {
    const { open, FormComponent, FormComponentProps, extraButtons, title, dialogId, saveButtonLabel, className, shouldHideCancel, canModelsBeEmpty } = this.props;
    const { modelCopies } = this.state;
    return <FormComponent ref={ref => this.formComponentRef = ref} models={modelCopies} {...FormComponentProps} handleClose={this.handleClose} dialogId={dialogId} />;
  }

  _render() {
    const { open, FormComponent, FormComponentProps, extraButtons, title, dialogId, saveButtonLabel, className, shouldHideCancel, canModelsBeEmpty } = this.props;
    const { modelCopies } = this.state;

    if (!canModelsBeEmpty && isEmpty(modelCopies)) {
      return null; // loading?
    }

    return (
      <DraggableDialog
        open={open && (canModelsBeEmpty || !isEmpty(modelCopies))}
        TransitionComponent={Transition}
        onClose={this.handleClose(false)}
        // would be nice to disable when user has made changes
        // but detecting changes is complicated
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={styles.root + ' ' + className}>
          {title
            ? (
              <>
                <DialogTitle id="draggable-dialog-title">
                  {title}
                </DialogTitle>
                <DialogContent>
                  {this.formComponent}
                </DialogContent>
              </>
            ) : this.formComponent
          }

          <DialogActions className={styles.dialogActions}>
            {extraButtons}

            {!shouldHideCancel && (
              <Button onClick={this.handleClose(false)} color="primary">
                {i18n.t('Cancel')}
              </Button>
            )}
            <Button onClick={this.handleClose(true)} color="primary" variant="contained">
              {saveButtonLabel || i18n.t('Save')}
            </Button>
          </DialogActions>
        </div>
      </DraggableDialog>
    );
  }
}

