
import { Button, FilledInput, FormControl, InputLabel, MenuItem, Portal, Select } from '@material-ui/core';
import FeetInchesField from 'components/common/FeetInchesField/FeetInchesField';
import ObserverComponent from 'components/common/ObserverComponent';
import TextField from 'components/common/TextField/TextField';
import { DRAWING_SCALE } from 'constants/Constants';
import { DrawToolType } from 'constants/DrawToolType';
import { Unit } from 'constants/Unit';
import { UnitSystem } from 'constants/UnitSystem';
import Globals from 'Globals';
import { uniqBy } from 'lodash';
import { computed } from 'mobx';
import Point from 'models/Point';
import * as React from 'react';
import i18n from 'utils/i18n';
import MeasurementConverter, { INCHES_PER_METER } from 'utils/MeasurementConverter';
import { InputNumberFormatMilimeter, InputNumberMetricScale } from 'utils/NumberFormatter';
import { getSafe } from 'utils/Utils';

const styles = require('./BackgroundImageScaleComponent.module.scss');

enum ScaleType {
  Page,
  Section,
}
interface BackgroundImageScaleComponentState {
  length: number,
  scaleType: ScaleType,
  manualScaleFactor: number,
}

export default class BackgroundImageScaleComponent extends ObserverComponent<{}, BackgroundImageScaleComponentState> {
  state = {
    length: 0,
    scaleType: ScaleType.Page,
    manualScaleFactor: 0,//48, // put to 0,
  };

  @computed get isManual() {
    const { shapesStore } = this.context;
    return shapesStore.backgroundImageScaleShape?.lengthPixels === 0;
  }

  ref;

  initialUnitSystem = UnitSystem.Imperial;

  componentDidMount(): void {
    if (this.isManual) {
      this.ref?.scrollIntoViewIfNeeded?.();
    }

    const { settingsStore } = this.context;
    this.initialUnitSystem = settingsStore.settings.unitSystem;
  }

  confirmScale = () => {
    const { shapesStore, backgroundImagesStore, settingsStore, drawToolsStore, treeNodesStore } = this.context;
    const { backgroundImage } = treeNodesStore.rootNode;
    const { length, scaleType, manualScaleFactor } = this.state;

    const metricLength = settingsStore.isImperial
      ? MeasurementConverter.convert(length, Unit.Foot, Unit.Meter)
      : MeasurementConverter.convert(length, Unit.Milimeter, Unit.Meter);

    const metricLengthWithCurrentScale = shapesStore.backgroundImageScaleShape.length;

    if (!manualScaleFactor && (!metricLength || !metricLengthWithCurrentScale)) {
      debugger;
      return;
    }

    const previousScale = backgroundImage.scale;
    const wasScalePreviouslySet = backgroundImage.isScaleSet;

    if (manualScaleFactor) {
      // newScale = 90px / m réels / (DPI/SCALEFACTOR)px /pouces réels * INCHES_PER_METER)
      backgroundImage.scale = DRAWING_SCALE / ((backgroundImage.DPI / manualScaleFactor) * INCHES_PER_METER);
    } else {
      backgroundImage.scale *= metricLength / metricLengthWithCurrentScale;
    }

    if (wasScalePreviouslySet && previousScale !== backgroundImage.scale) {
      // fix current drawing
      treeNodesStore.rootNode.shapes.forEach(shape => {
        shape.points.forEach(point => {
          point.x *= backgroundImage.scale / previousScale;
          point.y *= backgroundImage.scale / previousScale;
        });
      });

      shapesStore.addEditItems(treeNodesStore.rootNode.shapes);

      // fix other drawings in project with same background image
      const { treeNodesStore: projectTreeNodesStore, shapesStore: projectShapesStore } = Globals.defaultStores;
      const projectShapesWithSameBackgroundImage =
        uniqBy([projectTreeNodesStore.rootNode, ...projectTreeNodesStore.rootNode.descendants]
          .filter(n => n.drawingRoot?.backgroundImageId === backgroundImage?.id)
          .map(node => node.shapes)
          .flat(),
          'id'
        );

      projectShapesWithSameBackgroundImage.forEach(shape => {
        shape.points.forEach(point => {
          point.x *= backgroundImage.scale / previousScale;
          point.y *= backgroundImage.scale / previousScale;
        });
      });

      projectShapesStore.addEditItems(projectShapesWithSameBackgroundImage);
    }

    shapesStore.zoomController.zoomCompletelyOut();

    // should crop next
    if (scaleType === ScaleType.Section) {
      backgroundImage.isPageScale = false;
      drawToolsStore.selectedTool = DrawToolType.BackgroundImageCrop;
    } else {
      backgroundImage.isPageScale = true;
      drawToolsStore.selectedTool = DrawToolType.Select;
    }

    backgroundImagesStore.addEditItem(backgroundImage);

    settingsStore.settings.unitSystem = this.initialUnitSystem;
  }

  onLengthInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target as any;
    this.onChange(value);
  }

  onChange = (value: number) => {
    this.setState({ length: value });
  }

  handleChangeTab = (event, selectedTab) => {
    const { settingsStore } = this.context;
    settingsStore.settings.unitSystem = selectedTab;
  };

  _render() {
    const { shapesStore, settingsStore, treeNodesStore } = this.context;
    const { length, scaleType, manualScaleFactor } = this.state;
    const { rootNode } = treeNodesStore;
    const { backgroundImage } = rootNode;

    const shape = shapesStore.backgroundImageScaleShape;
    const shouldShowDimensions = shapesStore.shouldShowBackgroundImageScaleInput;

    const middlePt = new Point(
      (shape.startPt.x + shape.endPt.x) / 2,
      (shape.startPt.y + shape.endPt.y) / 2,
    );

    if (middlePt.x === 0 && middlePt.y === 0 && backgroundImage) {
      // bad rotation should be taken care of inside background image
      middlePt.x = ((backgroundImage.rotation / 90) % 2 ? backgroundImage.height : backgroundImage.width) / 2;
      middlePt.y = ((backgroundImage.rotation / 90) % 2 ? backgroundImage.width : backgroundImage.height) / 2;
    }

    // duplicate
    const shapeScale = getSafe(() => shapesStore.zoomController.observableScale || 1);

    return (
      <g className={styles.root} ref={ref => this.ref = ref}>
        <line
          x1={shape.startPt.x}
          y1={shape.startPt.y}
          x2={shape.endPt.x}
          y2={shape.endPt.y}
        />

        {shouldShowDimensions && (
          <Portal container={shapesStore.zoomController.secondObjectToZoom.querySelector('#fakeViewbox')}>
            <div className={'safariForeignContainer ' + styles.foreignContainer}
              style={{
                transform: `scale(${1 / shapeScale}) translate(${middlePt.x - 400 / 2}px, ${middlePt.y}px)`,
                transformOrigin: `${middlePt.x}px ${middlePt.y}px`,
                width: 400,
                //height: 460,
              }}>
              <div className={styles.firstRow}>
                {this.isManual ? (
                  settingsStore.isImperial
                    ? (
                      <FormControl className={styles.textField} margin="normal">
                        <InputLabel>{i18n.t('Scale')}</InputLabel>

                        <Select
                          value={manualScaleFactor}
                          onChange={({ target: { value } }) => { this.setState({ manualScaleFactor: value as number }) }}
                          input={<FilledInput />}
                        >
                          <MenuItem value={0}>{i18n.t('Choose Scale')}</MenuItem>
                          {[
                            [`1/16" = 1'-0"`, 192],
                            [`3/32" = 1'-0"`, 128],
                            [`1/8" = 1'-0"`, 96],
                            [`3/16" = 1'-0"`, 64],
                            [`1/4" = 1'-0"`, 48],
                            [`3/8" = 1'-0"`, 32],
                            [`1/2" = 1'-0"`, 24],
                            [`3/4" = 1'-0"`, 16],
                            [`1" = 1'-0"`, 12],
                            [`1 1/2" = 1'-0"`, 8],
                            [`3" = 1'-0"`, 4]
                          ].map(scaleParams => (
                            <MenuItem value={scaleParams[1]}>{scaleParams[0]}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        shouldFocusOnMount
                        label={i18n.t('Scale')}
                        value={manualScaleFactor || null}
                        className={styles.integerField}
                        InputProps={{
                          inputComponent: InputNumberMetricScale,
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            this.confirmScale();
                          }
                        }}
                        inputProps={{ enterkeyhint: 'done', inputmode: 'decimal' }}
                        onChange={({ target: { value } }) => { this.setState({ manualScaleFactor: value as number }) }}
                      />
                    )
                ) : (
                  settingsStore.isImperial
                    ? <FeetInchesField value={length} shouldFocusOnMount onChange={this.onChange} onConfirm={this.confirmScale} />
                    : (
                      <TextField
                        label={i18n.t('Length in mm.')}
                        onChange={this.onLengthInputChange}
                        className={styles.textField + ' ' + styles.textFieldNoLabel}
                        value={length}
                        shouldFocusOnMount
                        shouldStayFocused
                        shouldScrollIntoView
                        shouldStaySelectedAll
                        InputProps={{
                          inputComponent: InputNumberFormatMilimeter,
                        }}
                        inputProps={{ enterkeyhint: 'done', inputmode: 'decimal' }}
                      />
                    )
                )}

                <FormControl className={styles.unitSystem + ' ' + styles.textField} margin="normal">
                  <InputLabel>{i18n.t('Unit System')}</InputLabel>

                  <Select
                    value={settingsStore.settings.unitSystem}
                    onChange={({ target: { value } }) => {
                      settingsStore.settings.unitSystem = value as UnitSystem;
                      if (settingsStore.isImperial) {
                        this.setState({ manualScaleFactor: 0 })
                      }
                    }}
                    input={<FilledInput />}
                  >
                    <MenuItem value={UnitSystem.Imperial}>{i18n.t('Imperial')}</MenuItem>
                    <MenuItem value={UnitSystem.Metric}>{i18n.t('Metric')}</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <FormControl className={styles.textField} margin="normal">
                <InputLabel>{i18n.t('Scale Options')}</InputLabel>
                <Select
                  value={scaleType}
                  onChange={event => {
                    this.setState({ scaleType: event.target.value as ScaleType });
                  }}
                  input={<FilledInput />}
                >
                  <MenuItem value={ScaleType.Page}>{i18n.t('Apply scale to the whole page')}</MenuItem>
                  <MenuItem value={ScaleType.Section}>{i18n.t('Apply scale to a section of the page only')}</MenuItem>
                </Select>
              </FormControl>

              <Button variant="contained" color="primary" className={styles.actionButton} disabled={!length && !manualScaleFactor} onClick={this.confirmScale}>
                {i18n.t("Confirm Scale")}
              </Button>

              <Button onClick={() => {
                shapesStore.backgroundImageScaleShape = null;
                shapesStore.shouldShowBackgroundImageScaleInput = false;
              }}>
                {i18n.t("Restart Calibration")}
              </Button>
            </div>
          </Portal>
        )}
      </g>
    )
  }
}