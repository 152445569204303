import Fee from "models/Fee";
import Tax from "models/Tax";
import Stores from "stores/Stores";

export function getDefaultTaxes(stores: Stores): Tax[] {
  return [
    new Tax(stores, { en: 'GST', fr: 'TPS' }, 5),
    new Tax(stores, { en: 'QST', fr: 'TVQ' }, 9.975),
  ];
}

export function getDefaultFees(stores: Stores): Fee[] {
  const user = stores?.userInfoStore?.user || {};
  // bad because we manually changed the versionOnCreation on old users to allow them to see templates
  // so have to add extra check on creation date
  const isNewDefaultValue = user.versionOnCreation >= 4 && user.createdMiliseconds > new Date('2024-09-27').getTime();
  return [
    new Fee(stores, { en: 'General fees', fr: 'Frais généraux' }, isNewDefaultValue ? 10 : 0),
    new Fee(stores, { en: 'Administration fees', fr: 'Frais d\'administration' }, isNewDefaultValue ? 10 : 0),
    new Fee(stores, { en: 'Profit', fr: 'Profit' }, isNewDefaultValue ? 12 : 0),
  ];
}

