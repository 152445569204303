import { ClickAwayListener } from '@material-ui/core';
import classnames from 'classnames';
import { defer } from 'lodash';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./WorksheetsTreeNodeEditor.module.scss');

interface IWorksheetsTreeNodeProps {
  node: TreeNode;
}

export default class WorksheetsTreeNodeEditor extends ObserverComponent<IWorksheetsTreeNodeProps> {
  value: string = null;
  inputRef = null;

  componentDidMount() {
    defer(() => {
      this.inputRef?.focus?.();
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { treeNodesStore } = this.context;
    if (treeNodesStore.treeNodeBeingRenamed === null) {
      this.props.node.text = this.inputRef.value;
      treeNodesStore.addEditItem(this.props.node);
    }
  }

  resetValue() {
    this.inputRef.value = this.props.node.text;
  }

  onBlur = (event) => {
    const { treeNodesStore } = this.context;

    treeNodesStore.treeNodeBeingRenamed = null;
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { treeNodesStore } = this.context;

    //console.log(event.target, event.key);
    // todo, replace by real autocomplete that uses a dropdown like google

    if (event.metaKey || event.ctrlKey) {
      return;
    }

    const input = event.target as HTMLInputElement;

    // if typing capital letter other than 1st letter
    if (input.value.length && event.key.length == 1 && event.key.toLowerCase() !== event.key) {
      return;
    }

    const value = (
      // take into account current cursor selection, because the selection
      // gets overwritten by the keypress
      input.value.substr(0, input.selectionStart) +
      input.value.substr(input.selectionEnd, input.value.length) +
      event.key
    ).toLowerCase();

    let cancelEvent = false;

    switch (event.keyCode) {
      case 27: //escape
        this.resetValue();
      // nobreak
      case 13: //enter
        treeNodesStore.treeNodeBeingRenamed = null;
        cancelEvent = true;
        break;
      default:
        break;
    }

    switch (value) {
      case 'r':
      case 're':
      case 'rez':
        input.value = 'Rez-de-chaussée';
        cancelEvent = true;
        break;
      case '2':
      case '2e':
        input.value = '2e étage';
        cancelEvent = true;
        break;
      case 's':
      case 'so':
      case 'sou':
        input.value = 'Sous-sol';
        cancelEvent = true;
        break;
      case 'c':
      case 'cu':
      case 'cui':
        input.value = 'Cuisine';
        cancelEvent = true;
        break;
    }

    if (!cancelEvent) {
      // autocomplete round 2
    }

    if (cancelEvent) {
      input.selectionStart = value.length;
      input.selectionEnd = input.value.length;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  cancelEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  _render() {
    const { node } = this.props;
    if (!node) {
      return null;
    }
    const { text } = node;

    return (
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.onBlur}>
        <TextField
          className={classnames(styles.root, { [styles.isRootNode]: node.isRootNode })}
          type="text"
          inputRef={ref => this.inputRef = ref}
          defaultValue={text}
          onKeyDown={this.onKeyDown}
          shouldFocusOnMount
          shouldSelectAllOnFocus
          inputProps={{ enterkeyhint: 'done' }}
        // onMouseDownCapture={this.cancelEvent}
        //onMouseMoveCapture={this.cancelEvent}
        // onMouseUpCapture={this.cancelEvent}
        />
      </ClickAwayListener>
    )
  }
}