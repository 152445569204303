import { Button, Checkbox, IconButton } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import CheckedListIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CopyIcon from '@material-ui/icons/FileCopy';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import ReloadIcon from '@material-ui/icons/Refresh';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import ReplyIcon from '@material-ui/icons/Reply';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InvisibilityIcon from '@material-ui/icons/VisibilityOff';
import classnames from 'classnames';
import { CategoryType } from 'constants/CategoryType';
import { UnitType } from 'constants/UnitType';
import { compact, isEmpty } from 'lodash';
import { computed } from 'mobx';
import Dialog from 'models/Dialog';
import Measurement from 'models/Measurement';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { deleteItems } from 'utils/DeleteUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { duplicateMeasurements, getMeasurementsWithDependencies } from 'utils/MeasurementUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import CategoryMoveDialog from '../CategoryMoveDialog/CategoryMoveDialog';
import { ExplodeIcon, MeasuringTapeIcon2, MopIcon } from '../Icons';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';

const styles = require('./SelectedMeasurementsHeader.module.scss');

export default class SelectedMeasurementsHeader extends ObserverComponent {
  toggleMeasurements = (shouldActivate) => () => {
    const { measurementsStore, treeNodesStore } = this.context;
    let measurements = Array.from(measurementsStore.selectedItems.values());

    // if trying to clear all measurements, make double sure to clear hidden ones
    // because they sometimes don't get cleared up correctly
    const parentCheckboxState = this.isAllActiveMeasurementsChecked();
    if (!shouldActivate && parentCheckboxState.checked && !parentCheckboxState.indeterminate) {
      measurements = treeNodesStore.selectedTreeNode.measurementValuesArray
        .map(measurementValue => measurementValue.measurement)
        .filter(measurement => measurement && !measurement.isOneTimeUse);
    }

    treeNodesStore.toggleNodeMeasurements(
      measurements,
      shouldActivate,
    );

    measurementsStore.selectedItems.clear();
  }

  handleCheckAllMeasurements = (event, checked) => {
    const { measurementsStore } = this.context;
    measurementsStore.activeMeasurements.forEach(measurement => {
      // Need to find how to do this in a transaction without observing each add
      checked
        ? measurementsStore.selectedItems.add(measurement)
        : measurementsStore.selectedItems.delete(measurement);
    });
  }

  isAllActiveMeasurementsChecked = (
    activeMeasurements: Measurement[] = this.context.measurementsStore.activeMeasurements,
    selectedMeasurements: Set<Measurement> = this.context.measurementsStore.selectedItems,
  ): { checked: boolean, indeterminate: boolean } => {
    const checked = activeMeasurements.every(measurement => selectedMeasurements.has(measurement));
    const indeterminate = activeMeasurements.some(measurement => selectedMeasurements.has(measurement)) && !checked;

    return { checked: checked || indeterminate, indeterminate };
  }

  handleOpenChangeCategoryDialog = () => {
    const { measurementsStore, dialogsStore } = this.context;

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <CategoryMoveDialog
        open={open}
        dialogId={newDialog.id}
        models={measurementsStore.selectedItemsArray}
      />
    )
    dialogsStore.showDialog(newDialog);
  }

  removeZeroValueMeasurements = () => {
    const { measurementsStore, treeNodesStore } = this.context;

    treeNodesStore.toggleNodeMeasurements(
      this.zeroValueSelectedMeasurements,
      false,
    );

    measurementsStore.selectedItems.clear();
  }

  removeUnneededMeasurements = () => {
    const { measurementsStore, treeNodesStore } = this.context;

    treeNodesStore.removeUnneededMeasurements(
      treeNodesStore.selectedTreeNode,
      this.isAllActiveMeasurementsChecked() ? undefined : measurementsStore.selectedItemsArray,
    );

    measurementsStore.selectedItems.clear();
  }

  resetNonDefaultMeasurementValues = () => {
    const { measurementsStore, treeNodesStore } = this.context;

    treeNodesStore.resetNonDefaultMeasurementValues(
      treeNodesStore.selectedTreeNode,
      this.isAllActiveMeasurementsChecked() ? undefined : measurementsStore.selectedItemsArray,
    );

    measurementsStore.selectedItems.clear();
  }

  resetToUserItems = () => {
    const { measurementsStore } = this.context;

    measurementsStore.resetToUserItems(measurementsStore.selectedItemsDifferentFromUserCollection);
  }

  markAsMostRecent = () => {
    const { measurementsStore } = this.context;
    measurementsStore.addEditItems(measurementsStore.selectedItemsArray);
  }

  @computed get zeroValueSelectedMeasurements() {
    const { measurementsStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    return Array.from(measurementsStore.selectedItems.values()).filter(
      measurement => (
        selectedTreeNode.measurementValues.get(measurement.id)?.metricValue === 0 &&
        measurement.unitType !== UnitType.Angle
      )
    );
  }

  addSelectedItemsAsTasks = () => {
    const { measurementsStore, treeNodesStore, tasksStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    const items = measurementsStore.selectedItemsArray;

    const batch = firestoreBatch(this.context);

    const newTasksIds = items.map((item) => {
      const task = new Task(this.context);
      task.index = selectedTreeNode.tasks.length + measurementsStore.itemsByCategSubcategFlattened.findIndex(i => i.item?.id === item.id);
      task.measurementId = item.id;

      if (item.category.categoryTypes.includes(CategoryType.Task)) {
        task.category = item.category;
      }

      tasksStore.batchAddEditItem(task, batch);

      return task.id;
    });

    measurementsStore.ensureSavedInProjectCollection(getMeasurementsWithDependencies(items), batch);

    selectedTreeNode.ownTasksIds.push(...newTasksIds);
    treeNodesStore.batchAddEditItem(selectedTreeNode, batch);
    batch.commit();

    measurementsStore.selectedItems.clear();
  }


  // similar/ duplicate with worksheetstreenode explode drawings
  extractDrawingsWithMeasurement = () => {
    const { measurementsStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    const batch = firestoreBatch(this.context);

    const newParentNode = new TreeNode(this.context, i18n.t('Extracted drawings'));
    newParentNode.isExpanded = true;
    newParentNode.shouldBubbleMeasurements = false;

    measurementsStore.selectedItemsArray.forEach(measurement => {
      const nodesWithMeasurements = selectedTreeNode.measurementValues.get(measurement.id)?.bubbleDescendantsWithSameMeasurement
        .filter(node => !isEmpty(node.shapes));

      if (isEmpty(nodesWithMeasurements)) {
        return;
      }

      // todo toggle off measurements (maybe)
      const nodesCopies = nodesWithMeasurements.map(node => node.cloneDeep(batch));

      const groupedNodesCopies = []

      nodesCopies.forEach(node => {
        const nodeWithSameName = groupedNodesCopies.find(groupedNode => groupedNode.name === node.name);

        if (nodeWithSameName) {
          nodeWithSameName.childrenIds.push(...node.childrenIds);
          treeNodesStore.deleteItem(node.id, true, batch);
        } else {
          groupedNodesCopies.push(node);
        }
      })

      groupedNodesCopies.forEach(node => {
        const newChildNode = new TreeNode(this.context);
        newChildNode.name = node.name;
        newChildNode.shouldBubbleMeasurements = false;

        const newDrawingNode = !isEmpty(node.ownShapes)
          ? new TreeNode(this.context)
          : node;
        
        newDrawingNode.name = node.name;
        newDrawingNode.shouldBubbleMeasurements = true;
        newDrawingNode.isDrawingNode = true;
        newDrawingNode.backgroundImageId = selectedTreeNode.childDrawingNode.backgroundImageId;
        newDrawingNode.backgroundImageScale = selectedTreeNode.childDrawingNode.backgroundImageScale;

        if (newDrawingNode !== node) {
          newDrawingNode.childrenIds = [node.id];
        }

        newChildNode.childrenIds = [newDrawingNode.id];
        newParentNode.childrenIds.push(newChildNode.id);

        treeNodesStore.batchAddEditItem(newChildNode, batch);
        treeNodesStore.batchAddEditItem(newDrawingNode, batch);
      });

      treeNodesStore.addEditItems(groupedNodesCopies, true, undefined, batch);

      treeNodesStore.appendNode(newParentNode, selectedTreeNode.parent, selectedTreeNode.parent.childrenIds.findIndex(nodeId => nodeId === selectedTreeNode.id) + 1, batch);
    });

    treeNodesStore.batchAddEditItem(newParentNode, batch);
    batch.commit();

    if (!isEmpty(newParentNode.children)) {
      treeNodesStore.selectedTreeNode = newParentNode.children[0];
    }
  }

  toggleMeasurementsVisibility = () => {
    const { measurementsStore } = this.context;

    measurementsStore.selectedItemsArray.forEach(measurement => {
      measurement.isHidden = !measurement.isHidden;
    })

    measurementsStore.addEditItems(measurementsStore.selectedItemsArray, true, ['isHidden']);
    measurementsStore.selectedItems.clear();
  }

  toggleMeasurementsReadonly = () => {
    const { measurementsStore } = this.context;

    measurementsStore.selectedItemsArray.forEach(measurement => {
      measurement.isReadonly = !measurement.isReadonly;
    })

    measurementsStore.addEditItems(measurementsStore.selectedItemsArray, true, ['_isReadonly']);
    measurementsStore.selectedItems.clear();

    measurementsStore.shouldMarkReadonlyItems = true;
  }

  _render() {
    const { measurementsStore, treeNodesStore } = this.context;
    const selectedMeasurementsSize = measurementsStore.selectedItems.size;
    const { activeMeasurements } = measurementsStore;
    const { selectedTreeNode } = treeNodesStore;

    const shouldActivate = getSafe(() =>
      measurementsStore.inactiveMeasurements.includes(
        Array.from(measurementsStore.selectedItems.values())[0]
      ));

    const hasReadonlyMeasurements = measurementsStore.selectedItemsArray.some(m => m.isReadonly);

    return (
      <div className={classnames(styles.root, { [styles.hasMeasurements]: !isEmpty(activeMeasurements), [styles.hasSelection]: measurementsStore.selectedItems.size })}>
        {!isEmpty(activeMeasurements) && <Checkbox
          className={styles.itemToggle}
          // duplicate
          // weird material UI feature that indeterminate needs to also be checked
          {...this.isAllActiveMeasurementsChecked()}
          onChange={this.handleCheckAllMeasurements}
        />}
        <div className={styles.title}>
          <MeasuringTapeIcon2 />
          <div>{i18n.t('Measurements')}</div>
        </div>

        {selectedTreeNode && (
          <div className={styles.buttons}>
            {(selectedMeasurementsSize > 0 && !shouldActivate)
              ? (
                <>
                  <Button className={styles.cancelButton} onClick={() => measurementsStore.selectedItems.clear()}>
                    {i18n.t('Cancel')}
                  </Button>

                  <Button onClick={this.toggleMeasurements(shouldActivate)} className={styles.button}>
                    <RemoveIcon />&nbsp;
                    {i18n.t('Remove {{number}} measurement(s)', { number: selectedMeasurementsSize })}
                  </Button>
                  <MenuPopupButton
                    className={styles.moreButton}
                    menuItems={compact([
                      measurementsStore.selectedItemsArray.length <= 80 && {
                        icon: <CopyIcon />, text: i18n.t('Duplicate'), handler: () => {
                          const itemCopies = duplicateMeasurements(measurementsStore.selectedItemsArray, this.context)();
                          measurementsStore.selectedItems = new Set(itemCopies);
                        }
                      },
                      !hasReadonlyMeasurements && { icon: <CategoryIcon />, text: i18n.t('Move to a different category'), handler: this.handleOpenChangeCategoryDialog },
                      { icon: <ReplyIcon style={{ transform: 'scaleX(-1)' }} />, text: i18n.t('Add as tasks'), handler: this.addSelectedItemsAsTasks },
                      // limited to selecting one, but coded to work with more
                      !selectedTreeNode.isRootNode && measurementsStore.selectedItemsArray.length === 1 && measurementsStore.selectedItemsArray.some(measurement => !isEmpty(selectedTreeNode.measurementValues.get(measurement.id)?.bubbleDescendantsWithSameMeasurement)) && { icon: <ExplodeIcon />, text: i18n.t('Extract drawings with this measurement'), handler: this.extractDrawingsWithMeasurement },
                      !isEmpty(this.zeroValueSelectedMeasurements) && { icon: <RemoveIcon />, text: i18n.t('Remove measurements with a 0 value'), handler: this.removeZeroValueMeasurements },
                      !isEmpty(selectedTreeNode.unneededMeasurements) && { icon: <MopIcon />, text: i18n.t('Remove unneeded measurements'), handler: this.removeUnneededMeasurements },
                      !isEmpty(selectedTreeNode.nonDefaultMeasurementValues) && { icon: <ReloadIcon />, text: i18n.t('Reset measurements to default values'), handler: this.resetNonDefaultMeasurementValues },
                      !isEmpty(measurementsStore.selectedItemsDifferentFromUserCollection) && { icon: <ReloadIcon />, text: i18n.t('Reset measurements to latest changes'), handler: this.resetToUserItems },
                      !hasReadonlyMeasurements && !isEmpty(measurementsStore.selectedItemsDifferentFromUserCollection) && { icon: <ThumbUpIcon />, text: i18n.t('Mark measurements as latest for use in other projects'), handler: this.markAsMostRecent },
                      !hasReadonlyMeasurements && measurementsStore.selectedItemsArray.every(measurement => measurement.isHidden === measurementsStore.selectedItemsArray[0].isHidden) && {
                        icon: measurementsStore.selectedItemsArray[0].isHidden ? <VisibilityIcon /> : <InvisibilityIcon />,
                        text: measurementsStore.selectedItemsArray[0].isHidden ? i18n.t('Unhide measurements') : i18n.t('Hide measurements'),
                        handler: this.toggleMeasurementsVisibility
                      },
                      measurementsStore.selectedItemsArray.every(measurement => measurement.canChangeReadonly && measurement.isReadonly === measurementsStore.selectedItemsArray[0].isReadonly) && {
                        icon: measurementsStore.selectedItemsArray[0].isReadonly ? <UnlockIcon /> : <LockIcon />,
                        text: measurementsStore.selectedItemsArray[0].isReadonly ? i18n.t('Allow changing these measurements') : i18n.t('Lock all changes on these measurements'),
                        handler: this.toggleMeasurementsReadonly,
                        //danger: measurementsStore.selectedItemsArray[0].isReadonly,
                        //dangerText: i18n.t('Really unlock these measurements?')
                      },
                      !hasReadonlyMeasurements && { icon: <DeleteForeverIcon />, danger: true, text: i18n.t('Delete forever'), handler: () => deleteItems(measurementsStore.selectedItemsArray, measurementsStore) },
                    ])} />
                </>
              ) : (
                <IconButton className={styles.button} onClick={() => {
                  const { measurementsStore } = this.context;
                  //measurementsStore.toolbarSelectedCategory = null;
                  measurementsStore.isCategoriesToolbarOpen = !measurementsStore.isCategoriesToolbarOpen;
                }}>
                  <CheckedListIcon />
                </IconButton>
              )}
          </div>
        )}
      </div>
    )
  }
}