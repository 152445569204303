// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { IDialogProps } from 'constants/CommonProps';
import { UnitType } from 'constants/UnitType';
import { omit } from 'lodash';
import { observable } from 'mobx';
import ModelBase from 'models/ModelBase';
import ProvidingItem from 'models/ProvidingItem';
import Task from 'models/Task';
import * as React from 'react';
import DbItemFactory from 'utils/DbItemFactory';
import { modelToPlainObject } from 'utils/DeserializeUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { round } from 'utils/Utils';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import ObserverComponent from '../ObserverComponent';
import ProvidingItemDetailsForm from '../ProvidingItemDetailsForm/ProvidingItemDetailsForm';

interface IProvidingItemEditDialog extends IDialogProps {
  providingItem: ProvidingItem,
  task?: Task,
}

interface IProvidingItemEditDialogState {
  modelCopies: ModelBase[]
}

export default class ProvidingItemEditDialog extends ObserverComponent<IProvidingItemEditDialog, IProvidingItemEditDialogState> {
  handleFactoryReset = async () => {
    const { providingItemsStore, dialogsStore, userInfoStore } = this.context;
    const { providingItem, dialogId, task } = this.props;

    const { nonImpersonatedUser } = userInfoStore;

    providingItemsStore.resetToMasterItems(
      [providingItem],
      nonImpersonatedUser.shouldAutoSyncProvidingItems
        ? undefined
        : (task ? [providingItemsStore.projectCollection] : [providingItemsStore.userCollection])
    );

    // would be better to reload item, but not sure people will use this function much right now
    dialogsStore.hideDialog(dialogId);
  }

  handleUserItemReset = () => {
    const { providingItemsStore, dialogsStore } = this.context;
    const { providingItem, dialogId } = this.props;

    providingItemsStore.resetToUserItems([providingItem]);


    // would be better to reload item, but not sure people will use this function much right now
    dialogsStore.hideDialog(dialogId);
  }

  handleUserItemPush = async () => {
    const { providingItemsStore, dialogsStore } = this.context;
    const { providingItem, dialogId } = this.props;

    await providingItem.waitOnMetaReady();
    providingItemsStore.addEditItem(providingItem);

    // would be better to reload item, but not sure people will use this function much right now
    dialogsStore.hideDialog(dialogId);
  }

  @observable masterCollectionItem: ProvidingItem /* plain object */ = null;

  componentDidMount(): void {
    const { providingItemsStore } = this.context;
    const masterCollectionProvidingItemObject = providingItemsStore.cachedMasterData[this.props.providingItem.id]
    this.masterCollectionItem = DbItemFactory.create(masterCollectionProvidingItemObject, this.context) as ProvidingItem;
  }

  _render() {
    const { providingItemsStore, tasksStore, userInfoStore } = this.context;
    const { open, dialogId, providingItem, task } = this.props;
    const { user, nonImpersonatedUser } = userInfoStore;
    const userCollectionProvidingItem = providingItemsStore.userCollectionItemsMap.get(providingItem.id);
    const masterCollectionProvidingItemObject = providingItemsStore.cachedMasterData[providingItem.id];
    const fieldsToIgnoreInModelComparisons = ['cascadeOrder', 'cascadeOrders', 'updatedMiliseconds', 'createdMiliseconds'];

    const isProjectItemDifferentFromUser =
      userCollectionProvidingItem && (
        JSON.stringify(omit(modelToPlainObject(userCollectionProvidingItem), fieldsToIgnoreInModelComparisons)) !==
        JSON.stringify(omit(modelToPlainObject(providingItem), fieldsToIgnoreInModelComparisons))
      );

    return (
      <EditDialogComponent
        dialogId={dialogId}
        open={open}
        models={[providingItem]}
        onClose={(shouldSave, itemCopies) => {
          const editedProvidingItem = itemCopies[0] as ProvidingItem;
          if (shouldSave) {
            const batch = firestoreBatch(this.context);
            // last verification needed because of $ rounding errors 
            // the only field that has more decimals than 2 is the labourDuration, so need to check that one
            if (editedProvidingItem.labourRate) {
              editedProvidingItem.labourDuration = round(editedProvidingItem.price / editedProvidingItem.labourRate, 6);
              if (editedProvidingItem.providedQuantities.get(UnitType.Time)) {
                editedProvidingItem.providedQuantities.get(UnitType.Time).quantity = editedProvidingItem.labourDuration;
              }
            }

            if (!nonImpersonatedUser.shouldAutoSyncProvidingItems) {
              providingItemsStore.saveToDb(
                [editedProvidingItem],
                providingItemsStore.collections.filter(collection => task
                  ? collection === providingItemsStore.projectCollection
                  : collection === providingItemsStore.userCollection
                ),
                undefined,
                batch
              );
            } else {
              providingItemsStore.batchAddEditItem(editedProvidingItem, batch);
            }

            if (
              task &&
              task.providingItemId === providingItem.id &&
              editedProvidingItem.id !== providingItem.id
            ) {
              task.providingItemId = editedProvidingItem.id;
              tasksStore.batchAddEditItem(task, batch);
            }

            batch.commit();
          }
        }}
        FormComponent={ProvidingItemDetailsForm}
        FormComponentProps={{
          parentTask: task,
          onFactoryReset: this.masterCollectionItem && (
            JSON.stringify(omit(modelToPlainObject(this.masterCollectionItem), fieldsToIgnoreInModelComparisons)) !==
            JSON.stringify(omit(modelToPlainObject(providingItem), fieldsToIgnoreInModelComparisons))
          ) && this.handleFactoryReset,
          onUserItemReset: isProjectItemDifferentFromUser && this.handleUserItemReset,
          onUserItemPush: (isProjectItemDifferentFromUser || !userCollectionProvidingItem) && !nonImpersonatedUser.shouldAutoSyncProvidingItems && this.handleUserItemPush,
        }}
      />
    );
  }
}