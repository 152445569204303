
import Fee from 'models/Fee';
import * as React from 'react';
import { formatCurrency, formatPercentage, InputNumberFormatPercent } from 'utils/NumberFormatter';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { getSafe } from 'utils/Utils';
import Editable from '../Editable/Editable';
import ObserverComponent from '../ObserverComponent';

const styles = require('./FooterEditableFee.module.scss');

interface FooterEditableFeeProps {
  fee: Fee,
  className?: string,
  parentClassName?: string,
}

export default class FooterEditableFee extends ObserverComponent<FooterEditableFeeProps> {
  initialFeeValue = 0;

  _render() {
    const { settingsStore, treeNodesStore, totalsStore } = this.context;
    const { fee, className, parentClassName } = this.props;

    const { settings } = settingsStore;
    const { percentage, name } = fee;
    const feeIndex = settings.fees.indexOf(fee);
    const projectSubtotal = getSafe(() => treeNodesStore.rootNode.subtotalBeforeFees) || 0;
    const feeValue = totalsStore.feesTotalsByFee.get(fee);

    if (!this.initialFeeValue) {
      this.initialFeeValue = feeValue;
    }

    return (

      // marche pas parce que le prix * percent va pas être calculé si on modifie une copie du percent
      // pourrait marcher avec un render prop
      <Editable
        editLabel={name}
        label={`${name} (${formatPercentage(percentage)})`}
        model={settings}
        store={settingsStore}
        className={parentClassName}
        propertyName={`fees.${feeIndex}.percentage`}
        displayComponent={
          <div className={styles.root + ' ' + className}>
            <div>{name} ({formatPercentage(percentage, true)})</div>
            <div>{formatCurrency(feeValue)}</div>
          </div>
        }
        inputNumberFormatter={InputNumberFormatPercent}
        isNumber
        InputClassName={styles.input}
        //onSave={(model) => this.applyNewProjectName(model as Project)}
        //className={styles.projectNameField}
        classNameEditMode={styles.feeEdit}
        onSave={() => {
          if (this.initialFeeValue !== feeValue) {
            trackInteraction(TrackedInteractions.ChangeFeePercent)
          }
        }}
      //InputClassName={styles.projectNameFieldInput}
      />

    )
  }
}