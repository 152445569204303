import { BuiltinCategories } from 'constants/BuiltinCategories';
import { DbLocationType } from 'constants/DbLocationType';
import { MeasurementType } from 'constants/MeasurementType';
import Globals from 'Globals';
import { compact, Dictionary, first, flatten, fromPairs, groupBy, isEmpty, pull, remove, sortBy, uniq, uniqBy } from 'lodash';
import { action, computed, observable, reaction, transaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import BackgroundImage from 'models/BackgroundImage';
import Category from 'models/Category';
import Measurement from 'models/Measurement';
import MeasurementValue from 'models/MeasurementValue';
import Point from 'models/Point';
import Project from 'models/Project';
import Shape from 'models/Shape';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import FirebaseStore from 'stores/FirebaseStore';
import { flattenItemsByCategSubcateg, groupItemsByCategSubcateg, IFlattenedItemsByCategSubcateg } from 'utils/CategoryUtil';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { COUNT_FUNCTION, formulaIsBubbleFunction, formulaIsNumber } from 'utils/MeasurementFormatter';
import { getMeasurementsWithDependencies } from 'utils/MeasurementUtil';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { getSafe, modelSortFunction, trySetTimeout } from 'utils/Utils';


export default class TreeNodesStore extends FirebaseStore<TreeNode> {
  storeKey = 'treeNodes';

  dbLocationsTypes = new Set([
    DbLocationType.MasterProject,
    DbLocationType.Project
  ]);


  treeComponentRef = null;

  @computed get childrenToParentMap(): Map<ModelId, ModelId> {
    const retval = new Map<ModelId, ModelId>();

    this.items.forEach(parent => {
      parent.childrenIds.forEach(childId => {
        retval.set(childId, parent.id);
      })
    });

    return retval;
  }

  @observable _selectedTreeNodeIds: ModelId[] = [];

  @computed get selectedTreeNodeIds(): ModelId[] {
    return this._selectedTreeNodeIds;
  }

  set selectedTreeNodeIds(nodeIds: ModelId[]) {
    const selectedNode = this.getItem(nodeIds?.[0] || '');

    if (selectedNode) {
      const expandedFields = ['isExpanded', 'isExpanded2'];

      expandedFields.forEach(expandedField => {
        const collapsedAncestors = selectedNode.ancestors.filter(ancestor => !ancestor[expandedField]);
        collapsedAncestors.forEach(collapsedAncestor => collapsedAncestor[expandedField] = true);
      });
    }

    this._selectedTreeNodeIds = nodeIds;
  }

  @computed get selectedTreeNodeId(): ModelId {
    return first(this.selectedTreeNodeIds);
  };

  @computed get selectedTreeNodes(): TreeNode[] {
    return compact(this.selectedTreeNodeIds.map(nodeId => this.getItem(nodeId)));
  }

  @computed get selectedTreeNode(): TreeNode {
    return first(this.selectedTreeNodes);
  }

  set selectedTreeNode(node: TreeNode) {
    this.selectedTreeNodes = node ? [node] : [];
  }

  set selectedTreeNodes(nodes: TreeNode[]) {
    transaction(() => {
      // deselect previous
      if (!isEmpty(this.selectedTreeNodeIds)) {
        this.selectedTreeNodeIds.forEach(nodeId => {
          const node = this.getItem(nodeId);
          if (!node) {
            return;
          }
          node.isSelected = false;
          this.addEditItem(node, false);
        });
      }

      if (!isEmpty(nodes)) {
        nodes.forEach(node => {
          node.isSelected = true;
          this.addEditItem(node, false);

          // expand parents
          let parentNode = this.getParentNode(node);
          while (parentNode) {
            if (!parentNode.isExpanded) {
              parentNode.isExpanded = true;
              this.addEditItem(parentNode);
            }
            parentNode = this.getParentNode(parentNode);
          }
        });
      }

      this.selectedTreeNodeIds = nodes.map(node => node.id);
    });
  }

  @computed get selectedShapes(): Shape[] {
    return flatten(this.selectedTreeNodes.map(node => node.shapes));
  }

  @computed get selectedShapesPoints(): Point[] {
    return flatten(this.selectedShapes.map(shape => [...shape.points]));
  }

  @observable treeNodeBeingRenamed: TreeNode = null;

  @observable drawingTreeNodeBeingCopied: TreeNode;
  @observable drawingCopyTargetNode: TreeNode;

  @computed get rootNode(): TreeNode {
    // should never have more than one root node, but in case it happens, try to find the good one
    return first(sortBy(this.items.filter(i => i.isRootNode),
      i => (i.isDrawingNode ? 500 : 0) + -i.children.length + -i.tasks.length
    ));
  }

  // nodes except the drawing hidden nodes
  @computed get allVisibleNodes(): TreeNode[] {
    return this.rootNode
      ? this.allVisibleChildNodes(this.rootNode, true)
      : [];
  }

  @computed get allNodes(): TreeNode[] {
    return this.rootNode
      ? this.allVisibleChildNodes(this.rootNode, false)
      : [];
  }

  @computed get hasSomeInvisibleNodes(): boolean {
    return this.items.some(node => !node.isVisible);
  }

  getNodeWithMeasurementValue = computedFn((measurementValue: MeasurementValue) =>
    this.allNodes.find(node => node.measurementValues.has(measurementValue.id)) ||
    this.selectedTreeNode // not sure it's a valid fallback
  )


  @computed get selectedNodeCategories(): Category[] {
    return uniq(
      [
        ...this.selectedNodeOwnTaskCategories,
        ...this.selectedNodeTaskCategories
      ]).sort(modelSortFunction);
  }

  @computed get selectedNodeTaskCategories(): Category[] {
    return uniqBy(this.selectedTreeNode?.ownTasks || [], task => task.category).map(task => task.category)
      .sort(modelSortFunction);
  }

  @computed get selectedNodeOwnTaskCategories(): Category[] {
    //duplicate
    const { categoriesStore } = this.stores;
    const generalCategory = categoriesStore.getItem(BuiltinCategories.General);
    const categories = (getSafe(() => this.selectedTreeNode.ownTaskCategories) || []);
    return ((isEmpty(categories) && generalCategory)
      ? [generalCategory]
      : compact(compact(categories).map(categ => categoriesStore.getItem(categ.id)))
    ).sort(modelSortFunction);
  }

  @computed get selectedNodeOwnExpandedTasksByCategFlattened(): IFlattenedItemsByCategSubcateg<Task>[] {
    const { categoriesStore } = this.stores;

    return this.selectedNodeOwnTasksByCategFlattened
      .filter(row => (
        !row.item ||
        this.selectedNodeOwnTaskCategories.length === 1 || // don't collapse when only one categ
        !categoriesStore.collapsedTaskCategories.has(row.category)
      ));
  }

  @computed get selectedNodeOwnTasksByCategFlattened(): IFlattenedItemsByCategSubcateg<Task>[] {
    const { categoriesStore } = this.stores;
    const emptyList = [{ category: categoriesStore.generalCategory }, {}];

    if (!this.selectedTreeNode) {
      return emptyList;
    }
    let retval = flattenItemsByCategSubcateg(groupItemsByCategSubcateg(this.selectedTreeNode.ownTasks), this.stores);

    // add empty node own categs
    retval = [
      ...retval,
      ...this.selectedNodeOwnTaskCategories
        .filter(category => isEmpty(this.getSelectedNodeOwnTasksForCategory(category)))
        .map(category => ({ category }))
    ].sort((a, b) => (a.category?.index || 0) - (b.category?.index || 0));

    if (isEmpty(retval)) {
      return emptyList;
    }

    if (retval.length === 1) {
      return [...retval, {}];
    }

    return retval;
  }

  getSelectedNodeOwnTasksForCategory = computedFn((category: Category) => (
    getSafe(() => this.selectedTreeNode.ownTasks.filter(task => task.category === category))) || []
  )

  getSelectedNodeChildTasksForCategory = computedFn((category: Category) => (
    getSafe(() => this.selectedTreeNode.childTasks.filter(task => task.category === category))) || []
  )

  @computed/*({ keepAlive: true })*/ get nodesByShapeId() {
    return this.rootNode
      ? fromPairs(
        [this.rootNode, ...this.rootNode.descendants]
          .filter(i => i.shape || !isEmpty(i.ownShapesIds))
          .map(n => !isEmpty(n.ownShapesIds)
            ? n.ownShapesIdsArray.map(shapeId => [shapeId, n])
            : [[n.shape.id, n]]
          ).flat(),
      )
      : {};
  }

  @computed get nodesByTaskId(): Map<string /* task id */, TreeNode> {
    return new Map(this.items.map(node => node.ownTasksIds.map(taskId => [taskId, node])).flat());
  }

  @computed get nodesByBackgroundImageId(): Dictionary<TreeNode[]> {
    return groupBy(this.rootNode.descendants, node => node.backgroundImageId);
  }

  getNodeForShape = (shape: Shape) => !!shape && this.nodesByShapeId[shape.id];

  getNodeForTask = (task: Task) => task ? this.nodesByTaskId.get(task.id) : null;

  getNodesForBackgroundImage = (bgImage: BackgroundImage) => this.nodesByBackgroundImageId[bgImage?.id] || [];

  // should go to TreeNode object instead
  getAncestorsNames = computedFn((node: TreeNode) => {
    const names = [];
    let parentNode = this.getParentNode(node);
    while (parentNode) {
      names.push(parentNode.text);
      parentNode = this.getParentNode(parentNode);
    }

    if (names.length > 0) {
      names.pop(); // remove root node (project overview) that is common to everyone
    }

    return names.reverse();
  })

  getPath = computedFn((node: TreeNode) =>
    this.getAncestorsNames(node).concat(node.text).join(' > ')
  )

  // bad name since visible is parameter
  private allVisibleChildNodes = (node: TreeNode, visibleNodesOnly): TreeNode[] => {
    return [
      node,
      ...(
        (!visibleNodesOnly || node.hasNonDrawingRootChildren)
          ? flatten(node.children.map(child => this.allVisibleChildNodes(child, visibleNodesOnly)))
          : []
      )
    ] as TreeNode[];
  };

  // this is called when root node is loaded only, not all nodes, because
  // of they way it gets deserialized.... :(
  // Create new user in DB when first login
  public onLoadCompleted() {
    (async () => {
      // double check that really root node isnt in db, can't afford to be a connection error
      // or we will overwrite whole project
      if (!this.rootNode) {
        try {
          const projectSnapshot = await this.projectCollection.where('isRootNode', '==', true).get({ source: 'server' });
          const masterProjectSnapshot = await this.masterProjectCollection.where('isRootNode', '==', true).get({ source: 'server' });

          if (projectSnapshot.docs.length + masterProjectSnapshot.docs.length === 0) {
            this.onLoadCompletedAndVerified();
          } else {
            debugger; // bad!
            window.location.reload();
          }
        } catch (error) {
          if (navigator.onLine) {
            debugger;
            this.stores.commonStore.error = error.message;
            throw (error);
          }
        }
      } else {
        this.onLoadCompletedAndVerified();
      }
    })();
  }

  public onLoadCompletedAndVerified() {
    const {settingsStore} = this.stores;

    let firstLocation: TreeNode;

    const isProjectTree = this.stores == Globals.defaultStores;
    if (!this.rootNode) {
      const batch = firestoreBatch(this.stores);
      batch.isUndoable = false;

      // create the project root node
      const rootNode = new TreeNode(this.stores);
      rootNode.isRootNode = true;
      rootNode.isExpanded = true;
      rootNode._name = i18n.tAll("Overall view");
      rootNode.shouldBubbleMeasurements = false;

      if (isProjectTree) {
        firstLocation = new TreeNode(this.stores);
        if (settingsStore.isVerticalPhone) {
          firstLocation._name = i18n.tAll('Location');
        } else {
          firstLocation._name = i18n.tAll('Location 1 (e.g. 2nd floor, Bathroom, etc.)');
        }
        firstLocation.shouldBubbleMeasurements = false;

        this.addEditItem(firstLocation, true, undefined, batch);
        this.appendNode(firstLocation, rootNode, undefined, batch);
      } else {
        this.addEditItem(rootNode, true, undefined, batch);
      }

      batch.commit();
    }

    super.onLoadCompleted();
    this.rootNode.isExpanded = true;
    this.selectedTreeNode = isEmpty(this.rootNode.ownTasksIds)
      ? (this.rootNode.nonDrawingChildren[0] || this.rootNode)
      : this.rootNode;
  }

  @action
  saveAfterDragAndDrop() {
    // update children property which is modified after a drag & drop
    if (isEmpty(this.rootNode.children)) {
      //bug
      debugger;
    }

    this.addEditItems(this.items, true, ['childrenIds']);
  }

  @computed get areAllNodesCollapsed() {
    return this.rootNode && this.rootNode.nonDrawingDescendants.every(node => node.isLeaf || !node.isExpanded || node.descendants.includes(this.selectedTreeNode));
  }

  @action expandOrCollapseAllNodes = (forceCollapse = false) => {
    const shouldExpand = this.areAllNodesCollapsed && !forceCollapse;
    (this.stores.name === 'drawingStores' ? this.rootNode.descendants : this.rootNode.nonDrawingDescendants)
      .filter(n => !n.isLeaf)
      .forEach(n => n.isExpanded = shouldExpand || n.descendants.includes(this.selectedTreeNode));
    this.rootNode.isExpanded = true; // root should always stay expanded
    // could save to db
  }

  @action
  // doesn't save the new node, only the parent node
  appendNode(node: TreeNode, parentNode: TreeNode, indexWithinSiblings: number = parentNode.children.length, batchParam: WriteBatch = null) {
    const previousParent = this.items.find(item => item.children.includes(node));

    if (previousParent) {
      // @ts-ignore
      previousParent.childrenIds.remove(node.id);
    }

    const cleanedUpChildrenIds = parentNode.children.map(n => n.id);

    cleanedUpChildrenIds.splice(indexWithinSiblings, 0, node.id);
    parentNode.childrenIds = cleanedUpChildrenIds;

    // update children property which is modified after a drag & drop
    const itemsToSave = !previousParent || (previousParent === parentNode)
      ? [parentNode]
      : [previousParent, parentNode];

    this.addEditItems(itemsToSave, true, null, batchParam);

    if (node.ancestors.every(n => n.isExpanded)) {
      trySetTimeout(() => {
        this.treeComponentRef.scrollTo({ key: node.id });
      }, 150)
    }
  }

  @action duplicateNode(node: TreeNode): TreeNode {
    const batch = firestoreBatch(this.stores);

    const parentNode = this.getParentNode(node) || this.rootNode;

    let nodeCopy = node.clone(this.stores);
    if (node === this.rootNode) {
      // don't copy children because will copy the whole project
      nodeCopy.children = [nodeCopy.childDrawingNode];
      nodeCopy.isRootNode = false;
    }

    nodeCopy = nodeCopy.cloneDeep(batch);
    this.batchAddEditItem(nodeCopy, batch);
    this.appendNode(nodeCopy, parentNode, parentNode.childrenIds.indexOf(node.id) + 1, batch);

    batch.commit();

    return nodeCopy;
  }

  async duplicateNodeToExternalProject(node: TreeNode, project: Project) {
    if (!project) {
      return;
    }

    // duplicate
    let nodeCopy = node.clone(this.stores);
    if (node === this.rootNode) {
      // don't copy children because will copy the whole project
      nodeCopy.children = [nodeCopy.childDrawingNode];
    }

    const { externalProjectStores } = Globals;
    const batch = firestoreBatch(this.stores);

    externalProjectStores.commonStore.selectedProjectId = project.id;

    await waitOnStoresReady([
      externalProjectStores.treeNodesStore,
      externalProjectStores.measurementsStore,
      externalProjectStores.tasksStore,
      externalProjectStores.backgroundImagesStore
    ]);

    nodeCopy = nodeCopy.cloneDeep(
      batch,
      externalProjectStores,
    );

    // todo copy images/notes

    node.backgroundImages.forEach((backgroundImage) => {
      // keep same id, doesnt matter
      externalProjectStores.backgroundImagesStore.batchAddEditItem(backgroundImage, batch);
    });

    externalProjectStores.treeNodesStore.batchAddEditItem(nodeCopy, batch);
    externalProjectStores.treeNodesStore.appendNode(nodeCopy, externalProjectStores.treeNodesStore.rootNode, 0, batch);

    await batch.commit();

    externalProjectStores.commonStore.selectedProjectId = '';
  }


  // could override deleteItem, but then gets called from parent class
  @action
  deleteNodeAndDescendants(node: TreeNode, shouldSaveToDb = true, batchParam: WriteBatch = null) {
    const { shapesStore, tasksStore } = this.stores;
    const batch = batchParam || firestoreBatch(this.stores);

    // this could be done by auto delete cascade instead
    // need to be done at the beginning before we start deleting nodes
    if (
      !isEmpty(node.shapes) &&
      // only delete when in same context (ie. tasks list shouldn't delete project shapes)
      first(node.shapes).stores === node.stores
    ) {
      shapesStore.deleteItems(node.shapes.map(shape => shape.id), shouldSaveToDb, batch)
    };

    if (
      !isEmpty(node.tasks) &&
      // only delete when in same context (ie. tasks list shouldn't delete project shapes)
      first(node.tasks).stores === node.stores
    ) {
      tasksStore.deleteItems(node.tasks.map(task => task.id), shouldSaveToDb, batch);
    };

    const parentNode: TreeNode = this.getParentNode(node);

    // remove from children array of parent
    // @ts-ignore
    if (parentNode) {
      remove(parentNode.childrenIds, childId => childId === node.id);
      this.addEditItem(parentNode, shouldSaveToDb, null, batch);
    }

    // remove node and all child nodes
    const nodeIdsToDelete: ModelId[] = [
      node.id,
      ...node.descendants.map(node => node.id)
    ];

    // remove from selected nodes
    this.selectedTreeNodeIds = pull(this.selectedTreeNodeIds, ...nodeIdsToDelete);

    if (!this.selectedTreeNode) {
      this.selectedTreeNode = parentNode || this.rootNode;
    }

    this.deleteItems(nodeIdsToDelete, shouldSaveToDb, batch);

    if (!batchParam) {
      batch.commit();
    }
  }

  @action deleteDescendants(node: TreeNode, shouldSaveToDb = true, batchParam: WriteBatch = null) {
    const batch = batchParam || firestoreBatch(this.stores);

    node.children.map(childNode => this.deleteNodeAndDescendants(childNode, shouldSaveToDb, batch))
    node.childrenIds = [];

    if (!batchParam) {
      batch.commit();
    }
  }

  @action deleteSelectedNodeTask(taskIdToRemove: ModelId) {
    const batch = firestoreBatch(this.stores);
    // would need to skip this part if we want to be able to easily restore
    // a deleted node
    remove(this.selectedTreeNode.ownTasksIds, taskId => taskId === taskIdToRemove);
    this.batchAddEditItem(this.selectedTreeNode, batch);

    const { tasksStore } = this.stores;
    tasksStore.deleteItem(taskIdToRemove, true, batch);

    batch.commit();
  }

  getParentNode(childNode: TreeNode): TreeNode {
    return this.getItem(this.childrenToParentMap.get(childNode?.id));
  }

  @action removeUnneededMeasurements(treeNode = this.selectedTreeNode, measurementsSubset: Measurement[] = undefined, batch: WriteBatch = null) {
    const measurentsToRemove = measurementsSubset
      ? treeNode.unneededMeasurements.filter(m => measurementsSubset.find(m2 => m2?.id === m?.id))
      : treeNode.unneededMeasurements;

    this.toggleNodeMeasurements(measurentsToRemove, false, treeNode, undefined, batch);
  }

  @action resetNonDefaultMeasurementValues(treeNode = this.selectedTreeNode, measurementsSubset: Measurement[] = undefined) {
    const batch = firestoreBatch(this.stores);

    const measurentsValuesToReset = measurementsSubset
      ? treeNode.nonDefaultMeasurementValues.filter(mv => measurementsSubset.find(m2 => m2?.id === mv?.measurement?.id))
      : treeNode.nonDefaultMeasurementValues;

    measurentsValuesToReset.forEach(mv => {
      mv.__formula = mv.measurement.__defaultFormula;
      this.addEditItem(mv.treeNode, true, ['ownMeasurementValues'], batch);
    })

    batch.commit();
  }

  @action toggleNodeMeasurements(measurements: Measurement[], activate = true, treeNode = this.selectedTreeNode, shouldActivateDependencies = true, batchParam: WriteBatch = null) {
    if (isEmpty(measurements)) {
      return;
    }

    let nodesToSave = new Set<TreeNode>();

    // always get dependencies when deactivating, to cleanup hidden unused dependencies
    let measurementsWithDependencies = (!activate || shouldActivateDependencies)
      ? getMeasurementsWithDependencies(measurements)
      : measurements;

    const otherActiveMeasurements = treeNode.measurements.filter(measurement => !measurementsWithDependencies.includes(measurement));
    const hiddenDependenciesStillInUse = activate
      ? []
      : getMeasurementsWithDependencies(otherActiveMeasurements).filter(measurement => measurement.isHidden);

    // remove dependencies already in use
    // (important when toggling off, to not toggle off dependencies still inuse)
    // O(2)
    const measurementsToToggle = activate
      ? measurementsWithDependencies
      : measurementsWithDependencies
        .filter(measurement => measurement.isHidden || measurements.includes(measurement))
        // don't remove ones in use unless explicitly asking to remove it
        .filter(measurement => !hiddenDependenciesStillInUse.includes(measurement) || measurements.includes(measurement))

    measurementsToToggle.forEach(measurement => {
      // ancestors become irrelevant whether we add measurement lower in the tree
      if (activate) {
        treeNode.getBubbleAncestorsWithOwnMeasurement(measurement).forEach(ancestor => {
          ancestor.ownMeasurementValues.delete(measurement.id);
          nodesToSave.add(ancestor);
        });
      }

      if (!activate) {
        treeNode.getBubbleDescendantsWithOwnMeasurement(measurement).forEach(descendant => {
          // we should never activate a child measurement directly, because we activate to current node only
          // and if there is a child node with measurement, we consider it already activated.
          descendant.ownMeasurementValues.delete(measurement.id);
          nodesToSave.add(descendant);
        });

        if (treeNode.ownMeasurementValues.has(measurement.id)) {
          treeNode.ownMeasurementValues.delete(measurement.id);
          nodesToSave.add(treeNode);
        }
      }

      if (
        activate &&
        isEmpty(treeNode.getBubbleDescendantsWithOwnMeasurement(measurement)) &&
        !treeNode.ownMeasurementValues.has(measurement.id)
      ) {
        const newMeasurementValue = new MeasurementValue(this.stores, treeNode, measurement);

        // ugly exception for roof slope direction default value, but improves UX a lot
        if (measurement.measurementType === MeasurementType.RoofSlopeDirection) {
          const horizontalLines = treeNode.lineShapes.filter(line => line.isHorizontal);
          const verticalLines = treeNode.lineShapes.filter(line => line.isVertical);

          if (
            horizontalLines.length &&
            horizontalLines.length === treeNode.lineShapes.length &&
            newMeasurementValue.metricValue !== 0 &&
            newMeasurementValue.metricValue !== Math.PI
          ) {
            newMeasurementValue.formula = '0';
          }

          if (
            verticalLines.length &&
            verticalLines.length === treeNode.lineShapes.length &&
            newMeasurementValue.metricValue !== Math.PI / 2 &&
            newMeasurementValue.metricValue !== 3 / 2 * Math.PI
          ) {
            // metric value is in radians, formula value is (normally) in degrees
            newMeasurementValue.formula = '270';
          }
        }

        treeNode.ownMeasurementValues.set(
          measurement.id,
          newMeasurementValue
        );
        nodesToSave.add(treeNode);
      }
    });

    return this.addEditItems(Array.from(nodesToSave.values()), true, ['ownMeasurementValues'], batchParam);
  }

  @action applyMeasurementValues(node: TreeNode, measurementValues: MeasurementValue[], shouldActivateDependencies = true, batchParam: WriteBatch = null) {
    const batch = batchParam || firestoreBatch(this.stores);

    measurementValues = measurementValues.filter(mv => mv.measurement);

    const measurementValuesToBubbleDown = measurementValues.filter(
      value => !isEmpty(node.getBubbleDescendantsWithOwnMeasurement(value.measurement)) &&
        !formulaIsBubbleFunction(value.formula) && // don't bubble down sum_children type functions
        !value.formula.includes(COUNT_FUNCTION) // nor that one
    );

    const measurementValuesToApplyDirectly = measurementValues.filter(
      value => isEmpty(node.getBubbleDescendantsWithOwnMeasurement(value.measurement))
    );

    this.toggleNodeMeasurements(measurementValues.map(mv => mv.measurement), true, node, shouldActivateDependencies, batch);

    // this override is for measurements that are *not summable like 'Wall height', to save the value
    measurementValuesToBubbleDown.forEach(measurementValue => {
      const measurement = measurementValue.measurement;
      const descendantsWithSameMeasurement = node.getBubbleDescendantsWithOwnMeasurement(measurement)

      descendantsWithSameMeasurement.forEach(descendant => {
        let descendantMeasurementValue = descendant.ownMeasurementValues.get(measurement.id);
        if (
          measurement.isSummable &&
          formulaIsNumber(measurementValue._formula) &&
          measurementValue.metricValue > 0
        ) {
          descendantMeasurementValue.formula = '' + parseFloat(measurementValue._formula) / descendantsWithSameMeasurement.length
        } else {
          // when summable measurement is set in parent, we assume it means we can evenly distribute it to leafs
          // localization should be simplified
          descendantMeasurementValue.__formula = Object.assign({}, measurementValue.__formula);
        }
      });
    });

    measurementValuesToApplyDirectly.forEach(value => {
      node.ownMeasurementValues.set(value.measurementId, value.clone(this.stores));
    });

    measurementValues.forEach(value => {
      if (value.adjustment) {
        //value.adjustment.measurementId = value.measurementId; // is this needed?
        node.ownMeasurementAdjustments.set(
          value.measurementId,
          value.adjustment.clone(this.stores),
        );
      }
    });

    // could improve precision of modified nodes
    const nodesToSave = isEmpty(measurementValuesToBubbleDown)
      ? [node]
      : [node, ...node.bubbleDescendants];

    this.addEditItems(nodesToSave, true, ['ownMeasurementValues', 'ownMeasurementAdjustments'], batch);

    if (!batchParam) {
      batch.commit();
    }
  }

  @computed get allCachedValues() {
    return this.items.map(item => [
      item.measurementValuesArray,
      item.measurementValuesArray.map(mv => mv.metricValue).flat(),
    ].flat()).flat();
  }

  selectedNodeObserver = reaction(() => this.selectedTreeNode, (selectedTreeNode) => {
    if (!selectedTreeNode?.isSelected && this.rootNode) {
      this.selectedTreeNode = this.rootNode;
    }
    if (this.stores.measurementsStore?.selectedItems?.size > 0) {
      this.stores.measurementsStore?.selectedItems?.clear();
    }
  })

  measurementValuesObserver = reaction(() => this.stores.name !== 'drawingStores' && this.allCachedValues, (values) => {
    // nothing, just observe so we keep all metric values memoized at all time
  });

  doubleRootNodeDetector = reaction(() => this.items.filter(i => i?.isRootNode).length, value => {
    if (value > 1) {
      console.error('More than one root node in ' + this.stores?.name + ' ' + this.storeKey);
      debugger;
    }
  })

  /* override */ attemptLoadItems() {
    if (this.isLoading) {
      return;
    }

    if (
      !this.stores.shapesStore.isReady
    ) {
      return;
    }

    super.attemptLoadItems();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.shapesStore?.isReady
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}
