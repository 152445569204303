const styles = require('./CursorPoint.module.scss');
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';

const colors = require('Colors.scss');
interface ICursorPointProps {
  //point: Point;  better to get inside this component rather than props to avoid rerender of drawlayer
  style: any;
}

export default class CursorPoint extends ObserverComponent<ICursorPointProps> {
  private RADIUS = 15 * 2;
  private CROSS_HAIR_EXTRA_LENGTH = 8 * 2;
  private CROSS_HAIR_LENGTH = this.RADIUS + 2 * this.CROSS_HAIR_EXTRA_LENGTH * 2;

  public _render(): JSX.Element {
    const { shapesStore, snapStore, drawToolsStore } = this.context;
    const point = snapStore.cursorPosition;
    const { style } = this.props;

    const color = (shapesStore.isCalibrationSecondStep && shapesStore.backgroundImageScaleShape.startPt.distancePixels(point) < 20) 
      ? colors.green 
      : colors.action;

    return point && (
      <g id="cursorPt" style={style} color={color}>
        <rect
          key="rect"
          className={styles.default}
          x={point.x - this.RADIUS / 2}
          y={point.y - this.RADIUS / 2}
          width={this.RADIUS}
          height={this.RADIUS}
        />
        <line
          key="verticalLine"
          className={styles.default}
          x1={point.x}
          y1={point.y - this.CROSS_HAIR_LENGTH}
          x2={point.x}
          y2={point.y + this.CROSS_HAIR_LENGTH}
        />
        <line
          key="horizontalLine"
          className={styles.default}
          x1={point.x - this.CROSS_HAIR_LENGTH}
          y1={point.y}
          x2={point.x + this.CROSS_HAIR_LENGTH}
          y2={point.y}
        />
      </g>
    )
  }
}
