
import classnames from 'classnames';
import { IEditableProps } from 'constants/CommonProps';
import { compact, defer } from 'lodash';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import ReactQuill from 'react-quill';
import sanitizeHtml from 'sanitize-html';
import FirebaseStore from 'stores/FirebaseStore';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

const styles = require('./RichText2.module.scss');
require('./RichText2.scss');

interface RichText2Props {
  model: ModelBase,
  property: string,
  canAddSignature?: boolean,
  className?: string,
  style?: any,
  store?: FirebaseStore<ModelBase>,
  toolbarTitle?: string,
  scrollingContainer?: string,
}

/*
 * Simple editor component that takes placeholder text as a prop
 */

const SIGNATURE_BLOCK_HTML = `
<br><br><b>_______________________________             ___________________</b> 
<br>Signature                                                       Date<br>
`;

const UNDERSCORE_HTML = '<b>__</b>';
export default class RichText2 extends ObserverComponent<RichText2Props & IEditableProps> {
  ref;
  
  mObserver;

  lastModelId = '';

  formats = [
    'bold', 'italic', 'underline', 'list', 'bullet', 'strike'
    /*'header', 'font', 'size',
    'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'*/
  ];

  get editorDomElement() {
    return this.ref?.editor?.root;
  }

  signatureHandlers = {
    signature: () => this.handleToolbarInsert('signature'),
    underscore: () => this.handleToolbarInsert('underscore')
  }

  handleToolbarInsert = (name) => {
    if (!this.ref) {
      return;
    }

    const { editor } = this.ref;

    const cursorIndex = editor.selection.lastRange.index;
    const endIndex = editor.scroll.length();

    editor.clipboard.dangerouslyPasteHTML(cursorIndex || endIndex, name === 'signature' ? SIGNATURE_BLOCK_HTML : UNDERSCORE_HTML);
  }

  handleChange = () => {
    const { store, model, property } = this.props;

    if (!this.ref) {
      return;
    }

    const htmlString = this.ref.getEditorContents()
      .replace(/\t/g, '    ');

    model[property] = htmlString;
    // assumes property looks like description2 and serializable property is __description2
    store && store.addEditItemLongDebounced(model, true, [`_${property}`, `_${property.replace('2', '')}`, `__${property}`, `__${property.replace('2', '')}`]);
  }

  // DOESNT work, most of the time editorDomElement is null here
  componentDidMount(): void {
    defer(() => {
      if (!this.editorDomElement) {
       // debugger;
        return;
      }
      this.editorDomElement.addEventListener('blur', this.blurEventListener)
    });
  }

  componentWillUnmount(): void {
    if (!this.editorDomElement) {
      // DOESNT work, most of the time editorDomElement is null here
      //debugger;
      return;
    }
    this.editorDomElement.removeEventListener('blur', this.blurEventListener)
  }

  blurEventListener = (e) => {
    if (!this.editorDomElement) {
      // DOESNT work, most of the time editorDomElement is null here
      //debugger;
      return;
    }

    if (document.activeElement?.id == 'merchantIframe') {
      this.editorDomElement.focus();
    }
  }

  _render() {
    const { isEditable, model, property, className, scrollingContainer, style, canAddSignature } = this.props;

    const modules = {
      toolbar: {
        container: compact([
          //[{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          //[{ size: [] }],
          ['bold', 'italic', 'underline', 'strike']/*, 'strike', 'blockquote']*/,
          [{ 'list': 'ordered' }, { 'list': 'bullet' }/*
      { 'indent': '-1' }, { 'indent': '+1' }*/],
          /* ['link', 'image', 'video'],
           ['clean'],*/
          //[{ 'align': [] }]
          canAddSignature && ['signature'],
          canAddSignature && ['underscore'],
        ]),
        handlers: (canAddSignature ? this.signatureHandlers : undefined)
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      }
    }

    if (!model || !property) {
      return null;
    }

    return (
      <div className={classnames(styles.root, className)} style={style}>
        {isEditable ? (
          <ReactQuill
            ref={ref => {
              if (!ref) {
                if (this.mObserver) {
                  this.mObserver.disconnect();
                  this.mObserver = null;
                }
                return;
              }

              this.ref = ref;              

              if (model.id !== this.lastModelId && this.ref?.editor?.history) {
                // prevent doing undo and restoring the previously selected node's text
                this.ref.editor.history.clear();
                this.lastModelId = model.id;
              }

              if (!this.mObserver) {
                // quill builtin change listener doesn't work correctly, so using this
                this.mObserver = new MutationObserver((mutationsList, observer) => {
                  mutationsList.forEach((mutation) => { this.handleChange(); })
                })
                this.mObserver.observe(ref.editor.root, {
                  childList: true,
                  subtree: true,
                  characterData: true,
                })
              }
            }}
            theme="snow"
            value={model[property]}
            modules={modules}
            formats={this.formats}
            placeholder={i18n.t('Notes')}
            scrollingContainer={scrollingContainer}
          />
        ) : (
          <div className="ql-readonly ql-container ql-snow ql-editor" dangerouslySetInnerHTML={{
            __html: sanitizeHtml(model[property], {
              allowedTags: ['p', 'br', 'u', 'b', 'i', 'ol', 'ul', 'strong', 'li', 's', 'table', 'tr', 'td']
            })
          }} />
        )}
      </div>
    )
  }
}
