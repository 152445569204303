import { and, or, query, where } from '@firebase/firestore';
import Globals from 'Globals';
import { DbLocationType } from 'constants/DbLocationType';
import { isProduction } from 'environment';
import { compact, debounce, first, size } from 'lodash';
import { computed, observable, reaction } from 'mobx';
import Project from 'models/Project';
import moment from 'moment';
import { CollectionReference, WriteBatch, firestore, functions } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { encodeProjectName } from 'utils/ProjectUtils';
import { syncInteractions } from 'utils/TrackingUtil';
import { sleep } from 'utils/Utils';
import i18n from 'utils/i18n';
import SearchableFirebaseStore from './SearchableFirebaseStore';

export default class ProjectsStore extends SearchableFirebaseStore<Project> {
  storeKey = 'projects';

  @observable projectBeingRenamed: Project = null;

  dbLocationsTypes = new Set([
    DbLocationType.Master,
    DbLocationType.User,
  ]);

  hasCategories = true;

  shouldTrackCreationDate = true;
  shouldTrackModifDate = true;
  shouldTrackOwner = true;

  shouldSoftDelete = true;

  //mostly useless sort, gets forcibly sorted by Project.index later on
  sortField = "updated._i";
  sortDirection: "asc" | "desc" = 'desc';

  queryFilters = new Map([['type', 'Project']]);

  updateModificationDateTimer = null;

  latestMondayLogDate = 0;

  updateModificationDate = debounce(async () => {
    if (this.userEmail === 'louisp.tremblay@gmail.com' && this.nonImpersonatedEmail !== this.userEmail) {
      return;
    }

    if (this.selectedProject) {
      const batch = firestoreBatch(this.stores);
      batch.isUndoable = false;
      // should also modify user modification date
      this.addEditItem(this.selectedProject, true, ['updatedMiliseconds'], batch);
      batch.commit();

      if (isProduction()) {
        // once a day, log usage / sync customer journey (traits)
        if ((Date.now() - this.latestMondayLogDate) > 24 * 60 * 60 * 1000) {
          const logUsageBackend = functions.httpsCallable('logUsage');
          try {
            const result = await logUsageBackend();
            this.latestMondayLogDate = Date.now();

            const result2 = await syncInteractions();
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
  }, 10000);

  public onLoadCompleted() {
    super.onLoadCompleted();

    // Creates the timer to update project modification date in case the person lets
    // the project tab open for several days. Only applies to default project store, not the
    // temporary stores used to copy between projects
    if (this !== Globals.defaultStores.projectsStore) {
      return;
    }

    if (this.updateModificationDateTimer) {
      clearInterval(this.updateModificationDateTimer);
    }
    // every 10 minutes
    this.updateModificationDateTimer = setInterval(this.updateModificationDate, 10 * 60 * 1000);
  }

  @computed get selectedProject(): Project {
    return this.itemsMap.get(this.selectedProjectId);
  };

  getNextTemporaryProjectName(baseName = i18n.t('Untitled Project')): string {
    let numberSuffix = 0;
    let newName = baseName;

    const untitledProjects = this.itemsAndHiddenItems
      .filter(project => project.name.includes(baseName));

    while (untitledProjects.some(project => project.name === newName)) {
      numberSuffix++;
      newName = `${baseName} ${numberSuffix}`;
    }

    return newName;
  }

  /* override */ saveToDb(items: Project[], collections: CollectionReference[], fieldsToUpdate = [], batchParam: WriteBatch = null) {
    // add basic protection from renaming project to same name as another one and losing access
    // to one of the 2
    if (size(items) === 1) {
      const projectToSave = items[0];
      const projectFromEncodedName = this.findByEncodedName(projectToSave.encodedName);
      if (projectFromEncodedName && projectToSave.id !== projectFromEncodedName.id) {
        return;
      }
    }

    super.saveToDb(items, collections, fieldsToUpdate, batchParam);
  }

  findByEncodedName(encodedName): Project {
    return first(this.filterByEncodedName(encodeProjectName));
  }

  filterByEncodedName(encodedName): Project[] {
    //needs to be sorted ascending to avoid unwanted refresh with newer project from other user
    return this.itemsAndHiddenItems
      .sort((a, b) => b.createdMiliseconds - a.createdMiliseconds)
      .filter(project => encodeProjectName(project.name) === encodedName);
  }

  @computed get oldestProjectDateMiliseconds() {
    return Math.min(...this.allItems.map(item => item.createdMiliseconds || Number.POSITIVE_INFINITY));
  }
  /* override */
  getItemsImpl(): Project[] {
    // list projects from master only if they are "Templates" categ
    return super.getItemsImpl()
      .filter(p => this.stores?.userInfoStore?.userEmail === 'master' || p.cascadeOrder || p.categoryId === '3d18682b-51aa-44d2-aa0a-d36d35d14736');
  }

  /* override */ attemptLoadItems(shouldForce = false) {
    // apply sharing rules
    const { userInfoStore } = this.stores;

    if (!userInfoStore.isReady) {
      return;
    }

    const { user } = userInfoStore;

    if (user.isAdvancedSharingEnabled) {
      this.queryFilters = new Map();

      this.advancedQueryFunction = collection => query(
        collection,
        and(...compact([
          where('type', '==', 'Project'),
          !userInfoStore.isAdmin && or(
            where('owner', '==', userInfoStore.nonImpersonatedEmail),
            where('readers', 'array-contains-any', [userInfoStore.nonImpersonatedEmail, userInfoStore.organization]),
            where('editors', 'array-contains-any', [userInfoStore.nonImpersonatedEmail, userInfoStore.organization]),
          )
        ])
        )
      );
    }

    super.attemptLoadItems(shouldForce);
  }


  DEBUG__fixReportHeader = async () => {
    const { userInfoStore } = this.stores;

    const db = firestore();
    const batch = firestoreBatch(this.stores);

    await this.items.map(p => p.id).reduce(async (previousTask, projectId) => {
      await previousTask;

      const reports = (await db.collection(`/users/${userInfoStore.userEmail}/projects/${projectId}/reports`).get()).docs;

      if (reports.length > 1) {
        debugger;
      }

      reports.forEach(reportDoc => {
        const report = reportDoc.data();

        if (!report.companyName) {
          debugger;
          return;
        }

        if (!report.companyName.toLowerCase().includes('rodrigue') && report.other !== '') {
          batch.set(reportDoc.ref, { other: '' }, { merge: true });
          console.log("FIXING (remove rbq) - ", this.getItem(projectId)?.name, projectId);
        } else if (report.companyName.toLowerCase().includes('rodrigue') && !report.other) {
          batch.set(reportDoc.ref, { other: 'RBQ : 2702-6855-73' }, { merge: true });
          console.log("FIXING (add rbq) - ", this.getItem(projectId)?.name, projectId);
        }
      })

      await sleep(500);

      return '';
    }, Promise.resolve(''));

    batch.commit();

    console.log('DONE fixing');
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.userInfoStore?.isReady
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });

  selectedProjectChangeReaction = reaction(
    () => this.stores.commonStore?.selectedProjectId,
    () => {
      this.stores.dbCacheStore.clearProjectSpecificCache();
      this.stores.undoStore.reset();
      this.stores.measurementsStore?.selectedItems?.clear();
      this.stores.tasksListsStore?.selectedItems?.clear();
      this.stores.tasksStore?.selectedItems?.clear();

      if (this.selectedProject && moment().valueOf()) {
        this.updateModificationDate();
      }
    });
}
