
import { IconButton, Menu, Tooltip } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import MoreIcon from '@material-ui/icons/MoreVert';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import i18n from 'utils/i18n';
import MenuItemComponent from '../MenuItemComponent/MenuItemComponent';
import ObserverComponent from '../ObserverComponent';


const styles = require('./MenuPopupButton.module.scss');

export interface MenuItem {
  text: string,
  handler?: (event: React.MouseEvent, closeFunction?: () => void, cancelSecondStepFunction?: () => void) => void,
  icon?: JSX.Element,
  danger?: boolean,
  dangerText?: string,
  is2Steps?: boolean,
  secondStepComponent?: (closeFunction: () => void, cancelFunction: () => void) => JSX.Element,
  isGroupStart?: boolean,
  disabled?: boolean,
  title?: string,
  subItems?: MenuItem[],
  shouldStayOpenOnItemClick?: boolean,
  className?:string,
}

interface MenuPopupButtonProps {
  menuItems: MenuItem[],
  className?: string,
  icon?: JSX.Element,
  title?: string,
  text?: string,
  parentPopupState?: any,
  isSubmenu?: boolean,
  menuClassName?: string,
}

interface MenuPopupButtonState {
  // button text as key
  itemsInSecondStep: Set<string>
}

class MenuPopupButton extends ObserverComponent<MenuPopupButtonProps, MenuPopupButtonState> {
  buttonRef;

  state = {
    itemsInSecondStep: new Set<string>()
  }

  onClose = () => {
    this.setState({ itemsInSecondStep: new Set<string>() });
  }

  _render() {
    const { menuItems, className, icon, title, parentPopupState, isSubmenu, text, menuClassName } = this.props;
    const { itemsInSecondStep } = this.state;

    return !isEmpty(menuItems) && (
      <PopupState variant="popover" popupId="demo-popup-menu" parentPopupState={parentPopupState}>
        {popupState => {
          this.buttonRef?.parentElement?.classList?.toggle('isPopupOpen', popupState.isOpen);

          const menuProps = bindMenu(popupState);

          const originalOnClose = menuProps.onClose;
          menuProps.onClose = () => {
            this.onClose();
            originalOnClose();
          }

          const closeFunction = () => {
            popupState.close();
            parentPopupState?.close?.();
          }

          const cancelSecondStepFunction = () => {
            if (!isEmpty(itemsInSecondStep)) {
              itemsInSecondStep.clear();
              this.setState({ itemsInSecondStep });
            }
          }

          return (
            <React.Fragment>
              <div ref={ref => this.buttonRef = ref} className={styles.button + ' ' + className} style={isSubmenu ? { flex: '1' } : {}}>
                {isSubmenu ? (
                  <div {...bindTrigger(popupState)} title={title} className={styles.subMenuButton}>
                    <div className={styles.icon}>{icon}</div> <span style={{ flex: 1 }}>{text}</span> <ChevronRight />
                  </div>
                ) : (
                  <IconButton {...bindTrigger(popupState)} title={title}>
                    {icon || <MoreIcon />}
                  </IconButton>
                )}
              </div>

              <Menu
                {...menuProps}
                //anchorEl={this.buttonRef}
                anchorOrigin={isSubmenu ? { vertical: 'top', horizontal: 'left' } : undefined}
                transformOrigin={isSubmenu ? { vertical: 'top', horizontal: 'right' } : undefined}
                className={classnames(styles.root, menuClassName, { [styles.isSubmenu]: isSubmenu })}
              >{
                  menuItems.map((item, index) => (
                    <Tooltip title={item.title} key={'mic_' + index} open={item.title ? undefined : false}>
                      <div className={item.className || ''}>
                        <MenuItemComponent
                          className={classnames(styles.menuItem, {
                            [styles.danger]: item.danger,
                            [styles.isGroupStart]: item.isGroupStart,
                            [styles.isAskingToDelete]: itemsInSecondStep.has(item.text) && item.danger,
                          })}
                          onClick={(e) => {
                            if ((item.danger || item.is2Steps) && !itemsInSecondStep.has(item.text)) {
                              itemsInSecondStep.add(item.text);
                              this.setState({ itemsInSecondStep });
                            } else if (item.handler) {
                              if (!item.shouldStayOpenOnItemClick) {
                                closeFunction();
                              }

                              item.handler(e, closeFunction, cancelSecondStepFunction);

                              if (!item.shouldStayOpenOnItemClick) {
                                cancelSecondStepFunction();
                              }
                            }
                          }}
                          disabled={item.disabled}
                          item={item}
                        >
                          {!isEmpty(item.subItems) ? (
                            <MenuPopupButton menuItems={item.subItems} text={item.text} icon={item.icon} isSubmenu parentPopupState={popupState} />
                          ) : (
                            <>
                              {item.icon && <div className={styles.icon}>{item.icon}</div>}
                              {itemsInSecondStep.has(item.text) ? item.dangerText || (item.secondStepComponent?.(closeFunction, cancelSecondStepFunction) || i18n.t('Really delete?')) : item.text}
                            </>
                          )}
                        </MenuItemComponent>
                      </div>
                    </Tooltip>
                  ))}
              </Menu>
            </React.Fragment>
          );
        }
        }
      </PopupState >
    );
  }
}

export default MenuPopupButton;