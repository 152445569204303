// TODO: only show when element is currently selected

// CONFUSING: angle calculation is positive when clockwise whereas shown to user is positive when counter clockwise
import { IconButton, Portal } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import TextField from 'components/common/TextField/TextField';
import { DRAWING_SCALE } from 'constants/Constants';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { isEqual } from 'lodash';
import Line from 'models/Line';
import Point from 'models/Point';
import * as React from 'react';
import { offsetLine } from 'utils/GeometryUtils';
import MeasurementConverter from 'utils/MeasurementConverter';
import MeasurementFormatter, { architectStrToDecimal, parseDegreeAngle } from 'utils/MeasurementFormatter';
import { getViewBoxFromParentSvg } from 'utils/ShapeUtil';

// dont use modules for svg part
require('./DimensionLine.scss');

const styles = require('./DimensionLine.module.scss');

enum InputType {
  Length,
  Angle,
}

interface IDimensionLineProps {
  lineToMeasure: Line,
  hidden?: boolean,
  readonly?: boolean,
  forceVisible?: boolean,
  isEditMode?: boolean,
  containerWidth?: number,
  containerHeight?: number,
  isLineBeingAdded?: boolean,
}

interface IDimensionLineState {
  lineCoordinates: number[],
  angle: number, //radian -pi to +iwpi
  previousAngle: number,
  lengthInputValue: string,
  angleInputValue: string,
  inputFocus: InputType,
}

function lineToCoordinates(line: Line): number[] {
  return [line.startPt.x, line.startPt.y, line.endPt.x, line.endPt.y];
}

export default class DimensionLine extends ObserverComponent<IDimensionLineProps, IDimensionLineState> {
  domComponent;
  lengthInputRef;

  state = {
    lineCoordinates: null,
    angle: 0,
    previousAngle: 0,
    lengthInputValue: null,
    angleInputValue: null,
    inputFocus: InputType.Length,
  };

  static getDerivedStateFromProps(props, state) {
    const { lineToMeasure } = props;
    if (!lineToMeasure || isEqual(state.lineCoordinates, lineToCoordinates(lineToMeasure))) {
      return {};
    }
    let { previousAngle } = state;
    const { points } = lineToMeasure;

    let angle = Math.atan2(points[1].y - points[0].y, points[1].x - points[0].x);

    // edge case if drawing in inches ex. 0-5 for 5 inches, when we type 0, the angle becomes unknown, keep last known value
    if (angle === 0 && previousAngle !== 0 && lineToMeasure.length === 0) {
      angle = previousAngle;
    }

    previousAngle = angle;

    return {
      ...state,
      lineCoordinates: lineToCoordinates(lineToMeasure),
      angle,
      previousAngle
    }
  }

  getLineLengthPixelsForAngle = () => {
    const { lineToMeasure } = this.props;
    const { angle } = this.state;

    if (Math.abs(angle) > Math.PI / 2) {
      return Math.min(300, lineToMeasure.lengthPixels);
    }

    return lineToMeasure.lengthPixels;
  }

  selectOtherInput = () => {
    const { drawToolsStore } = this.context;
    const { inputFocus } = this.state;
    const newInputFocus = inputFocus === InputType.Angle ? InputType.Length : InputType.Angle;

    drawToolsStore.shouldLocklineBeingAddedLength = newInputFocus === InputType.Angle;

    this.setState({ inputFocus: newInputFocus });
  }

  // hack and called too often
  get formattedLength() {
    const { lineToMeasure } = this.props;
    const { settingsStore, userInfoStore } = this.context;

    const { user } = userInfoStore;
    const { shouldShowDrawingsInInches } = user;

    return MeasurementFormatter.format(
      //todo fix hack
      // @ts-ignore
      {
        metricValue: lineToMeasure.roofLength,
        measurement: {
          displayUnit: settingsStore.isImperial
            ? (shouldShowDrawingsInInches ? Unit.Inch : Unit.Foot)
            : Unit.Meter,
          unitType: UnitType.Length,
          stores: this.context
        },
        applyAdjustment: (a => a),
      }
    );
  }

  onLengthInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { settingsStore, pagesStore, drawToolsStore } = this.context;
    switch (event.key.toLowerCase()) {
      case 'd':
        drawToolsStore.finalizeUnclosedShape();
        break;
      case 'tab':
      case 'enter':
        this.onLengthInputCommit();
        this.selectOtherInput();
        this.setState({ lengthInputValue: null });

        event.preventDefault();
        event.stopPropagation();
        break;
    }

    if (event.key.toLowerCase() == 'enter') {
      this.confirmDimension();
    }
  }

  onTouchStart = (event) => {
    debugger;
  }

  onAngleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { drawToolsStore } = this.context;
    if (
      event.key.toLowerCase() === 'tab' ||
      event.key === 'Enter'
    ) {
      event.preventDefault();
      event.stopPropagation();

      this.onAngleInputCommit();

      if (event.key.toLowerCase() === 'tab') {
        this.selectOtherInput();
        this.setState({ angleInputValue: null });
      } else { //enter
        this.confirmDimension();
      }
    }
  }

  confirmDimension = (e?) => {
    e?.preventDefault?.();

    const { drawToolsStore } = this.context;
    // hack! should move to AddWallTool
    drawToolsStore.lastMousePt = drawToolsStore.lineBeingAdded.endPt.clone();

    drawToolsStore.selectedDrawToolRef.mouseDown?.(new MouseEvent("click", {}));
  }

  onAngleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target as any;
    this.setState({ angleInputValue: value }, () => {
      // would be nice to only commit after done typing but complicated
      //  this.onAngleInputCommit();
    });
  }

  onAngleInputCommit = () => {
    const { angleInputValue } = this.state;
    const { drawToolsStore, shapesStore } = this.context;

    const { startPt } = drawToolsStore.lineBeingAdded;
    const pixelLength = drawToolsStore.lineBeingAdded.lengthPixels;

    if (angleInputValue === null) {
      return;
    }

    const radians = parseDegreeAngle(angleInputValue) || 0;

    drawToolsStore.lineBeingAdded.endPt = new Point(
      startPt.x + pixelLength * Math.cos(radians),
      startPt.y + pixelLength * Math.sin(radians),
    );

    if (!shapesStore.zoomController.isSvgPointVisible(drawToolsStore.lineBeingAdded.endPt)) {
      //      shapesStore.zoomController.zoomOutOnShapes(2, drawToolsStore.lineBeingAdded.endPt);
    }
  }

  onLengthInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { drawToolsStore, shapesStore } = this.context;

    if (!drawToolsStore.lineBeingAdded) {
      console.error('null start pt, not normal');
      debugger;
      return;
    }

    let { value } = event.target as any;
    this.setState({ lengthInputValue: value }, () => {
      // would be nice to only commit after done typing but complicated
      //this.onLengthInputCommit();
    });
  }

  normalizeTextAngleRadToDeg = (angle: number /* between -Pi to Pi */): number /* between -90 to 90 deg */ => {
    // svg rotation: clockwise from positive x axis
    let degrees = (angle / Math.PI * 180);

    if (degrees < -90) {
      degrees += 180;
    } else if (degrees > 90) {
      degrees -= 180;
    }

    return degrees;
  }

  onLengthInputCommit = () => {
    const { settingsStore, drawToolsStore, shapesStore, userInfoStore } = this.context;
    const { angle, lengthInputValue } = this.state;
    const { shouldShowDrawingsInInches } = userInfoStore.user;
    const newLengthMeters = (
      settingsStore.isImperial
        ? MeasurementConverter.convert(
          shouldShowDrawingsInInches ? lengthInputValue / 12 : architectStrToDecimal(lengthInputValue || this.formattedLength),
          Unit.Foot,
          Unit.Meter
        ) : parseFloat(lengthInputValue)
    ) || 0;

    const newLengthPixels = newLengthMeters * DRAWING_SCALE;
    const { startPt } = drawToolsStore.lineBeingAdded;
    drawToolsStore.lineBeingAdded.endPt = new Point(
      startPt.x + newLengthPixels * Math.cos(angle),
      startPt.y + newLengthPixels * Math.sin(angle),
    );

    if (!shapesStore.zoomController.isSvgPointVisible(drawToolsStore.lineBeingAdded.endPt)) {
      shapesStore.zoomController.zoomOutOnShapes(2, drawToolsStore.lineBeingAdded.midPt);
    }
  }

  _render() {
    const { shapesStore, drawToolsStore, treeNodesStore, routerStore, settingsStore, undoStore } = this.context;
    const { lineToMeasure, hidden, forceVisible, isEditMode, readonly, containerWidth, containerHeight, isLineBeingAdded } = this.props;
    const { angle, lengthInputValue, angleInputValue, inputFocus } = this.state;
    const { isTouchDevice } = settingsStore;

    if (
      !lineToMeasure
    ) {
      return null;
    }

    const textWidth = 90;
    const textHeight = 16;

    // new
    const viewBox = getViewBoxFromParentSvg(this.domComponent);

    const drawingNode = lineToMeasure.treeNode?.parentDrawingNode?.parent;
    const viewBoxWidth = drawingNode?.viewBoxRectWithoutDimensions?.width;
    const viewBoxHeight = drawingNode?.viewBoxRectWithoutDimensions?.height;
    const scale = shapesStore.zoomController?.observableScale || 1;

    let scaleFromContainer = Math.min(
      999999,
      Math.min(
        (containerWidth || 0) / ((viewBox.width || 800)),
        (containerHeight || 0) / ((viewBox.height || 600))
      )
    );

    // duplicate
    let shapeScale = readonly
      ? ((scaleFromContainer || scale) * (routerStore.isReportPage ? 1 : 0.8) || 0.3)
      : scale;

    let textFontSize = 16 / shapeScale;

    const line = offsetLine(lineToMeasure, angle > 0 && isEditMode, 10 + 13 / shapeScale);

    const middlePt = new Point(
      (line.startPt.x + line.endPt.x) / 2,
      (line.startPt.y + line.endPt.y) / 2,
    );

    const angleMiddlePt = new Point(
      (lineToMeasure.startPt.x + this.getLineLengthPixelsForAngle() * Math.cos(angle / 2)),
      (lineToMeasure.startPt.y + this.getLineLengthPixelsForAngle() * Math.sin(angle / 2)),
    );

    // careful not to ask for lastMousePt outside of edit mode, because will rerender all drawing shapes
    if (isEditMode && drawToolsStore.lastMousePt && Math.abs(drawToolsStore.lastMousePt.y - angleMiddlePt.y) < 40 / shapeScale) {
      angleMiddlePt.y += drawToolsStore.lastMousePt.y - angleMiddlePt.y + 40 / shapeScale;
    }

    const angleLineX2 = lineToMeasure.points[0].x + this.getLineLengthPixelsForAngle();

    // don't display if text is bigger than the line
    if (
      !isEditMode && 
      !isLineBeingAdded &&
      line.lengthPixels * shapeScale < this.formattedLength.length * textFontSize * 0.5 /* arbitrary*/ * shapeScale
    ) {
      return null;
    }

    if (isLineBeingAdded && !isEditMode) {
      textFontSize += 8;
    }

    return (
      <g ref={ref => {
        this.domComponent = ref;
      }} className={
        classnames('DimensionLine', {
          isDimensionHidden: hidden,
          isEditMode
        })}
      >
        <g className={'lengthDimension'} strokeWidth={0.5 / shapeScale} strokeDasharray={1 / shapeScale}>
          <line
            className={'default'}
            x1={line.points[0].x}
            y1={line.points[0].y}
            x2={line.points[1].x}
            y2={line.points[1].y}
            markerStart='url(#arrowheadStart)'
            markerEnd='url(#arrowheadEnd)'
          />
          <line
            className={'edgeLine'}
            x1={line.points[0].x}
            y1={line.points[0].y}
            x2={lineToMeasure.points[0].x}
            y2={lineToMeasure.points[0].y}
          />
          <line
            className={'edgeLine'}
            x1={line.points[1].x}
            y1={line.points[1].y}
            x2={lineToMeasure.points[1].x}
            y2={lineToMeasure.points[1].y}
          />
          {!isEditMode && (
            <text filter={
              routerStore.isReportPage
                ? null
                : 'url(#textBackground)'} transform={`rotate(${this.normalizeTextAngleRadToDeg(angle)}, ${middlePt.x}, ${middlePt.y})`
                }
            >
              <tspan textAnchor="middle"
                x={middlePt.x}
                y={middlePt.y}
                dy={textFontSize / 2}
                className={'text'}
                style={{ fontSize: `${textFontSize}px`, fontWeight: isLineBeingAdded ? 'bold' : '' }}
              >
                {this.formattedLength}
              </tspan>
            </text>
          )}
          {isEditMode && (
            <Portal container={shapesStore.zoomController.secondObjectToZoom.querySelector('#fakeViewbox')}>
              <div
                className={classnames(
                  'safariForeignContainer',
                  styles.foreignContainer,
                  styles.foreignContainerLengthInput,
                  { [styles.isTouchDevice]: isTouchDevice }
                )}
                style={{
                  transform: `scale(${1 / shapeScale}) translate(${middlePt.x - 200 / 2}px, ${middlePt.y - 60 / 2}px)`,
                  transformOrigin: `${middlePt.x}px ${middlePt.y}px`,
                }}>
                {isTouchDevice && (
                  <IconButton className={styles.cancelButtonIcon} onClick={async (e) => {
                    await undoStore.undo();
                    drawToolsStore.selectedDrawToolRef?.afterUndoOrRedo?.();
                  }}>
                    <CancelIcon />
                  </IconButton>
                )}
                <TextField
                  inputRef={ref => this.lengthInputRef = ref}
                  onKeyDown={this.onLengthInputKeyDown}
                  onChange={this.onLengthInputChange}
                  className={styles.textField + ' ' + styles.textFieldNoLabel}
                  key={'tf' + lineToMeasure.id}
                  value={lengthInputValue === null ? this.formattedLength : lengthInputValue}
                  shouldFocusOnMount={!isTouchDevice}
                  shouldStayFocused={!isTouchDevice && inputFocus === InputType.Length}
                  shouldStaySelectedAll={!isTouchDevice && !lengthInputValue}
                  shouldSelectAllOnFocus={isTouchDevice}
                  inputProps={{ size: this.formattedLength.length + 1, enterkeyhint: 'done', inputmode: 'decimal' }}
                  onFocus={e => {
                    if (settingsStore.isTouchDevice) {
                      this.setState({ lengthInputValue: '' })
                    }
                  }}
                />

                {isTouchDevice && (
                  <IconButton className={styles.acceptButtonIcon} onClick={(e) => {
                    this.onLengthInputCommit();
                    this.onAngleInputCommit();
                    this.confirmDimension();
                  }}>
                    <AcceptIcon />
                  </IconButton>
                )}
              </div>
            </Portal>
          )}
        </g>

        {isEditMode && (
          <g className={'angleDimension'}>
            <line
              className={'default'}
              x1={lineToMeasure.points[0].x}
              y1={lineToMeasure.points[0].y}
              x2={angleLineX2}
              y2={lineToMeasure.points[0].y}
              markerEnd='url(#arrowheadEnd)'
            />
            <path
              className={'default'}
              fill="none"
              d={`M ${angleLineX2}, ${lineToMeasure.points[0].y} A${this.getLineLengthPixelsForAngle()},${this.getLineLengthPixelsForAngle()} 0 0,${angle > 0 ? 1 : 0} ${lineToMeasure.startPt.x + this.getLineLengthPixelsForAngle() * Math.cos(angle)},${lineToMeasure.startPt.y + this.getLineLengthPixelsForAngle() * Math.sin(angle)}`}
            />


            <Portal container={shapesStore.zoomController.secondObjectToZoom.querySelector('#fakeViewbox')}>
              <div className={'safariForeignContainer ' + styles.foreignContainer}
                style={{
                  transform: `scale(${1 / shapeScale}) translate(${angleMiddlePt.x - 200 / 2}px, ${angleMiddlePt.y - 60 / 2}px)`,
                  transformOrigin: `${angleMiddlePt.x}px ${angleMiddlePt.y}px`,
                  width: 200,
                  //height: 60,

                }}>
                <TextField
                  onTouchStartCapture={this.onTouchStart}
                  onKeyDown={this.onAngleInputKeyDown}
                  onChange={this.onAngleInputChange}
                  shouldStayFocused={inputFocus === InputType.Angle}
                  shouldStaySelectedAll={!isTouchDevice && !angleInputValue}
                  className={styles.textField + ' ' + styles.textFieldNoLabel}
                  key={'tfa' + lineToMeasure.id}
                  value={angleInputValue === null ? (-angle * 180 / Math.PI).toFixed(0) + '°' : angleInputValue}
                  inputProps={{ size: 5 }}
                />
              </div>
            </Portal>
          </g>
        )}

      </g>
    );
  }
}