import ObserverComponent from 'components/common/ObserverComponent';
import { DrawToolType } from 'constants/DrawToolType';
import BackgroundImage from 'models/BackgroundImage';
import Point from 'models/Point';
import { Rectangle } from 'models/Rectangle';
import * as React from 'react';
import * as Rematrix from 'rematrix';

const styles = require('./BackgroundImageComponent.module.scss');

interface BackgroundImageComponentProps {
  backgroundImage: BackgroundImage;
  onLoad?: () => void;
  isSvg?: boolean;
  previewWidth?: number,
  shouldShow?: boolean, // needs the bounding box, but doesn't need to show the actual image
}

export default class BackgroundImageComponent extends ObserverComponent<BackgroundImageComponentProps> {
  static defaultProps = {
    shouldShow: true
  }

  _render() {
    const { drawToolsStore } = this.context;
    const { backgroundImage, isSvg, className, onLoad, previewWidth, shouldShow } = this.props;
    const { rotation, imageUrl } = backgroundImage;

    const cropRectangle = (
      (!isSvg || drawToolsStore.selectedTool !== DrawToolType.BackgroundImageCrop) && backgroundImage.cropRectangle ||
      new Rectangle(
        this.context,
        new Point(0, 0),
        ((rotation / 90) % 2 === 0)
          ? new Point(backgroundImage.width, backgroundImage.height)
          : new Point(backgroundImage.height, backgroundImage.width)
      )
    );

    const previewScale = (previewWidth && !isSvg) ? previewWidth / cropRectangle.width : 1;

    let downsampleScale = 1;
    let transform = Rematrix.format([1, 0, 0, 1, 0, 0])
    // Punting.... but would be good to downsample when plan scale is too huge (ex. covering large building or sets of building)
    if (false) {// maxDimension > 5000) {
      downsampleScale = 0.5;

      transform = [
        //Rematrix.inverse(imageTranslateMat),
        Rematrix.translate(backgroundImage.width / 2, backgroundImage.height / 2),
        Rematrix.scale(1 / downsampleScale),
        Rematrix.translate(-backgroundImage.width / 2, -backgroundImage.height / 2),
        //imageTranslateMat,
      ].reduce(Rematrix.multiply);
    }

    const imageTransformOrigin = (rotation === 0 || rotation === 180)
      ? 'center'
      : 'top left';

    let imageTranslate = '';
    if (rotation === 90) {
      imageTranslate = `translateY(${-backgroundImage.height * previewScale}px)`;
    } else if (rotation === 270) {
      imageTranslate = `translateX(${-backgroundImage.width * previewScale}px)`;
    }

    let imageTranslateMat = Rematrix.format([1, 0, 0, 1, 0, 0]);

    if (rotation === 90) {
      imageTranslateMat = Rematrix.translateY(-backgroundImage.height * previewScale);
    } else if (rotation === 270) {
      imageTranslateMat = Rematrix.translateX(-backgroundImage.width * previewScale);
    }

    const maxDimension = Math.max(backgroundImage.width, backgroundImage.height);

    transform = [
      Rematrix.rotate(rotation),
      imageTranslateMat,
      transform,
    ].reduce(Rematrix.multiply)

    return isSvg
      ? (
        <svg
          className={styles.root}
          style={{
            pointerEvents: 'none',
          }}
          width={cropRectangle.width}
          height={cropRectangle.height}
          x={cropRectangle.topLeft.x}
          y={cropRectangle.topLeft.y}
          viewBox={`${cropRectangle.topLeft.x} ${cropRectangle.topLeft.y} ${cropRectangle.width} ${cropRectangle.height}`}
        >
          {shouldShow && (
            <image
              id="BackgroundImage"
              href={imageUrl}
              style={{
                //transform: `rotate(${rotation}deg) ${imageTranslate}`,
                transform: Rematrix.toString(transform),
                transformOrigin: imageTransformOrigin,
              }}
              width={backgroundImage.width * downsampleScale}
              height={backgroundImage.height * downsampleScale}
            />
          )}
          {/* for proper getBBox calculations, doesn't work without it */}
          <rect
            x={cropRectangle.topLeft.x}
            y={cropRectangle.topLeft.y}
            width={cropRectangle.width}
            height={cropRectangle.height}
            stroke="none"
            fill="none"
          />
        </svg>
      ) : (
        <div
          className={styles.root}
          style={{
            pointerEvents: 'none',
            width: cropRectangle.width * previewScale,
            height: cropRectangle.height * previewScale
          }}
        >
          <div style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}>
            <img
              src={shouldShow ? imageUrl : ''}
              style={{
                transform: Rematrix.toString(transform),
                transformOrigin: imageTransformOrigin,
                top: -cropRectangle.topLeft.y * previewScale,
                left: -cropRectangle.topLeft.x * previewScale,
                width: backgroundImage.width * previewScale * downsampleScale,
                height: backgroundImage.height * previewScale * downsampleScale
              }}
              onLoad={() => onLoad?.()} />
          </div>
        </div>
      )
  }
}