import { Loader } from '@googlemaps/js-api-loader';
import { Checkbox, FormControlLabel, IconButton, LinearProgress, Slider, Switch, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import NameIcon from '@material-ui/icons/LabelOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import MarkerIcon from '@material-ui/icons/Room';
import RotateIcon from '@material-ui/icons/Rotate90DegreesCcw';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import Globals from 'Globals';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import BackgroundImageComponent from 'components/drawing/BackgroundImageComponent/BackgroundImageComponent';
import { AddBackgroundImageTabs } from 'constants/AddBackgroundImageTabs';
import { DrawToolType } from 'constants/DrawToolType';
import firebase from 'firebase/compat/app';
import { isEmpty, throttle } from 'lodash';
import BackgroundImage from 'models/BackgroundImage';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { Controlled as Zoom } from 'react-medium-image-zoom';
import { askToDeleteItem, askToDeleteItems } from 'utils/DeleteUtils';
import mergeImages from 'utils/ImageUtil';
import i18n from 'utils/i18n';
import uuidv4 from 'uuid/v4';
import ConfirmDialog, { IConfirmDialogProps } from '../ConfirmDialog/ConfirmDialog';
import Editable from '../Editable/Editable';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import SearchToolbar from '../SearchToolbar/SearchToolbar';
import TextField from '../TextField/TextField';

// TODO: Split in 2 components for satellite vs upload

const styles = require('./AddBackgroundImageDialog.module.scss');

const colors = require('../../../Colors.scss');

interface AddBackgroundImageDialogState {
  address: string,
  selectedTab: AddBackgroundImageTabs,
  isUploading: boolean,
  isMerging: boolean,
  progress: number,
  selectedBackgroundImages: Set<BackgroundImage>,
  imageIdBeingZoomed: string,
  canSelectMultiplePages: boolean
}

interface AddBackgroundImageDialogProps extends IConfirmDialogProps {
  treeNodeCopy: TreeNode,
}

export default class AddBackgroundImageDialog extends ObserverComponent<AddBackgroundImageDialogProps, AddBackgroundImageDialogState> {
  state = {
    address: '',
    selectedTab: null,
    isUploading: false,
    isMerging: false,
    progress: 0,
    // could use backgroundImagesStore.selectedItems instead
    selectedBackgroundImages: new Set<BackgroundImage>(),
    imageIdBeingZoomed: null,
    canSelectMultiplePages: false
  };

  uploader;
  autocomplete;
  locationTextField;

  get selectedBackgroundImage() {
    return [...this.state.selectedBackgroundImages][0] || null;
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  getImageSize = (imageUrl): Promise<{ width: number, height: number }> => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = () => reject();
      img.src = imageUrl;
    });
  }

  handleUploadSuccess = async (filename: string, task: any) => {
    const { backgroundImagesStore } = this.context;
    this.setState({ isUploading: false });

    const imageUrl = await task.snapshot.ref.getDownloadURL();
    const imageSize = await this.getImageSize(imageUrl);
    const newBackgroundImage = new BackgroundImage(this.context);
    newBackgroundImage.parentDocumentName = filename;
    newBackgroundImage.imageUrl = imageUrl;
    newBackgroundImage.unscaledWidth = imageSize.width;
    newBackgroundImage.unscaledHeight = imageSize.height;
    backgroundImagesStore.addEditItem(newBackgroundImage);

    if (!this.selectedBackgroundImage) {
      this.setState({ selectedBackgroundImages: new Set([newBackgroundImage]) });
    }
  };

  componentDidMount() {
    const { backgroundImagesStore } = this.context;

    const { backgroundImage } = this.props.treeNodeCopy;
    if (backgroundImage) {
      this.setState({ selectedBackgroundImages: new Set([backgroundImage]) });
    }

    // should go to store instead
    const loader = new Loader({
      apiKey: "AIzaSyBOK0SjoPPxFKicRgNLodSalFQtHOaorSI",
      libraries: ['places']
    });

    loader
      .load()
      .then(() => {
        const { treeNodeCopy } = this.props;

        const options = {
          componentRestrictions: { country: ['ca', /*'us',*/ 'mx'] },
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
          types: ["geocode"],
        } as google.maps.places.AutocompleteOptions;

        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById("pac-input"),
          options
        );

        this.autocomplete.addListener("place_changed", () => {
          const place = this.autocomplete.getPlace();
          if (!place?.geometry?.location) {
            return;
          }

          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          this.setState({ address: place.formatted_address });
          treeNodeCopy.satelliteImageAddress = place.formatted_address;
          treeNodeCopy.satelliteImageCoords.lat = lat;
          treeNodeCopy.satelliteImageCoords.lng = lng;
          treeNodeCopy.satelliteImageUrl =
            `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=20&scale=2&size=640x640&maptype=satellite&markers=color:white%7C${lat},${lng}&key=AIzaSyBOK0SjoPPxFKicRgNLodSalFQtHOaorSI`;
        })

      })
      .catch(e => {
        console.error(e);
        // do something
      });
  }

  mergeSelectedImages = async () => {
    const { backgroundImagesStore } = this.context;
    const { selectedBackgroundImages } = this.state;

    const selectedBackgroundImagesArray = [...selectedBackgroundImages];

    const numCols = Math.round(Math.sqrt(selectedBackgroundImages.size));

    const newBackgroundImage = new BackgroundImage(this.context);
    const firstSelectedImage = selectedBackgroundImagesArray[0];

    const maxWidth = Math.max(...selectedBackgroundImagesArray.map(bgImg => Math.max(bgImg.unscaledWidth, bgImg.unscaledHeight)));
    // resulting image 5200px wide or less
    const scale = Math.min(1, 7200 / (maxWidth * numCols));

    let imageX = 0;
    let imageY = 0;
    let tallestImageUnscaledHeightForRow = 0;
    let finalImageWidth = 0;

    const imagesToMerge = Array.from(selectedBackgroundImages).map((backgroundImage, index) => {
      const retval = { src: backgroundImage.imageUrl, x: imageX, y: imageY, rotationDegrees: backgroundImage.rotation };

      // not last column or last image
      if (index % numCols !== numCols - 1 && index !== selectedBackgroundImages.size - 1) {
        // not the same scale as the background image architectural scale
        // here it's a scale to ensure image doesn't get too big
        imageX += backgroundImage.unscaledWidth * scale;
        tallestImageUnscaledHeightForRow = Math.max(tallestImageUnscaledHeightForRow, backgroundImage.unscaledHeight);
      } else {
        tallestImageUnscaledHeightForRow = Math.max(tallestImageUnscaledHeightForRow, backgroundImage.unscaledHeight);
        finalImageWidth = Math.max(finalImageWidth, imageX + backgroundImage.unscaledWidth * scale)
        imageX = 0;
        imageY += tallestImageUnscaledHeightForRow * scale;
        tallestImageUnscaledHeightForRow = 0;
      }

      return retval;
    })

    const newImageDataUrl = await mergeImages(
      imagesToMerge,
      {
        scale: scale,
        width: finalImageWidth,
        height: imageY,
        crossOrigin: 'anonymous',
        //quality: 0.85,
        format: 'image/jpeg'
      }
    );

    // TODO!
    newBackgroundImage.index = Math.min(...selectedBackgroundImagesArray.map(bgImg => bgImg.index)) - 0.01; // lazy but should work
    newBackgroundImage.initialViewportScale = firstSelectedImage.initialViewportScale * scale;
    newBackgroundImage.parentDocumentName = firstSelectedImage.parentDocumentName;
    newBackgroundImage.unscaledWidth = finalImageWidth; // unscaled in app, but not from original file
    newBackgroundImage.unscaledHeight = imageY;

    const storageRef = firebase.storage()
      .ref(`images/${backgroundImagesStore.dbLocation}`)
      .child(newBackgroundImage.id)
      .child(newBackgroundImage.parentDocumentName.replace('.pdf', `mergedimage.jpg`));

    await storageRef.putString(newImageDataUrl, 'data_url');

    newBackgroundImage.imageUrl = await storageRef.getDownloadURL();

    backgroundImagesStore.addEditItem(newBackgroundImage);

    await this.setState({ selectedBackgroundImages: new Set([newBackgroundImage]) });

    return newBackgroundImage;
  }

  onConfirm = async () => {
    const { treeNodesStore, drawToolsStore, shapesStore } = this.context;
    const { treeNodeCopy } = this.props;
    const { selectedBackgroundImages } = this.state;

    treeNodeCopy.backgroundImage = this.selectedBackgroundImage;

    if (selectedBackgroundImages.size > 1) {
      this.setState({ isMerging: true });
      const mergedBackgroundImage = await this.mergeSelectedImages();
      this.setState({ isMerging: false });

      treeNodeCopy.backgroundImage = mergedBackgroundImage;
    }

    treeNodesStore.addEditItem(treeNodeCopy, true, ['satelliteImageUrl', 'satelliteImageAddress', 'satelliteImageCoords', 'satelliteImageRotation', 'backgroundImageId']);
    drawToolsStore.selectedTool = !this.selectedBackgroundImage || this.selectedBackgroundImage.isScaleSet
      ? DrawToolType.Select
      : DrawToolType.BackgroundImageSetScale;

    shapesStore.zoomController.zoomCompletelyOut();
  }

  handleClearSatelliteAddress = () => {
    const { treeNodeCopy } = this.props;

    this.autocomplete.set('place', null);
    this.setState({ address: '' });
    treeNodeCopy.satelliteImageUrl = '';
    treeNodeCopy.satelliteImageAddress = '';
    treeNodeCopy.satelliteImageCoords = { lat: 0, lng: 0 };
  }

  // on Upload file change
  onChange = (event) => {
    const file: File = event.target.files[0];

    if (file.name.toLowerCase().endsWith('pdf')) {
      this.makeLocalPdfThumbs(file);
      return; // could comment out to upload pdf and convert to svg too
    }

    this.uploader.startUpload(file);
  }

  rotateImage = (backgroundImage: BackgroundImage) => {
    const { backgroundImagesStore } = this.context;
    backgroundImage.rotation = (backgroundImage.rotation + 90) % 360;
    backgroundImagesStore.addEditItemDebounced(backgroundImage, true, ['rotation']);
  }

  onSelectBackgroundImage = (backgroundImage: BackgroundImage) => (event) => {
    if (
      event.target.ariaLabel?.includes?.('zoom') ||
      event.target.type !== 'checkbox' && event.target.closest('.imageButtons')
    ) {
      return false;
    }

    let { selectedBackgroundImages, canSelectMultiplePages } = this.state;

    if (selectedBackgroundImages.has(backgroundImage)) {
      selectedBackgroundImages.delete(backgroundImage);
    } else if (canSelectMultiplePages) {
      selectedBackgroundImages.add(backgroundImage);
    } else {
      selectedBackgroundImages = new Set<BackgroundImage>([backgroundImage])
    }
    this.setState({ selectedBackgroundImages });
  }

  makeLocalPdfThumbs = async (file: File) => {
    const { projectsStore, backgroundImagesStore } = this.context;
    const PDFJS = require('pdfjs-dist/webpack');

    this.setState({ isUploading: true, progress: 0 });

    const fileReader = new FileReader();
    fileReader.onload = (ev) => {
      PDFJS.getDocument(fileReader.result).promise.then(
        async (pdf) => {
          //
          // Fetch the first page
          //
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);

            this.setState({ progress: ((i - 1) * 3) / (pdf.numPages * 3) * 100 });

            var scale = 1;
            var viewport = page.getViewport({ scale });

            // weirdly this still creates images bigger than 4000 px wide
            var desiredScale = Math.ceil(4000 / viewport.width);
            viewport = page.getViewport({ scale: desiredScale });

            //
            // Prepare canvas using PDF page dimensions
            //
            var canvas = document.getElementById('the-canvas') as HTMLCanvasElement;
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            //
            // Render PDF page into canvas context
            //
            const newBackgroundImage = new BackgroundImage(this.context);

            var task = page.render({ canvasContext: context, viewport: viewport })
            const storageRef = firebase.storage()
              .ref(`images/${backgroundImagesStore.dbLocation}`)
              .child(newBackgroundImage.id)
              .child(file.name.replace('.pdf', `pdfjsthumb_${i}.jpg`));

            await task.promise;

            this.setState({ progress: ((i - 1) * 3 + 1) / (pdf.numPages * 3) * 100 });

            await storageRef.putString(canvas.toDataURL('image/jpeg'), 'data_url');

            newBackgroundImage.index = i;
            newBackgroundImage.initialViewportScale = desiredScale;
            newBackgroundImage.parentDocumentName = file.name;
            newBackgroundImage.imageUrl = await storageRef.getDownloadURL();
            newBackgroundImage.unscaledWidth = viewport.width; // unscaled in app, but not from original file
            newBackgroundImage.unscaledHeight = viewport.height;
            backgroundImagesStore.addEditItem(newBackgroundImage);

            this.setState({ progress: ((i - 1) * 3 + 2) / (pdf.numPages * 3) * 100 });

            if (i === pdf.numPages) {
              this.setState({ isUploading: false });
            }
          }
        }, function (error) {
          console.log(error);
        });
    };
    fileReader.readAsArrayBuffer(file);
  }

  onTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  }

  onRotationChange = throttle(
    (event, newValue: number) => {
      const { treeNodeCopy } = this.props;
      treeNodeCopy.satelliteImageRotation = -newValue;
    }, 40)

  _render() {
    const { projectsStore, backgroundImagesStore, settingsStore } = this.context;
    const { treeNodesStore: projectTreeNodesStore } = Globals.defaultStores;
    const { open, dialogId, treeNodeCopy } = this.props;
    let { address, selectedTab, isUploading, isMerging, progress, imageIdBeingZoomed, selectedBackgroundImages, canSelectMultiplePages } = this.state;
    const { satelliteImageUrl, satelliteImageRotation, satelliteImageAddress } = treeNodeCopy;

    selectedTab = selectedTab || satelliteImageUrl && AddBackgroundImageTabs.SATELLITE || AddBackgroundImageTabs.UPLOAD;

    const selectionCount = (
      selectedTab === AddBackgroundImageTabs.SATELLITE && satelliteImageUrl && 1 ||
      selectedTab === AddBackgroundImageTabs.UPLOAD && Array.from(selectedBackgroundImages).filter(bgImg => bgImg && backgroundImagesStore.getItem(bgImg.id)).length ||
      0
    );

    return (
      <ConfirmDialog
        dialogId={dialogId}
        open={open}
        title={i18n.t('Add a Background Image')}
        onConfirm={this.onConfirm}
        yesLabel={isUploading
          ? i18n.t('Uploading...')
          : (selectionCount ? i18n.t('Continue with selected image', { count: selectionCount }) : i18n.t('Continue without a background image'))
        }
        actionButtonColor={selectionCount ? colors.action : colors.primary}
        isActionButtonDisabled={isUploading || isMerging}
        noLabel={i18n.t('Cancel')}
        extraButtons={
          selectedTab === AddBackgroundImageTabs.UPLOAD && !isEmpty(backgroundImagesStore.itemsByDocumentName) && (
            <FormControlLabel
              className={styles.canSelectMultiplePages}
              control={
                <Switch
                  checked={canSelectMultiplePages}
                  onChange={(event, checked) => {
                    this.setState({
                      canSelectMultiplePages: checked,
                    });

                    if (!checked && this.selectedBackgroundImage) {
                      this.setState({
                        selectedBackgroundImages: new Set([this.selectedBackgroundImage])
                      });
                    }
                  }}
                />
              }
              label={<Tooltip title={i18n.t('We do not recommend using this for pages in plan view, but only for elevation views. Otherwise, it will be hard list materials per floor which is often needed.')}><span className={styles.text}>{i18n.t('Select multiple pages (of same scale)')}</span></Tooltip>}
            />
          )}
        content={
          <div className={classnames(styles.root, { [styles.isSatelliteTabSelected]: selectedTab === AddBackgroundImageTabs.SATELLITE })}>
            <LoadingOverlay isVisible={isMerging} message={i18n.t('Merging...')} />
            <Tabs
              className={styles.tabs}
              value={selectedTab}
              onChange={this.onTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab value={AddBackgroundImageTabs.UPLOAD} icon={<UploadIcon />} className={styles.tab} label={i18n.t('Plans')} />
              <Tab value={AddBackgroundImageTabs.SATELLITE} icon={<LanguageIcon />} className={styles.tab} label={i18n.t('Satellite Imagery')} />
            </Tabs>

            <div className={styles.tabContent} hidden={selectedTab !== AddBackgroundImageTabs.SATELLITE}>
              <div className={styles.subtitle}>
                {i18n.t('Enter an address to use satellite imagery for measurements takeoff.')}
              </div>

              <div className={styles.autocomplete}>
                <MarkerIcon />

                <form autocomplete="off" onSubmit={e => e.preventDefault()}>
                  <TextField
                    className={styles.addressField}
                    shouldFocusOnMount // doesnt work
                    label={i18n.t('Location')}
                    inputProps={{ id: 'pac-input', placeholder: i18n.t('Enter a location'), autocomplete: 'false' }}
                    InputProps={{
                      endAdornment: satelliteImageUrl && (
                        <IconButton className={styles.clearIcon} onClick={this.handleClearSatelliteAddress}>
                          <ClearIcon />
                        </IconButton>
                      )
                    }}
                    onChange={event => this.setState({ address: event.target.value })}
                    value={address || satelliteImageAddress}
                  />
                </form>
              </div>
              <div
                className={styles.satelliteImage}
              >
                {!satelliteImageUrl && <ImageIcon />}
                {satelliteImageUrl && (
                  <>
                    <img src={satelliteImageUrl} style={{ transform: `rotate(${satelliteImageRotation}deg`, willChange: 'transform' }} />
                    <div className={styles.grid} />
                  </>
                )}
              </div>
              <div className={styles.subtitle}>
                {i18n.t('Rotation')}
              </div>
              <Slider value={-satelliteImageRotation} onChange={this.onRotationChange} min={-60} max={60} step={0.1} />
            </div>

            <div className={styles.tabContent} hidden={selectedTab !== AddBackgroundImageTabs.UPLOAD}>
              <canvas id="the-canvas" style={{ display: 'none' }}></canvas>

              <form className={styles.upload}>
                <label> {/* has to be a label for FileUploader to work for some reason*/}
                  {!isUploading && (
                    <div className={styles.uploadButton}>
                      <div className={'MuiButtonBase-root MuiButton-root MuiButton-text ' + styles.button}>
                        <UploadIcon />
                        {i18n.t('Add an image or PDF')}
                      </div>
                    </div>
                  )}
                  {isUploading && (
                    <LinearProgress className={styles.progressBar} variant={progress ? 'determinate' : 'indeterminate'} value={progress} />
                  )}

                  <FileUploader
                    ref={ref => this.uploader = ref}
                    style={{}}
                    accept="image/*,application/pdf"
                    name="avatar"
                    // should keep original name
                    filename={file => file.name}
                    storageRef={firebase.storage().ref(`images/${projectsStore.dbLocation}/${projectsStore.selectedProjectId}/backgroundImage_${uuidv4()}`)}
                    onChange={this.onChange}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    hidden
                  />

                </label>
              </form>

              {!isEmpty(backgroundImagesStore.items) && (
                <SearchToolbar
                  className={styles.searchToolbar}
                  store={backgroundImagesStore}
                  shouldFocusOnMount={!settingsStore.isTouchDevice}
                />
              )}

              <div className={styles.existingImages}>

                {Object.keys(backgroundImagesStore.itemsByDocumentName).map((parentDocumentName, index) => (
                  <div key={parentDocumentName + index}>
                    <div className={styles.documentName}>
                      <Typography variant="subtitle2">{parentDocumentName}</Typography>
                      <IconButton onClick={(event) => {
                        const itemsToDelete = backgroundImagesStore.items.filter(i => i.parentDocumentName === parentDocumentName);

                        askToDeleteItems(
                          itemsToDelete,
                          backgroundImagesStore,
                          () => {
                            backgroundImagesStore.items.forEach(item => {
                              selectedBackgroundImages.delete(item);
                            });
                            backgroundImagesStore.deleteItems(itemsToDelete.map(item => item.id));
                            // backgroundImagesStore.selectedItems.clear();
                          });
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <div className={styles.imagesList}>
                      {backgroundImagesStore.itemsByDocumentName[parentDocumentName].map(backgroundImage => (
                        <div
                          className={classnames(styles.logoImageContainer, { [styles.isSelected]: selectedBackgroundImages.has(backgroundImage) })}
                          onClick={this.onSelectBackgroundImage(backgroundImage)}
                          key={backgroundImage.id}
                          id={`bgimage-${backgroundImage.id}`}
                        >
                          <Zoom
                            isZoomed={imageIdBeingZoomed === backgroundImage.id}
                            onZoomChange={isZoomed => {
                              if (!isZoomed) {
                                this.setState({ imageIdBeingZoomed: null })
                              }
                            }}>
                            <BackgroundImageComponent
                              backgroundImage={backgroundImage}
                              previewWidth={backgroundImage.isLandscape ? 480 : 270}
                            />
                          </Zoom>
                          <div className={styles.imageButtons + ' imageButtons'}>
                            <Tooltip title={i18n.t('Name this page')} open={backgroundImage.name ? false : undefined}>
                              <div className={classnames(styles.name, { [styles.isEmpty]: !backgroundImage.name })}>
                                <Editable
                                  displayComponent={<div className={styles.nameDisplayComponent}>{backgroundImage.name ? backgroundImage.name : <NameIcon />}</div>}
                                  model={backgroundImage}
                                  propertyName='name'
                                  store={backgroundImagesStore}
                                />
                              </div>
                            </Tooltip>

                            <IconButton onClick={(event) => {
                              this.setState({ imageIdBeingZoomed: backgroundImage.id });
                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                              <ZoomIcon />
                            </IconButton>
                            <IconButton onClick={(event) => {
                              askToDeleteItem(
                                backgroundImage,
                                backgroundImagesStore,
                                () => {

                                  selectedBackgroundImages.delete(backgroundImage);
                                  backgroundImagesStore.deleteItem(backgroundImage.id);
                                  //backgroundImagesStore.selectedItems.clear();
                                });

                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                              <DeleteIcon />
                            </IconButton>
                            {!backgroundImage.cropRectangle && (
                              <IconButton onClick={(event) => {
                                this.rotateImage(backgroundImage);
                                event.preventDefault();
                                event.stopPropagation();
                              }}>
                                <RotateIcon />
                              </IconButton>
                            )}
                            <Checkbox checked={selectedBackgroundImages.has(backgroundImage)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ div>
        }
      />
    );
  }
}