import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { SaveOptions } from '@progress/kendo-file-saver';
import PageTemplate from 'components/reports/PageTemplate/PageTemplate';
import { DbLocationType } from 'constants/DbLocationType';
import { ReportSubtypes } from 'constants/ReportOptionsConstants';
import { ReportTabs } from 'constants/ReportTabs';
import { first } from 'lodash';
import { action, computed, observable } from 'mobx';
import Report from 'models/Report';
import * as ReactDOM from 'react-dom';
import FirebaseStore from 'stores/FirebaseStore';
import { firestore } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import KendoDrawingAdapter from 'utils/KendoDrawingAdapter';
import { PDFExportProps } from 'utils/PDFExportProps';
import { b64toBlob } from 'utils/ReportUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { getSafe } from 'utils/Utils';

const { Estimate, Invoice } = ReportSubtypes;

const DEFAULT_KEY = 'DEFAULT';

export default class ReportsStore extends FirebaseStore<Report> {
  storeKey = 'reports';

  dbLocationsTypes = new Set([
    DbLocationType.User,
    DbLocationType.Project
  ]);

  shouldKeepUserItems = true;

  @observable isGeneratingExcel = false;

  @observable printablePageRef = HTMLDivElement;

  @observable reportGenerationProgress = 0;
  cancelReport = null;
  @observable pdfUrl;

  @observable selectedItem: Report;

  @observable selectedTab = ReportTabs.DESIGN;

  // alias because currently only one report, even if someday we might allow more
  @computed get report() {
    return this.selectedItem;
  }

  set report(report) {
    this.selectedItem = report;
  }

  // not per project
  @computed get userReport(): Report {
    return first(Array.from(this.userCollectionItemsMap.values())) || this.selectedItem;
  }
  public onLoadCompleted() {
    let report = this.getItem(DEFAULT_KEY);
    // double check that really report isnt in db, can't afford to be a connection error
    // or we will overwrite default report

    if (!report || !report.cascadeOrders.has(this.collections.length - 1)) {
      this.projectCollection.doc(DEFAULT_KEY).get({ source: 'server' })
        .then(snapshot => {
          if (!snapshot.exists) {
            this.onLoadCompletedAndVerified();
          } else {
            debugger; // bad!
            window.location.reload();
          }
        }).catch(error => {
          if (navigator.onLine) {
            debugger;
            this.stores.commonStore.error = error.message;
            throw (error);
          }
        })
    } else {
      this.onLoadCompletedAndVerified();
    }
  }

  @action
  onChangeSelectedTab = (event, selectedTab) => {
    if (this.cancelReport) {
      this.cancelReport();
    }

    this.selectedTab = selectedTab;
    this.pdfUrl = '',
      this.reportGenerationProgress = 0,
      this.cancelReport = null,

      setTimeout(() => {
        const { projectsStore, reportsStore } = this.stores;
        const { selectedProject } = projectsStore;

        const pageComponent = ReactDOM.findDOMNode(this.printablePageRef);

        // glitch because not always defined (about 5% of times)
        if (!pageComponent && selectedTab === ReportTabs.PDF) {
          debugger;
          console.error('bug pageComponent undefined')
        }
        if (pageComponent && selectedTab === ReportTabs.PDF) {
          this.savePDF(ReactDOM.findDOMNode(this.printablePageRef), {
            // Any option added here, must be copied in KendoDrawingAdapter
            repeatHeaders: true,
            keepTogether: '.keep-together',
            forcePageBreak: '.force-page-break',
            paperSize: 'Letter',
            margin: { left: '0cm', top: '3cm', right: '0cm', bottom: '2.3cm' },
            pageTemplate: PageTemplate,
            title: selectedProject.name + ' - Evalumo',
            scale: 0.5,
            imageResolution: 300,
            progress: ({ pageNum, totalPages, cancel }) => {
              this.reportGenerationProgress = Math.round(pageNum / totalPages * 100);
              this.cancelReport = cancel;
            },
            shouldShowTasks: reportsStore.selectedItem?.shouldShowTasks // custom
          });
        }
      }, 1200) // enough time for drawings to resize
  };

  download = () => {
    const { pdfUrl } = this;
    if (!pdfUrl) {
      return;
    }
    const { projectsStore, reportsStore } = this.stores;

    const projectName = getSafe(() => projectsStore.selectedProject.name) || '';
    const filename = `${projectName} - ${i18n.t(reportsStore.report.subtype)}.pdf`;

    const pdfjsWindow = document.getElementById('mobile-pdf-viewer-iframe')?.contentWindow;
    const pdfjsDownloadButton = getSafe(() => pdfjsWindow.document.getElementById('downloadButton'));

    if (pdfjsDownloadButton) {
      pdfjsDownloadButton.click();
    } else {
      var link = document.createElement('a');
      link.href = pdfUrl;
      link.download = filename;
      link.click();
    }

    trackInteraction(TrackedInteractions.DownloadOrSendReport);
  }


  saveToBlob = (dataUri: string, fileName: string, options: SaveOptions) => {
    const b64 = dataUri.replace('data:application/pdf;base64,', '');
    // could improve download button file name with
    // https://stackoverflow.com/questions/53548182/can-i-set-the-filename-of-a-pdf-object-displayed-in-chrome
    const newUri = URL.createObjectURL(b64toBlob(b64, 'application/pdf'));

    this.pdfUrl = newUri;
  }

  savePDF = (domElement, options: PDFExportProps) => {
    /*defineFont({
      "Montserrat|Medium":
        "https://app.evalumo.com/fonts/Montserrat-Medium.ttf",
    });*/

    new KendoDrawingAdapter(drawDOM, exportPDF, this.saveToBlob, domElement, options).savePDF(() => { ; });
  }



  DEBUG_applyReportsToAllProjects = async (key: keyof Report, value: any) => {
    const { projectsStore, settingsStore, userInfoStore } = this.stores;

    if (!key || !value) {
      return;
    }

    const db = firestore();
    const batch = firestoreBatch(this.stores);

    const projectIds = projectsStore.items.map(i => i.id);

    await projectIds.reduce(async (previousTask, projectId) => {
      const projectsSettingsDocs = await (await db.collection(`/users/${userInfoStore.userEmail}/projects/${projectId}/reports`).get()).docs.map(doc => doc.id);

      projectsSettingsDocs.forEach(settingsId => {
        batch.set(db.doc(`/users/${userInfoStore.userEmail}/projects/${projectId}/reports/${settingsId}`), { [key]: value }, { merge: true });
      })

      return '';
    }, Promise.resolve(''));

    debugger;
    batch.commit();
  }

  public onLoadCompletedAndVerified() {
    let report = this.getItem(DEFAULT_KEY);

    // ensure project && user cascade object
    if (!report || !report.cascadeOrders.has(this.collections.length - 1)) {
      report = report || new Report(this.stores, DEFAULT_KEY, Estimate, i18n.t('Document 1'));
      report.clientName = report.clientAddress = report.number = report.description = '';

      const batch = firestoreBatch(this.stores);
      batch.isUndoable = false;
      this.addEditItem(report, true, undefined, batch);
      batch.commit();
    }

    this.selectedItem = first(this.items);

    // hack, find a way to have proper user settings for reports
    this.selectedItem.shouldShowProvidingItemsIcons = this.userReport.shouldShowProvidingItemsIcons;

    super.onLoadCompleted();
  }
}
