import Point from "models/Point";
import { isSafari } from './Utils';

export function cursorToLocalPoint(e: { pageX: number, pageY: number }, svgDoc: SVGSVGElement): Point {
  return screenToLocalPoint(e.pageX, e.pageY, svgDoc);
}

export function screenToLocalPoint(pointX, pointY, svgDoc: SVGSVGElement): Point {
  const pt = svgDoc.createSVGPoint();
  pt.x = pointX;
  pt.y = pointY;

  const scale = parseFloat(svgDoc.style.transform.match(/matrix3?d?\(([^,]+),/)[1]);
  let matrix = svgDoc.getScreenCTM();

  // safari bad getScreenCTM
  if (isSafari() && scale) {
    matrix = matrix.scale(scale);
  }

  const svgPt = pt.matrixTransform(matrix.inverse());
  const simplePt = new Point(svgPt.x, svgPt.y);
  return simplePt;
}

export function localToScreenPoint(localPt: Point, svgDoc: SVGSVGElement): Point {
  const pt = svgDoc.createSVGPoint();
  pt.x = localPt.x;
  pt.y = localPt.y;

  const screenPt = pt.matrixTransform(svgDoc.getScreenCTM());
  return new Point(screenPt.x, screenPt.y);
}

export function isMouseInsideComponent(e: MouseEvent, component: HTMLElement | SVGSVGElement): boolean {
  if (!component) {
    return false;
  }
  
  const rect = component.getBoundingClientRect();

  const fingerOffsetX = e?.fingerOffsetX || 0;
  const fingerOffsetY = e?.fingerOffsetY || 0;

  return !e || !(
    e.pageX - fingerOffsetX < rect.left ||
    e.pageX - fingerOffsetX > rect.right ||
    e.pageY - fingerOffsetY < rect.top ||
    e.pageY - fingerOffsetY > rect.bottom
  );
}
