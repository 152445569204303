import { DbLocationType } from 'constants/DbLocationType';
import { compact, orderBy } from 'lodash';
import { computed, observable } from 'mobx';
import BackgroundImageScale from 'models/BackgroundImageScale';
import Line from 'models/Line';
import Point from 'models/Point';
import { Rectangle } from 'models/Rectangle';
import Shape from 'models/Shape';
import Surface from 'models/Surface';
import FirebaseStore from 'stores/FirebaseStore';
import { SZoom } from 'utils/seriouszoom';
import { getSafe } from 'utils/Utils';

export default class ShapesStore extends FirebaseStore<Shape>   {
  storeKey = 'shapes';

  dbLocationsTypes = new Set([
    DbLocationType.MasterProject, 
    DbLocationType.Project
  ]);


  // hack to make sure surface is under
  sortField = 'type';

  /*@observable */ viewBoxRectangle: Rectangle = null;

  // scale isn't return in the shapes array
  @observable backgroundImageScaleShape: BackgroundImageScale = null;
  @observable shouldShowBackgroundImageScaleInput = false;

  @observable backgroundImageCropRectangle: Rectangle = null;
  @observable isCroppingBackgroundImageSecondPoint = false;
  @observable isConfirmingBackgroundImageCrop = false;

  @observable zoomController: SZoom; // hack
  @computed get isDragPanningOnDrawing() {
    return getSafe(() => this.zoomController.isDragPanning);
  }

  @computed get sortedShapes(): Shape[] {
    const { treeNodesStore } = this.stores;
    return orderBy(
      // prevents showing shapes when node has been deleted, but shouldnt be needed if everything was working correctly
      this.items.filter(shape => treeNodesStore.getNodeForShape(shape)),
      ['index', 'isSelected']
    );
  }

  attemptLoadItems(shouldForce?: boolean): void {
    if (this.stores.routerStore.isRescueMode) {
      this.onLoadCompleted();
    } else {
      super.attemptLoadItems(shouldForce);
    }
  }

  addEditItemsImpl(items: Shape[], shouldSaveToDb = true, fieldsToUpdate: (keyof Shape)[] = null, batchParam: WriteBatch = null) {
    const {routerStore} = this.stores;
    if (routerStore.isRescueMode) {
      return null;
    }

    return super.addEditItemsImpl(items, shouldSaveToDb, fieldsToUpdate, batchParam);
  }

  public onLoadCompleted() {
    // refresh tree nodes selection
    // const { treeNodesStore } = this.stores;
    // treeNodesStore.selectedTreeNodes = [...treeNodesStore.selectedTreeNodes];
    super.onLoadCompleted();
  }

  getItemsImpl(): Shape[] {
    return super.getItemsImpl()
      // ?????
      .sort((shapeA, shapeB) => shapeA[this.sortField] > shapeB[this.sortField] ? 1 : -1);

    // maybe sort should not be done twice (firebase + here)
  }

  @computed get points(): Point[] {
    return this.items
      .filter(item => item.treeNode?.isVisible)
      .map(item => compact([
        ...item.points,
        (item as Line).midPt,
        ...((item as Surface).holes?.map(hole => hole.points).flat() || [])
      ])).flat();
  }

  @computed get isCalibrationSecondStep() {
    return this.backgroundImageScaleShape && !this.shouldShowBackgroundImageScaleInput;
  }
}

